<template>
  <div class="about-wrapper">
    <!-- <intro-app-bar :title="$t('intro.pages.about.title')"></intro-app-bar> -->

    <admin-quote name="mfranca"></admin-quote>

    <div class="py-4 timeline-wrapper">

      <v-slide-group
        v-model="timeline"
        center-active
        show-arrows
      >

        <div class="divider"></div>

        <v-slide-item
          v-for="(item, i) in $t('intro.pages.about.items')"
          :key="item.title + i"
          v-slot="{ active, toggle }"
        >
          <div
            class="about-card-wrapper"
            :class="{'active': active, 'even': i % 2 === 0, 'odd': i % 2 !== 0}"
          >

            <div class="timeline-icon-wrapper"></div>

            <v-card
              class="ma-4 pa-4 about-card"
              :class="{ 'ml-4' : i === 0 }"
              height="140"
              :width="cards.width"
              @click="toggle"
            >
              <div class="card-title">
                <h2>{{ item.title }}</h2>
                <div class="about-date">{{ item.date }}</div>
              </div>

              <div
                v-html="item.description + '.'"
                class="card-text mt-2"
              ></div>

              <div class="card-more-info">
                <v-icon>{{ (active && i % 2 === 0) ? 'mdi-chevron-up' : (!active && i % 2 !== 0) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </div>
            </v-card>
          </div>
        </v-slide-item>
      </v-slide-group>
    </div>

  </div>
</template>

<script>
export default {
  data: () => ({
    timeline: null,
    cards: {
      width: 400,
    },
  }),
  methods: {
    setSliderSize() {
      if (this.$route.name !== this.$config.navigation.intro.about.link) return;

      setTimeout(() => {
        let timeline = document.getElementsByClassName("timeline-wrapper");
        let timelineWidth = timeline[0].clientWidth;

        let prev = document.getElementsByClassName("v-slide-group__prev");
        let prevWidth = prev[0].clientWidth;

        let next = document.getElementsByClassName("v-slide-group__next");
        let nextWidth = next[0].clientWidth;

        let workableWidth = timelineWidth - (nextWidth + prevWidth);
        let slidesWidth = workableWidth / this.cardsPerSlide;

        let cardEl = document.getElementsByClassName("about-card");
        let cardMarginLeft = window.getComputedStyle(cardEl[0]).getPropertyValue('margin-left');
        let cardMarginRight = window.getComputedStyle(cardEl[0]).getPropertyValue('margin-right');

        let cardMargin = parseInt(cardMarginLeft) + parseInt(cardMarginRight);
        this.cards.width = slidesWidth - cardMargin; //Set card width

        let timelineIcon = document.getElementsByClassName("timeline-icon-wrapper");

        for (let i = 0; i < timelineIcon.length; i++) {
          timelineIcon[i].style.left = (this.cards.width / 2) + 'px'; //Set timeline icon pos
        }
      }, 0);
    }
  },
  mounted() {
    this.setSliderSize();
    window.addEventListener('resize', this.setSliderSize);
  },
  computed: {
    cardsPerSlide() {
      return this.$vuetify.breakpoint.width >= 1650 ? 4 : this.$vuetify.breakpoint.width >= 1000 ? 3 : this.$vuetify.breakpoint.width >= 700 ? 2 : 1;
    },
  },
}
</script>

<style lang="scss" scoped>
.about-date {
  color: $palette0;
  font-weight: 500;
}

.about-card-wrapper {
  height: 360px;
  display: flex;
  position: relative;

  &.active {
    .about-card {
      height: 328px !important;
      background-color: #fff;

      .card-text {
        display: block;
        -webkit-line-clamp: none;
      }
    }
  }

  &.odd {
    align-items: flex-end;
  }

  .timeline-icon-wrapper {
    position: absolute;
    top: 170px;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 2px solid $palette0;
    border-radius: 20px;
  }

  .about-card {
    transition: 250ms ease-in-out;
    overflow: hidden;
    user-select: none;

    .card-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .card-text {
      color: rgba(0, 0, 0, 0.6);
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.375rem;
      letter-spacing: 0.0071428571em;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .card-more-info {
      height: 24px;
      width: 24px;
      position: absolute;
      right: 8px;
      bottom: 8px;
    }

    @media screen and (min-width: 1650px) {
      .card-title h2 {
        font-size: 18px !important;
      }
    }

    @media screen and (max-width: 1650px) {
      .card-title h2 {
        font-size: 16px !important;
      }
    }

    @media screen and (max-width: 1400px) {
      .card-text {
        line-height: 18px;
      }
    }

    @media screen and (max-width: 1000px) {
      .about-date {
        font-size: 14px;
      }

      .card-title h2 {
        font-size: 16px !important;
      }

      .card-text {
        -webkit-line-clamp: 2;
      }
    }
  }
}

.v-card--link:focus::before {
  opacity: 0;
}

.v-slide-group__wrapper {
  position: relative;
}

.slide-fade {
  position: absolute;
  width: 400px;
  height: 100%;
  &.left {
    top: 0;
    left: 0;
    z-index: 2;
    background: linear-gradient(
      90deg,
      rgba(242, 242, 242, 1),
      rgba(242, 242, 242, 0.1)
    );
  }
}

.divider {
  width: 100%;
  height: 2px;
  top: 179px;
  position: absolute;
}

::v-deep .v-slide-group__prev,
::v-deep .v-slide-group__next {
  transition: 150ms ease-in-out;

  &:hover {
    opacity: 0.3;
  }
}
</style>