<template>
  <div class="features-wrapper py-12 px-4">
    <div class="limit-width py-12 text-center">

      <h2 class="bolder">{{ $t('industria.pages.home.features.title') }}</h2>
      <div class="divider mt-4"></div>

      <div class="feature-wrapper mt-12">
        <v-hover
          v-slot:default="{ hover }"
          v-for="(item, i) in $t('industria.pages.services.solutions.values')"
          :key="item.title"
        >
          <router-link :to="{ name: solutions.link, params: { solutionsIndex: i } }">
            <v-img
              class="elevation-4 img-wrapper"
              :class="{ zoom: hover }"
              :src="solutions.values[i].img"
              :alt="item.title"
              :max-width="$vuetify.breakpoint.width > 700 ? 320 : $vuetify.breakpoint.width > 450 ? 240 : 260"
              :height="$vuetify.breakpoint.width > 700 ? 160 : 'auto'"
              :min-height="160"
              gradient="to top right, rgba(0,0,0,.15), rgba(0,0,0,.05)"
              :eager="$vuetify.breakpoint.mobile"
            >
              <v-expand-transition v-if="!$vuetify.breakpoint.mobile">
                <div
                  class="v-card--reveal white--text hover-overlay"
                  style="height: 100%;"
                >
                  <h3 class="h6 regular">{{ item.title }}</h3>
                  <v-icon
                    color="white"
                    size="20"
                  >mdi-chevron-right</v-icon>

                </div>
              </v-expand-transition>

              <div class="feature-overlay">
                <div class="py-2 px-4">
                  <h3 class="h6">{{ item.title }}</h3>
                </div>
              </div>
            </v-img>
          </router-link>
        </v-hover>
      </div>

      <hr class="mt-12">

      <div class="performance-wrapper mt-10">
        <div
          v-for="(item, i) in $t('company.stats.industry')"
          :key="i"
        >
          <h1 class="bolder">{{ stats.industry[i] }}</h1>
          <h2 class="regular text-uppercase performance-title">{{ item }}</h2>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import solutions from '@mixins/industria/solutions/solutions';
import stats from '@mixins/company/stats';

export default {
  mixins: [solutions, stats],
}
</script>

<style lang="scss" scoped>
::v-deep .img-wrapper {
  cursor: pointer;
  .v-image__image {
    transition: 350ms ease-in-out;
  }

  &.zoom {
    .v-image__image {
      transform: scale(1.2);
    }
  }
}

.features-wrapper {
  background-color: #fff;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

 .feature-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 22px;

  @media screen and (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }

  @media screen and (max-width: 530px) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 530px) {
    .img-wrapper {
      max-width: 350px !important;
    }
  }

  @media screen and (max-width: 380px) {
    .img-wrapper {
      max-width: 280px !important;
    }
  }

  & > div {
    cursor: pointer;
  }
}

.feature-overlay {
  height: 100%;
  display: flex;
  align-items: flex-end;
  & > div {
    height: 38px;
    font-weight: 600;
    display: flex;
    align-items: center;
    border-left: 4px solid $palette0;
    background-color: rgba(255, 255, 255, 0.9);
    & h3 {
      @media screen and (max-width: 1100px) {
        font-size: 14px !important;
      }
      @media screen and (max-width: 700px) {
        font-size: 12px !important;
      }
    }
  }
}

.performance-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 16px;
  width: 100%;

  @media screen and (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
    width: auto;
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }

  .performance-title {
    font-size: 13px;
  }
}
</style>