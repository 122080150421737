var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "about-wrapper white--text py-8",
      class: _vm.$vuetify.breakpoint.width > 1050 ? "px-12" : "px-4",
    },
    [
      _c("div", { staticClass: "limit-width py-8 d-flex align-center" }, [
        _c("div", [
          _c("h1", { staticClass: "highlight white--text" }, [
            _vm._v(_vm._s(_vm.$t("auto.pages.home.about.title"))),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "about-subtitle mt-4" }, [
            _vm._v(_vm._s(_vm.$t("auto.pages.home.about.subtitle"))),
          ]),
          _vm._v(" "),
          _c("div", {
            staticClass: "mt-12",
            domProps: {
              innerHTML: _vm._s(_vm.$t("auto.pages.home.about.text")),
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "performance-wrapper mt-10" },
            _vm._l(_vm.$t("company.stats.auto"), function (item, i) {
              return _c("div", { key: i }, [
                _c("h1", {
                  staticClass: "bolder",
                  domProps: { innerHTML: _vm._s(_vm.stats.auto[i]) },
                }),
                _vm._v(" "),
                _c(
                  "h2",
                  { staticClass: "regular text-uppercase performance-title" },
                  [_vm._v(_vm._s(item))]
                ),
              ])
            }),
            0
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "logo-wrapper elevation-12 ma-8" }, [
          _c("video", {
            staticClass: "video",
            staticStyle: { "max-height": "100%", "max-width": "100%" },
            attrs: {
              controls: "",
              playsinline: "",
              muted: "",
              src: _vm.$routes.asset("video/intro.mp4"),
            },
            domProps: { muted: true },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }