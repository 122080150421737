var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-carousel",
    {
      attrs: {
        "hide-delimiter-background": "",
        interval: "6000",
        cycle: "",
        "show-arrows-on-hover": true,
        height: _vm.height,
      },
      model: {
        value: _vm.currSlide,
        callback: function ($$v) {
          _vm.currSlide = $$v
        },
        expression: "currSlide",
      },
    },
    _vm._l(_vm.carousel, function (item, i) {
      return _c(
        "v-carousel-item",
        { key: i, attrs: { eager: "" } },
        [
          _c("div", { staticClass: "carousel-overlay pa-6" }, [
            _c("div", { staticClass: "limit-width" }, [
              _c("div", {
                staticClass: "hero-headline",
                domProps: { innerHTML: _vm._s(item.overlay.headline) },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "divider mt-6" }),
              _vm._v(" "),
              _c("div", {
                staticClass: "hero-description mt-6",
                domProps: { innerHTML: _vm._s(item.overlay.description) },
              }),
              _vm._v(" "),
              _vm.buttons.length > i
                ? _c(
                    "div",
                    { staticClass: "mt-8" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: { name: _vm.buttons[i].link } },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "main-btn hover-alt",
                              attrs: { dark: "", height: "48" },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.buttons[i].title) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "v-sheet",
            { attrs: { height: "100%", tile: "", color: "#333" } },
            [
              _c("v-parallax", {
                attrs: {
                  src: _vm.imgFolder + item.img,
                  "lazy-src": _vm.imgFolder + item.imgLazy,
                  alt: item.alt,
                  height: _vm.height,
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }