import en from "./en.json";
import pt from "./pt.json";
import es from "./es.json";

export const defaultLocale = "pt";

export const languages = {
    en: en,
    pt: pt,
    es: es,
};
