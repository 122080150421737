<template>
    <div class="intro-home-wrapper" :class="$vuetify.breakpoint.mobile ? 'mobile' : ''">

        <div class="video-wrapper">
            <video class="video" autoplay playsinline loop muted :src="$routes.asset('video/intro.mp4')"></video>
        </div>

        <div class="video-overlay"></div>

        <div class="content-overlay">
            <div class="slogan text-center">
                <h1>ROLLING YOUR WAY</h1>
            </div>
            <div class="sub-slogan text-center"><span>{{ $t('intro.pages.home.slogan.first') }}.</span><br><span>{{
        $t('intro.pages.home.slogan.second') }}.</span></div>

            <div class="mt-10 action-buttons">

                <router-link :to="{ name: $config.navigation.industria.home.link }">
                    <v-btn :x-large="!$vuetify.breakpoint.mobile" :large="$vuetify.breakpoint.mobile" color="#ec921d"
                        dark :title="$t('intro.pages.home.buttons.industry')">
                        {{ $t('intro.pages.home.buttons.industry') }}
                    </v-btn>
                </router-link>

                <router-link :to="{ name: $config.navigation.auto.home.link }">
                    <v-btn :x-large="!$vuetify.breakpoint.mobile" :large="$vuetify.breakpoint.mobile" color="#ec921d"
                        dark class="ml-2" :title="$t('intro.pages.home.buttons.automotive')">
                        {{ $t('intro.pages.home.buttons.automotive') }}
                    </v-btn>
                </router-link>

                <router-link :to="{ name: $config.navigation.agricultura.home.link }">
                    <v-btn :x-large="!$vuetify.breakpoint.mobile" :large="$vuetify.breakpoint.mobile" color="#ec921d"
                        dark class="ml-2" :title="$t('intro.pages.home.buttons.agricultura')">
                        {{ $t('intro.pages.home.buttons.agricultura') }}
                    </v-btn>
                </router-link>
            </div>

        </div>

    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.intro-home-wrapper {
    height: 100vh;
}

.video-wrapper {
    position: absolute;
    z-index: 1;
    inset: 0px;
    overflow: hidden;
    background-size: cover;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-image: none;
}

.video-overlay {
    height: 100vh;
    width: 100%;
    background: linear-gradient(to right,
            darken($romafe, 20%),
            darken($romafe, 5%));
    opacity: 0.8;
    position: absolute;
    z-index: 2;
}

.content-overlay {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 3;
}

.slogan {
    color: #fff;
    font-weight: 800;
    padding: 0 24px;
    text-shadow: 3px 3px 13px rgba(0, 0, 0, 1);

    h1 {
        font-size: 80px;
    }
}

.sub-slogan {
    font-size: 18px;
    color: #fff;
    line-height: 34px;
    padding: 0 24px;
    text-shadow: 3px 3px 13px rgba(0, 0, 0, 1);

    & span:nth-child(1) {
        font-size: 24px;
    }
}

.video {
    margin: auto;
    width: 100%;
    min-height: 100vh;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    visibility: visible;
    opacity: 1;
    object-fit: cover;
}

.action-buttons {
    & a {
        text-decoration: none !important;
    }
}

::v-deep .action-buttons {
    .v-btn span {
        text-shadow: 3px 3px 13px rgba(0, 0, 0, 1);
    }
}

@media screen and (max-width: 910px) {
    .slogan h1 {
        font-size: 60px;
    }

    .sub-slogan {
        margin-top: 24px;
    }
}

@media screen and (max-width: 500px) {
    .slogan h1 {
        font-size: 44px;
        line-height: 1.2em;
    }

    .sub-slogan {
        font-size: 16px;
        line-height: 22px;

        & span:nth-child(1) {
            font-size: 20px;
        }
    }
}
</style>
