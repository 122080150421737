<template>
  <v-dialog
    v-model="dialog"
    max-width="290"
  >
    <v-card>
      <v-card-title class="headline">
        <div v-html="title"></div>
      </v-card-title>

      <v-card-text>
        <div v-html="message"></div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color='#243851'
          text
          dark
          @click="agree"
        >
          SIM
        </v-btn>

        <v-btn
          color='#243851'
          dark
          @click="cancel"
        >
          NÃO
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
  }),
  methods: {
    open(title, message) {
      this.dialog = true;
      this.title = title;
      this.message = message;

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-card__title {
  padding: 16px 22px 10px !important;
}

::v-deep * {
  font-family: $mainFont !important;
}
</style>