var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "intro-home-wrapper",
      class: _vm.$vuetify.breakpoint.mobile ? "mobile" : "",
    },
    [
      _c("div", { staticClass: "video-wrapper" }, [
        _c("video", {
          staticClass: "video",
          attrs: {
            autoplay: "",
            playsinline: "",
            loop: "",
            muted: "",
            src: _vm.$routes.asset("video/intro.mp4"),
          },
          domProps: { muted: true },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "video-overlay" }),
      _vm._v(" "),
      _c("div", { staticClass: "content-overlay" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "sub-slogan text-center" }, [
          _c("span", [
            _vm._v(_vm._s(_vm.$t("intro.pages.home.slogan.first")) + "."),
          ]),
          _c("br"),
          _c("span", [
            _vm._v(_vm._s(_vm.$t("intro.pages.home.slogan.second")) + "."),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mt-10 action-buttons" },
          [
            _c(
              "router-link",
              {
                attrs: {
                  to: { name: _vm.$config.navigation.industria.home.link },
                },
              },
              [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      "x-large": !_vm.$vuetify.breakpoint.mobile,
                      large: _vm.$vuetify.breakpoint.mobile,
                      color: "#ec921d",
                      dark: "",
                      title: _vm.$t("intro.pages.home.buttons.industry"),
                    },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$t("intro.pages.home.buttons.industry")) +
                        "\n                "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: { to: { name: _vm.$config.navigation.auto.home.link } },
              },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "ml-2",
                    attrs: {
                      "x-large": !_vm.$vuetify.breakpoint.mobile,
                      large: _vm.$vuetify.breakpoint.mobile,
                      color: "#ec921d",
                      dark: "",
                      title: _vm.$t("intro.pages.home.buttons.automotive"),
                    },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$t("intro.pages.home.buttons.automotive")) +
                        "\n                "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                attrs: {
                  to: { name: _vm.$config.navigation.agricultura.home.link },
                },
              },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "ml-2",
                    attrs: {
                      "x-large": !_vm.$vuetify.breakpoint.mobile,
                      large: _vm.$vuetify.breakpoint.mobile,
                      color: "#ec921d",
                      dark: "",
                      title: _vm.$t("intro.pages.home.buttons.agricultura"),
                    },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$t("intro.pages.home.buttons.agricultura")) +
                        "\n                "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "slogan text-center" }, [
      _c("h1", [_vm._v("ROLLING YOUR WAY")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }