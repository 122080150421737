var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "px-4 bg-main" }, [
    _c("div", { staticClass: "limit-width py-12 d-flex align-center" }, [
      _vm.isImageLeft
        ? _c(
            "div",
            {
              staticClass: "d-flex align-center",
              class: { "has-gap": _vm.item.img },
            },
            [
              _c("div", [
                _c("div", [
                  _c("h2", { staticClass: "highlight mb-2" }, [
                    _vm._v(_vm._s(_vm.item.title)),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.item.description) },
                }),
              ]),
              _vm._v(" "),
              _vm.$vuetify.breakpoint.width >= 1020
                ? _c(
                    "div",
                    [
                      _c("v-img", {
                        attrs: {
                          src: _vm.$routes.asset(
                            "img/auto/products/" + _vm.item.img
                          ),
                          alt: _vm.item.title,
                          "max-width":
                            _vm.$vuetify.breakpoint.width < 400
                              ? 220
                              : _vm.$vuetify.breakpoint.width < 600
                              ? 300
                              : 400,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          )
        : _c(
            "div",
            {
              staticClass: "d-flex align-center",
              class: { "has-gap": _vm.item.img },
            },
            [
              _vm.$vuetify.breakpoint.width >= 1020
                ? _c(
                    "div",
                    [
                      _c("v-img", {
                        attrs: {
                          src: _vm.$routes.asset(
                            "img/auto/products/" + _vm.item.img
                          ),
                          alt: _vm.item.title,
                          "max-width":
                            _vm.$vuetify.breakpoint.width < 400
                              ? 220
                              : _vm.$vuetify.breakpoint.width < 600
                              ? 300
                              : 400,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", [
                _c("div", [
                  _c("h2", { staticClass: "highlight mb-2" }, [
                    _vm._v(_vm._s(_vm.item.title)),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.item.description) },
                }),
              ]),
            ]
          ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }