const sector = {
    methods: {
        sectorNotDefined() {
            throw "Sector is not defined: can't if is Industria, Auto or Agricultura";
        },
        getRoutes(routes) {
            let links = [];
            for (const key in routes) links.push(routes[key].link);
            return links;
        },
    },
    computed: {
        navLinks() {
            return Object.values(this.navigation).filter(
                (el) => el.show === true
            );
        },
        otherLinks() {
            return Object.values(this.navigation).filter(
                (el) => el.otherLinks === true
            );
        },
        navigation() {
            let navigation = this.$config.navigation;

            if (this.isIndustria) navigation = navigation.industria;
            else if (this.isAuto) navigation = navigation.auto;
            else if (this.isAgricultura) navigation = navigation.agricultura;
            else this.sectorNotDefined();

            return navigation;
        },
        isIntro() {
            let routes = this.getRoutes(this.$config.navigation.intro);
            return (
                routes.includes(this.$route.name) ||
                (this.windowUrl.includes(this.matchUrl) &&
                    !this.isIndustria &&
                    !this.isAgricultura &&
                    !this.isAuto)
            );
        },
        isIndustria() {
            let routes = this.getRoutes(this.$config.navigation.industria);
            return (
                routes.includes(this.$route.name) ||
                this.windowUrl.includes(this.matchUrl + "/industria")
            );
        },
        isAuto() {
            let routes = this.getRoutes(this.$config.navigation.auto);
            return (
                routes.includes(this.$route.name) ||
                this.windowUrl.includes(this.matchUrl + "/auto")
            );
        },
        isAgricultura() {
            let routes = this.getRoutes(this.$config.navigation.agricultura);
            return (
                routes.includes(this.$route.name) ||
                this.windowUrl.includes(this.matchUrl + "/agricultura")
            );
        },
        sector() {
            return this.isIndustria
                ? "industria"
                : this.isAuto
                ? "auto"
                : this.isIntro
                ? "intro"
                : this.isAgricultura
                ? "agricultura"
                : this.sectorNotDefined();
        },
        localePath() {
            return this.sector;
        },
        windowUrl() {
            return window.location.href;
        },
        matchUrl() {
            return this.$routes.host + this.$routes.path;
        },
    },
};

export default sector;
