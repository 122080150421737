import Vue from "vue";
import Vuex from "vuex";

//Load Vuex
Vue.use(Vuex);

import industriaModule from "./industria/industria";
import autoModule from "./auto/auto";
import agriculturaModule from "./agricultura/agricultura";
import dataModule from "./misc/fetchdata";
import dialogModule from "./misc/dialog";

//Create Store
const store = new Vuex.Store({
    modules: {
        industria: industriaModule,
        auto: autoModule,
        agricultura: agriculturaModule,
        fetchData: dataModule,
        dialog: dialogModule,
    },
});

export default store;
