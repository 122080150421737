/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");
window.Vue = require("vue");

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

const files = require.context("./", true, /\.vue$/i);
files
    .keys()
    .map((key) =>
        Vue.component(key.split("/").pop().split(".")[0], files(key).default)
    );

//Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

//! DevExtreme
import "devextreme/dist/css/dx.light.compact.css";

//! Vuex
import store from "./store/index";

//! Vuetify
import "@mdi/font/css/materialdesignicons.css";

import Vuetify, {
    VApp,
    VAlert,
    VMain,
    VNavigationDrawer,
    VAppBar,
    VAppBarNavIcon,
    VContainer,
    VFooter,
    VImg,
    VList,
    VListItem,
    VListItemIcon,
    VListItemContent,
    VListItemTitle,
    VIcon,
    VSpacer,
    VBtn,
    VListGroup,
    VSystemBar,
    VCard,
    VCardTitle,
    VCardSubtitle,
    VCardText,
    VCardActions,
    VHover,
    VMenu,
    VCarousel,
    VCarouselItem,
    VSheet,
    VRow,
    VCol,
    VTextField,
    VDialog,
    VExpandTransition,
    VExpandXTransition,
    VScaleTransition,
    VProgressCircular,
    VParallax,
    VSlideGroup,
    VSlideItem,
    VTabs,
    VTab,
    VTabsItems,
    VTabItem,
    VTabsSlider,
    VSkeletonLoader,
    VSelect,
    VExpansionPanels,
    VExpansionPanel,
    VExpansionPanelHeader,
    VExpansionPanelContent,
    VDataTable,
} from "vuetify/lib";

Vue.use(Vuetify, {
    components: {
        VApp,
        VAlert,
        VMain,
        VNavigationDrawer,
        VAppBar,
        VAppBarNavIcon,
        VContainer,
        VFooter,
        VImg,
        VList,
        VListItem,
        VListItemIcon,
        VListItemContent,
        VListItemTitle,
        VIcon,
        VSpacer,
        VBtn,
        VListGroup,
        VSystemBar,
        VCard,
        VCardTitle,
        VCardSubtitle,
        VCardText,
        VCardActions,
        VHover,
        VMenu,
        VCarousel,
        VCarouselItem,
        VSheet,
        VRow,
        VCol,
        VTextField,
        VDialog,
        VExpandTransition,
        VExpandXTransition,
        VScaleTransition,
        VProgressCircular,
        VParallax,
        VSlideGroup,
        VSlideItem,
        VTabs,
        VTab,
        VTabsItems,
        VTabItem,
        VTabsSlider,
        VSkeletonLoader,
        VSelect,
        VExpansionPanels,
        VExpansionPanel,
        VExpansionPanelHeader,
        VExpansionPanelContent,
        VDataTable,
    },
});

//ROUTES
import routes from "../routes/routes";
Vue.prototype.$routes = routes;

//CONFIG
import config from "../config/config";
Vue.prototype.$config = config;

//ROUTER
import router from "../routes/router";

//VUEX
import Vuex from "vuex";
Vue.use(Vuex);

//GOOGLE ANALYTICS
import VueGtag from "vue-gtag";

Vue.use(
    VueGtag,
    {
        config: { id: "G-ZFKJRNESNZ" },
        bootstrap: false,
    },
    router
);

//LANGUAGES & LOCALES
import pt from "vuetify/lib/locale/pt";
import en from "vuetify/lib/locale/en";
import es from "vuetify/lib/locale/es";

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

import { languages } from "./lang/index.js";
import { defaultLocale } from "./lang/index.js";
const messages = Object.assign(languages);

const i18n = new VueI18n({
    locale: defaultLocale,
    fallbackLocale: "pt",
    messages,
});

const app = new Vue({
    el: "#vue-main-app",
    router,
    store,
    i18n,
    vuetify: new Vuetify({
        icons: {
            iconfont: "mdi",
        },
        lang: {
            locales: { pt, en, es },
            current: "pt",
        },
    }),
});

global.vm = app;
