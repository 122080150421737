var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "news-page-single-wrapper", class: _vm.sector },
    [
      _c("hero-inner-page", {
        attrs: {
          src: _vm.$routes.asset(
            "/img/components/hero/news/hero-inner-page.jpg"
          ),
          routes: [
            _vm.$config.navigation[_vm.sector].news,
            _vm.$config.navigation[_vm.sector].newsSingle,
          ],
        },
      }),
      _vm._v(" "),
      _vm.post
        ? _c("div", { staticClass: "py-12 px-4" }, [
            _c("div", { staticClass: "limit-width py-12" }, [
              _c(
                "h1",
                {
                  staticClass: "highlight post-title",
                  class:
                    _vm.$vuetify.breakpoint.width > 900
                      ? "mb-12 h1"
                      : "mb-6 h4",
                },
                [_vm._v(_vm._s(_vm.post.titulo))]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "post-content-wrapper" }, [
                _c(
                  "div",
                  { staticClass: "img-wrapper" },
                  [
                    _c("v-img", {
                      staticClass: "post-img mb-6 mt-2 elevation-8",
                      class: { "ml-10": _vm.$vuetify.breakpoint.width > 900 },
                      attrs: {
                        alt: _vm.post.titulo,
                        src: _vm.$routes.asset(_vm.post.imagem),
                        "max-height":
                          _vm.$vuetify.breakpoint.width > 900 ? 500 : 350,
                        "max-width":
                          _vm.$vuetify.breakpoint.width > 900 ? 500 : 350,
                        eager: _vm.$vuetify.breakpoint.mobile,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", {
                  staticClass: "post-content",
                  domProps: { innerHTML: _vm._s(_vm.post.conteudo) },
                }),
              ]),
            ]),
          ])
        : _c("div", { staticClass: "py-12 px-4" }, [
            _c(
              "div",
              { staticClass: "limit-width py-12" },
              [
                _c("v-skeleton-loader", {
                  staticClass: "post-title-load",
                  class: _vm.$vuetify.breakpoint.width > 900 ? "mb-12" : "mb-6",
                  attrs: { type: "heading" },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "post-content-wrapper-load" },
                  [
                    _c("v-skeleton-loader", {
                      staticClass: "post-img-load",
                      class:
                        _vm.$vuetify.breakpoint.width > 900 ? "ml-6" : "mb-6",
                      attrs: {
                        type: "image",
                        "min-height":
                          _vm.$vuetify.breakpoint.width > 900 ? 500 : 350,
                        "min-width":
                          _vm.$vuetify.breakpoint.width > 900 ? 500 : 350,
                      },
                    }),
                    _vm._v(" "),
                    _c("v-skeleton-loader", {
                      staticClass: "post-content-load",
                      attrs: {
                        type: "sentences, paragraph, text, sentences, paragraph, text, paragraph, paragraph, text, sentences",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
      _vm._v(" "),
      _c("div", { staticClass: "pa-4 call-to-action alt about-4-wrapper" }, [
        _c("div", { staticClass: "limit-width" }, [
          _c("div", [
            _c("h2", { staticClass: "h6" }, [
              _vm._v(_vm._s(_vm.$t("pages.about.infocalltoaction"))),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ml-4" },
            [
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: {
                    to: {
                      name: _vm.$config.navigation[_vm.sector].contacts.link,
                    },
                  },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "main-btn alt",
                      attrs: { dark: "", title: _vm.$t("misc.contact.us") },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("misc.contact.us")) +
                          "\n          "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }