const solutions = {
  data: () => ({
    solutions: {
      link: 'industria-services',
      values: [
        { isProduct: true, img: null },
        { isProduct: true, img: null },
        { isProduct: true, img: null },
        { isProduct: true, img: null },
        { isProduct: true, img: null },
        { isProduct: true, img: null },
        { isProduct: true, img: null },
        { isProduct: true, img: null },
        { isProduct: false, img: null },
      ]
    }
  }),
  methods: {
    setImg() {
      for (let i = 0; i < this.solutions.values.length; i++) {
        this.solutions.values[i].img = `${this.imgFolder}${i + 1}.jpg`;
      }
    }
  },
  created() {
    this.setImg();
  },
  computed: {
    imgFolder() {
      return this.$routes.asset('img/industria/services/feature-');
    },
    products() {
      return this.solutions.values.filter((el) => el.isProduct === true);
    }
  }
}

export default solutions;