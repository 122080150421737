var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "showcase-wrapper py-12 px-4", attrs: { id: "partners" } },
    [
      _c("div", { staticClass: "limit-width text-center py-12" }, [
        _c("h2", { staticClass: "bolder" }, [
          _vm._v(_vm._s(_vm.$t("misc.brandshowcase.title"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "divider mt-4" }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "logo-wrapper mt-12", class: _vm.sector },
          _vm._l(_vm.links[_vm.sector], function (ref, index) {
            var title = ref[0]
            var link = ref[1]
            return _c(
              "a",
              {
                key: index,
                staticClass: "single-logo py-3 px-5 elevation-16",
                attrs: {
                  href: link,
                  rel: "noopener noreferrer",
                  target: "_blank",
                  title: title,
                },
              },
              [
                _c("v-img", {
                  staticClass: "img",
                  class: { mobile: _vm.$vuetify.breakpoint.mobile },
                  attrs: {
                    src: _vm.imgFolder + (index + 1) + ".jpg",
                    alt: "Parceiro ROMAFE - " + title,
                    width: "110",
                    height: "80",
                    eager: _vm.$vuetify.breakpoint.mobile,
                    contain: "",
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "logo-overlay" }, [
                  _c("div", { staticClass: "py-1 px-2" }, [
                    _vm._v(_vm._s(title)),
                  ]),
                ]),
              ],
              1
            )
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }