<template>
  <div class="about-wrapper py-12 px-4">
    <div class="limit-width py-12">

      <div class="about-content-wrapper">

        <div>
          <h2 class="bolder">{{ $t('industria.pages.home.aboutus.title') }}</h2>
          <div class="divider mt-4"></div>

          <div class="mt-6">
            <div>
              <div
                v-for="(item, i) in $t('industria.pages.home.aboutus.description')"
                :key="item"
              >
                {{ item }}<span v-if="i !== $t('industria.pages.home.aboutus.description').length - 1">.<br><br></span><span v-else>.</span>
              </div>
            </div>

            <router-link
              class="router-link"
              :to="{ name: $config.navigation.industria.about.link }"
            >
              <v-btn
                class="main-btn mt-8"
                dark
                height="48"
              >
                {{ $t('industria.pages.home.aboutus.title') }}
              </v-btn>
            </router-link>
          </div>
        </div>

        <div class="logo-wrapper ma-8">
          <video
            style="max-height:100%;max-width:100%;"
            class="video elevation-12"
            controls
            playsinline
            muted
            :src="$routes.asset('video/intro.mp4')"
          ></video>
        </div>

      </div>

      <div class="about-highlight-wrapper mt-12">
        <div
          v-for="(item, i) in $t('industria.pages.home.aboutus.values')"
          :key="item.title"
        >
          <v-icon
            size="42"
            class="highlight-icon"
          >{{ icons[i] }}</v-icon>
          <div class="ml-4">
            <h3 class="mb-2 h6">{{ item.title }}</h3>
            <span class="highlight-description">{{ item.description }}.</span>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    icons: ['mdi-cube-scan', 'mdi-cogs', 'mdi-seal-variant'],
  }),
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.about-wrapper {
  background-color: #fff;
}

.about-content-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  .logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(6deg);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    margin-right: -28px !important;
  }

  @media screen and (max-width: 850px) {
    grid-template-columns: 1fr;
    gap: 16px;
    .logo-wrapper {
      display: none;
    }
  }
}

.about-highlight-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 22px;
  & > div {
    display: flex;
    align-items: center;
  }

  .highlight {
    &-icon {
      color: $palette0;
      opacity: 0.3;
    }
    &-description {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 750px) {
    grid-template-columns: 1fr;
  }
}
</style>