var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "about-wrapper py-12 px-4" }, [
    _c("div", { staticClass: "limit-width py-12" }, [
      _c("div", { staticClass: "about-content-wrapper" }, [
        _c("div", [
          _c("h2", { staticClass: "bolder" }, [
            _vm._v(_vm._s(_vm.$t("industria.pages.home.aboutus.title"))),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "divider mt-4" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt-6" },
            [
              _c(
                "div",
                _vm._l(
                  _vm.$t("industria.pages.home.aboutus.description"),
                  function (item, i) {
                    return _c("div", { key: item }, [
                      _vm._v("\n              " + _vm._s(item)),
                      i !==
                      _vm.$t("industria.pages.home.aboutus.description")
                        .length -
                        1
                        ? _c("span", [_vm._v("."), _c("br"), _c("br")])
                        : _c("span", [_vm._v(".")]),
                    ])
                  }
                ),
                0
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: {
                    to: { name: _vm.$config.navigation.industria.about.link },
                  },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "main-btn mt-8",
                      attrs: { dark: "", height: "48" },
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("industria.pages.home.aboutus.title")) +
                          "\n            "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "logo-wrapper ma-8" }, [
          _c("video", {
            staticClass: "video elevation-12",
            staticStyle: { "max-height": "100%", "max-width": "100%" },
            attrs: {
              controls: "",
              playsinline: "",
              muted: "",
              src: _vm.$routes.asset("video/intro.mp4"),
            },
            domProps: { muted: true },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "about-highlight-wrapper mt-12" },
        _vm._l(
          _vm.$t("industria.pages.home.aboutus.values"),
          function (item, i) {
            return _c(
              "div",
              { key: item.title },
              [
                _c(
                  "v-icon",
                  { staticClass: "highlight-icon", attrs: { size: "42" } },
                  [_vm._v(_vm._s(_vm.icons[i]))]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "ml-4" }, [
                  _c("h3", { staticClass: "mb-2 h6" }, [
                    _vm._v(_vm._s(item.title)),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "highlight-description" }, [
                    _vm._v(_vm._s(item.description) + "."),
                  ]),
                ]),
              ],
              1
            )
          }
        ),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }