const fetchData = {
  namespaced: true,
  state: {
    isLoading: false,
  },

  getters: {

  },

  actions: {

  },

  mutations: {
    loadStart(state) {
      state.isLoading = true;
    },
    loadEnd(state) {
      state.isLoading = false;
    },
  },
}


export default fetchData