var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.accepted === null
    ? _c(
        "v-footer",
        {
          staticClass: "cookies-bar-wrapper",
          class: {
            mobile: _vm.$vuetify.breakpoint.mobile,
            isIntro: _vm.isIntro,
          },
          attrs: { padless: "" },
        },
        [
          _c(
            "div",
            {
              staticClass: "cookies-bar white--text",
              class: [
                _vm.$vuetify.breakpoint.width < 800 ? "px-4" : "px-12",
                _vm.$vuetify.breakpoint.width <= 650 ? "py-4" : "py-2",
              ],
            },
            [
              _c("div", { staticClass: "cookies-msg" }, [
                _vm._v(_vm._s(_vm.$t("misc.cookies.message")) + "."),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "action-wrapper",
                  class: {
                    "mt-4": _vm.$vuetify.breakpoint.width <= 650,
                    "ml-12": _vm.$vuetify.breakpoint.width > 650,
                  },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "main-btn",
                      attrs: {
                        dark: "",
                        height: "32",
                        title: _vm.$t("misc.cookies.button.accept"),
                        "aria-label": "accept",
                      },
                      on: { click: _vm.accept },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("misc.cookies.button.accept")) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-2",
                      attrs: {
                        icon: "",
                        color: "#FFF",
                        small: "",
                        title: _vm.$t("misc.cookies.button.close"),
                        "aria-label": "close",
                      },
                      on: { click: _vm.reject },
                    },
                    [
                      _c("v-icon", { attrs: { size: "16" } }, [
                        _vm._v("mdi-close"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }