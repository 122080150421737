<template>
  <div
    class="about-wrapper white--text py-8"
    :class="$vuetify.breakpoint.width > 1050 ? 'px-12' : 'px-4'"
  >
    <div class="limit-width py-8 d-flex align-center">
      <div>
        <h1 class="highlight white--text">{{ $t('auto.pages.home.about.title') }}</h1>
        <div class="about-subtitle mt-4">{{ $t('auto.pages.home.about.subtitle') }}</div>

        <div
          class="mt-12"
          v-html="$t('auto.pages.home.about.text')"
        ></div>

        <div class="performance-wrapper mt-10">
          <div
            v-for="(item, i) in $t('company.stats.auto')"
            :key="i"
          >
            <h1
              class="bolder"
              v-html="stats.auto[i]"
            ></h1>
            <h2 class="regular text-uppercase performance-title">{{ item }}</h2>
          </div>
        </div>
      </div>

      <div class="logo-wrapper elevation-12 ma-8">
        <video
          style="max-height:100%;max-width:100%;"
          class="video"
          controls
          playsinline
          muted
          :src="$routes.asset('video/intro.mp4')"
        ></video>
      </div>

    </div>
  </div>
</template>

<script>
import stats from '@mixins/company/stats';

export default {
  mixins: [stats],
}
</script>

<style lang="scss" scoped>
.about-wrapper {
  background: linear-gradient(to right, darken($palette1, 5%), $palette1);

  .limit-width {
    height: 100%;
    column-gap: 24px;

    & > div {
      flex: 1;
      &:nth-child(2) {
        margin-right: -28px !important;
      }
    }
  }

  .about-subtitle {
    font-size: 20px;
    color: darken($palette1, 30%);
  }

  .logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(6deg);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
  }

  @media screen and (max-width: 1150px) {
    .logo-wrapper {
      display: none;
    }
  }
}

.performance-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  width: 100%;

  @media screen and (max-width: 1050px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: auto;
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 400px) {
    grid-template-columns: 1fr;
    & > div {
      text-align: center;
    }
  }

  .performance-title {
    font-size: 13px;
  }
}
</style>