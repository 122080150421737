//TODO: replace path with '';

const routes = {
  host: 'https://www.romafe.com',
  path: '',
  build: (endpoint = '') => {
    return routes.host + routes.path + '/' + endpoint;
  },
  asset: (endpoint = '') => {
    return routes.host + routes.path + '/assets/' + endpoint;
  },
  sanitize: (val = '') => {
    return (val.normalize("NFD").replace(/[^a-zA-Z0-9 ]/g, "").replaceAll(' ', '-')).toLowerCase();
  },
  api: {} //Built with routes from GlobalConfig
}


export default routes;