<template>
  <v-dialog
    v-model="dialog.value"
    max-width="290"
    @input="closeDialog"
  >
    <v-card>
      <v-card-title class="headline">
        <div v-html="dialog.title"></div>
      </v-card-title>

      <v-card-text>
        <div v-html="dialog.content"></div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color='#0063AA'
          dark
          @click="closeDialog"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  methods: {
    closeDialog() {
      this.$store.commit('dialog/closeDialog');
    },
  },
  computed: {
    dialog() {
      return this.$store.state.dialog;
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-card__title {
  padding: 16px 22px 10px !important;
}

::v-deep * {
  font-family: $mainFont !important;
}
</style>