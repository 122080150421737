<template>
    <div>
        <v-app-bar app color="#004b81" dark flat dense v-if="$vuetify.breakpoint.mobile" fixed>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" aria-label="menu"></v-app-bar-nav-icon>

            <v-spacer></v-spacer>

            <v-img style="margin-left:-24px" height="38" max-width="120" contain
                :src="$routes.asset('img/logo/romafe-white.png')" alt="Logo Romafe" title="Romafe"></v-img>

            <v-spacer></v-spacer>

        </v-app-bar>

        <v-navigation-drawer app fixed v-model="drawer">
            <router-link :to="{ name: $config.navigation.intro.home.link }" class="logo mb-12">
                <v-img :src="$routes.asset('img/logo/romafe-blue.png')" alt="Logo Romafe" title="Romafe"></v-img>
            </router-link>

            <v-list class="list">
                <div v-for="([icon, link, isRouter, children], i) in links" :key="i">
                    <router-link class="router-link" :to="{ name: link }" v-if="isRouter && children.length == 0">
                        <v-list-item class="list-item" link>

                            <v-list-item-icon>
                                <!-- <v-icon :color="icon === 'mdi-domain' ? 'rgb(3, 85, 145)' : icon === 'mdi-car-outline' ? 'rgb(181, 46, 37)' : 'rgba(0, 0, 0, 0.54)'">{{ icon }}</v-icon> -->
                                <v-icon>{{ icon }}</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <!-- <v-list-item-title :style="getTitleStyle(icon)">{{ $t(`intro.navdrawer.links[${i}]`) }}</v-list-item-title> -->
                                <v-list-item-title>{{ $t(`intro.navdrawer.links[${i}]`) }}</v-list-item-title>
                            </v-list-item-content>

                        </v-list-item>
                    </router-link>

                    <v-list-item class="list-item" v-if="!isRouter && children.length == 0" :href="link" link>

                        <v-list-item-icon>
                            <v-icon>{{ icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{ $t(`intro.navdrawer.links[${i}]`) }}</v-list-item-title>
                        </v-list-item-content>

                    </v-list-item>

                    <v-list-group v-if="children.length != 0" class="list-item" :value="false" :prepend-icon="icon">
                        <template v-slot:activator>
                            <v-list-item-title>{{ $t(`intro.navdrawer.links[${i}]`) }}</v-list-item-title>
                        </template>

                        <v-list-item v-for="item in children" :key="item.title" :href="item.link" target="_blank"
                            class="list-item-child">
                            <v-list-item-icon>
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>

                </div>
            </v-list>

            <v-spacer></v-spacer>

            <template v-slot:append>
                <div class="d-flex justify-center">
                    <language-change></language-change>
                </div>

                <div class="social-media-wrapper pa-4">

                    <div class="social-media mb-4">
                        <v-btn v-for="([title, icon, link, color], i) in $config.socials" :key="i" class="mx-1" fab dark
                            x-small :color="color" :href="link" target="_blank" rel="noopener noreferrer"
                            :title="title">
                            <v-icon dark color="#FFF">
                                {{ icon }}
                            </v-icon>
                        </v-btn>
                    </div>

                    <div class="text-center">© ROMAFE. {{ $t('intro.navdrawer.copyright') }}.</div>
                </div>
            </template>

        </v-navigation-drawer>
    </div>
</template>

<script>

export default {
    data: () => ({
        drawer: true,
        links: [
            ['mdi-home-outline', '', true, []],
            ['mdi-fingerprint', '', true, []],
            ['mdi-domain', '', true, []],
            ['mdi-car-outline', '', true, []],
            ['mdi-cart-outline', '', false, []],
            ['mdi-car-battery', '', false, []],
            ['mdi-lock-outline', '', false, []],
            ['mdi-phone-outline', '', true, []],
        ],
    }),
    methods: {
        setLinks() {
            let currentLocale = this.$root.$i18n.locale || defaultLocale;

            for (let i = 0; i < this.links.length; i++) {
                if (i === 4 && currentLocale === 'es') {
                    this.links[i][1] = 'https://www.romafe.com/weberpsl';
                } else if (i === 6 && currentLocale === 'es') {
                    this.links[6][3][0].link = 'https://www.romafe.com/webcrmsl';
                } else {
                    this.links[i][1] = this.path[i];
                }
            }

            console.log("links6.3:", this.links[6][3].length);

            if (this.links[6][3].length && currentLocale === 'pt') {

                this.links[6][3][0].link = 'https://www.romafe.com/webcrm';
            }

            console.log("LINKS:", this.links);
        },
        setReservedArea() {
            let reservedArea = [];
            let platforms = this.$config.platforms;

            for (const key in platforms) {
                let platform = platforms[key];
                if (platform.showIntro === true) reservedArea.push({
                    icon: platform.icon, title: platform.title, link: platform.link
                });
            }

            if (this.$root.$i18n.locale === 'es') {
                reservedArea[0].link = 'https://www.romafe.com/webcrmsl';
            }

            this.links[6][3] = reservedArea;
        },
        getTitleStyle(icon) {
            let style = '';

            if (icon === 'mdi-domain') {
                style = "color:rgb(3, 85, 145);font-weight:600;";
            } else if (icon === 'mdi-car-outline') {
                style = "color:rgb(181, 46, 37);font-weight:600;";
            }

            return style;
        }
    },
    computed: {
        path() {
            let links = [
                this.$config.navigation.intro.home.link,
                this.$config.navigation.intro.about.link,
                this.$config.navigation.industria.home.link,
                this.$config.navigation.auto.home.link,
                this.$config.platforms.weberp.link,
                this.$config.platforms.goparts.link,
                '',
                this.$config.navigation.intro.contacts.link,
            ];

            return links;
        }
    },
    watch: {
        '$root.$i18n.locale': function (newLocale) {
            this.setLinks();
        }
    },
    created() {
        this.setLinks();
        this.setReservedArea();
        if (this.$vuetify.breakpoint.mobile) this.drawer = false;
    },
}

</script>

<style lang="scss" scoped>
::v-deep .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;
}

::v-deep .v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
    margin-right: 24px;
}

::v-deep .v-list-item__icon.v-list-group__header__prepend-icon {
    margin-right: 24px;
}

.list {
    max-height: 600px;
    overflow-y: auto;
}

.list-item:not(.v-list-group) {
    & i {
        transition: none;
    }
}

.router-link-exact-active {
    &>.list-item {
        background: $bgRomafeGradient;

        & .v-list-item__icon i,
        .v-list-item__content {
            color: #f2f2f2 !important;
        }
    }
}

::v-deep .list-item {
    & i {
        transition: none;
    }
}

::v-deep .v-list-item__icon {
    margin: 12px 0;
}

::v-deep .list-item-child {
    padding-left: 30px;

    & .v-list-item__icon {
        margin: 15px 0;

        & i {
            font-size: 18px;
        }
    }

    & .v-list-item__content .v-list-item__title {
        font-size: 14px;
    }
}

.logo {
    width: 100%;
    height: 150px;
    padding: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-media-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}
</style>
