var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("auto-home-hero"),
      _vm._v(" "),
      _c("auto-home-products"),
      _vm._v(" "),
      _c("auto-home-about"),
      _vm._v(" "),
      _c("home-brand-showcase"),
      _vm._v(" "),
      _c("admin-quote", { attrs: { name: "jcarvalho" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }