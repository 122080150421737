<template>
  <v-carousel
    v-model="currSlide"
    hide-delimiter-background
    interval="6000"
    cycle
    :show-arrows-on-hover="true"
    :height="height"
  >
    <v-carousel-item
      v-for="(item, i) in carousel"
      :key="i"
      eager
    >
      <div class="carousel-overlay pa-6">
        <div class="limit-width">
          <div
            v-html="item.overlay.headline"
            class="hero-headline"
          ></div>
          <div class="divider mt-6"></div>

          <div
            v-html="item.overlay.description"
            class="hero-description mt-6"
          ></div>

          <div
            v-if="buttons.length > i"
            class="mt-8"
          >
            <router-link
              class="router-link"
              :to="{ name: buttons[i].link }"
            >
              <v-btn
                class="main-btn hover-alt"
                dark
                height="48"
              >
                {{ buttons[i].title }}
              </v-btn>
            </router-link>
          </div>

        </div>
      </div>

      <v-sheet
        height="100%"
        tile
        color="#333"
      >

        <v-parallax
          :src="imgFolder + item.img"
          :lazy-src="imgFolder + item.imgLazy"
          :alt="item.alt"
          :height="height"
        >
        </v-parallax>

      </v-sheet>

    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  data: () => ({
    currSlide: 0,
    height: 650,
    buttons: [],
  }),
  methods: {
    setData() {
      this.buttons = [];
      
      let about = this.$config.navigation.industria.about;
      let services = this.$config.navigation.industria.services;
      

      this.buttons.push({
        title: this.$t(`industria.navigation.links.${about.localePath}`),
        link: about.link,
      })

      this.buttons.push({
        title: this.$t(`industria.navigation.links.${services.localePath}`),
        link: services.link,
      })

    },
  },
  created() {
    this.setData();
  },
  computed: {
    carousel() {
      return this.$t('industria.pages.home.carousel')
    },
    imgFolder() {
      return this.$routes.asset('img/industria/carousel/');
    },
  },
  watch: {
    '$root.$i18n.locale': function (newVal, oldVal) {
      if (this.$route.name === this.$config.navigation.industria.home.link) this.setData();
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-carousel__controls {
  & .v-item-group {
    margin-top: -20px;
  }
  & .v-carousel__controls__item {
    height: 10px;
    width: 10px;
    margin: 0 6px;
    & .v-btn__content i {
      color: #fff;
      font-size: 12px !important;
    }
    &.v-btn--active i {
      opacity: 1;
      color: #fff;
    }
  }
}

.carousel-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  & > div {
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

::v-deep .carousel-overlay {
  .hero-headline {
    > * {
      font-size: 52px !important;
      line-height: 62px !important;
      font-weight: 800;
      @media screen and (max-width: 1100px) {
        font-size: 42px !important;
        line-height: 52px !important;
      }
      @media screen and (max-width: 800px) {
        font-size: 26px !important;
        line-height: 32px !important;
      }
    }
    .highlight {
      color: $palette0;
      font-family: $titleFont;
    }
  }

  .hero-description {
    > * {
      font-weight: 400;
      @media screen and (max-width: 1100px) {
        font-size: 16px !important;
      }
      @media screen and (max-width: 800px) {
        font-size: 14px !important;
      }
    }
  }
}

::v-deep .v-parallax__content {
  background: linear-gradient(
    90deg,
    rgba(33, 37, 41, 0.8),
    rgba(33, 37, 41, 0.7)
  );
}
</style>