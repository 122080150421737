<template>
  <div class="services-page-wrapper">
    <hero-inner-page
      :src="$routes.asset(`img/industria/hero/services/hero-inner-page.jpg`)"
      :routes="[$config.navigation.industria.services]"
    ></hero-inner-page>

    <div class="py-12 px-4 bg-main">
      <div class="limit-width py-12">

        <div class="intro-wrapper">
          <div>
            <h1 class="mb-6 highlight">{{ $t('industria.pages.services.intro.title') }}</h1>

            <p>{{ $t('industria.pages.services.intro.text[0]') }}.</p>
            <p>{{ $t('industria.pages.services.intro.text[1]') }}.</p>
            <p>{{ $t('industria.pages.services.intro.text[2]') }}.</p>
            <p class="mb-0"><b>{{ $t('industria.pages.services.intro.text[3]') }}.</b></p>
          </div>

          <div :class="{ 'mt-6': $vuetify.breakpoint.width < 900 }">
            <v-img
              :src="$routes.asset(`img/industria/services/solutions.jpg`)"
              alt="Conjunto soluções Romafe"
              :max-width="$vuetify.breakpoint.width < 400 ? 220 : $vuetify.breakpoint.width < 600 ? 300 : 400"
            ></v-img>
          </div>
        </div>

        <hr class="my-12">

        <h2 class="mb-2 text-uppercase h5 text-center"><b>{{ $t('industria.pages.services.sectors.title') }}</b></h2>
        <div
          class="divider mt-4"
          style="margin: 0 auto;"
        ></div>

        <div class="sector-wrapper mt-6">
          <div
            class="d-flex justify-center align-center mt-4"
            :class="$vuetify.breakpoint.width < 450 ? 'pa-1' : 'pa-4'"
            v-for="(item, i) in $t('industria.pages.services.sectors.values')"
            :key="item"
          >
            <v-icon :size="$vuetify.breakpoint.width < 450 ? '32' : '38'">{{ icons[i] }}</v-icon>
            <h3 class="mt-4 sector-value text-uppercase h6">{{ item }}</h3>
          </div>
        </div>

      </div>
    </div>

    <v-sheet
      height="100%"
      tile
      color="#333"
      class="benefits-wrapper"
    >
      <v-parallax
        :src="$routes.asset('img/industria/services/benefits.jpg')"
        :lazy-src="$routes.asset('img/industria/services/benefits-lazy.jpg')"
        alt='Vantagens ao escolher a Romafe'
        :height="350"
      >
      </v-parallax>

      <div class="overlay px-4">
        <div class="limit-width overlay-content white--text py-8">
          <h1 class="highlight white--text">{{ $t('industria.pages.services.benefits.title[0]') }}<br><span class="highlight">{{ $t('industria.pages.services.benefits.title[1]') }}</span></h1>
          <div class="mt-6">
            <div class="list-indented">
              <p
                v-for="(benefit, i) in $t('industria.pages.services.benefits.values')"
                :key="i"
                class="mb-0"
              >
                <v-icon class="list-bullet">mdi-circle-medium</v-icon>{{ benefit }}<span v-if="i != $t('industria.pages.services.benefits.values').length - 1">;</span><span v-else>.</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </v-sheet>

    <div class="pa-4 call-to-action">
      <div class="limit-width">
        <div>
          <h2 class="h6">{{ $t('industria.pages.services.benefits.calltoaction') }}</h2>
        </div>
        <div class="ml-4">
          <router-link
            class="router-link"
            :to="{ name: $config.navigation.industria.contacts.link }"
          >
            <v-btn
              class="main-btn alt"
              dark
              :title="$t('misc.contact.us')"
            >
              {{ $t('misc.contact.us') }}
            </v-btn>
          </router-link>
        </div>
      </div>
    </div>

    <div id="our-services-wrapper" class="py-12 px-4 bg-alt">
      <div class="limit-width py-12">

        <h1 class="mb-6 highlight">{{ $t('industria.pages.services.services.title') }}</h1>

        <div>
          <v-expansion-panels>
            <v-expansion-panel
              v-for="(item, i) in $t('industria.pages.services.services.values')"
              :key="i"
            >
              <v-expansion-panel-header>
                <div v-html="item.title"></div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>

                <div v-if="item.sub">
                  <div
                    v-for="(sub, j) in item.sub"
                    :key="j"
                  >

                    <div
                      class="mb-2"
                      :class="{'mt-4': j != 0}"
                    >
                      <b>{{ (j + 1) }}. {{ sub.title }}</b>
                    </div>

<!-- Bullet List removida, Bruno Sena 8/4/22: -->

                     <!--  <div v-if="sub.values">
                      <div class="list-indented">
                        <p
                          v-for="(value, k) in sub.values"
                          :key="value"
                          class="mb-0"
                        >
                          <v-icon class="list-bullet">mdi-circle-medium</v-icon>{{ value }}<span v-if="k != sub.values.length - 1">;</span><span v-else>.</span>
                        </p>
                      </div>
                    </div>  -->

                  </div>
                </div>

                <div v-if="item.text">
                  <div v-html="item.text + '.'"></div>
                </div>

              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>

        <hr class="my-12">

        <h2 class="mb-2 text-uppercase h5 text-center"><b>{{ $t('industria.pages.services.solutions.title') }}</b></h2>
        <div
          class="divider mt-4 mb-6"
          style="margin: 0 auto;"
        ></div>

        <div
          class="products-wrapper limit-width d-flex justify-center"
          id="products-wrapper"
        >
          <v-expand-x-transition>
            <div
              class="products-grid"
              v-show="single.show === false && single.index === null"
            >
              <div
                v-for="(item, i) in products"
                :key="i"
                @click="showSingle(i)"
              >

                <v-hover v-slot:default="{ hover }">
                  <v-card :class="{ hover: hover }">
                    <v-img
                      class="img-wrapper"
                      :class="{ zoom: hover }"
                      :src="item.img"
                    ></v-img>

                    <div class="pa-2">
                      <h3 class="text-uppercase h6 highlight product-title">{{ $t(`industria.pages.services.solutions.values[${i}].title`) }}</h3>
                    </div>

                  </v-card>
                </v-hover>

              </div>
            </div>
          </v-expand-x-transition>

          <v-expand-x-transition>
            <v-card
              v-show="single.show === true && single.index !== null"
              :height="$vuetify.breakpoint.width > 850 ? 322 : 'auto'"
              width="100%"
              class="pa-4"
            >
              <div
                class="d-flex flex-column justify-space-between"
                style="height:100%"
              >
                <div
                  v-if="single.index !== null"
                  class="product-single-content"
                  :class="single.class"
                >
                  <h2 class="text-uppercase h6 highlight mb-2">{{ $t(`industria.pages.services.solutions.values[${single.index}].title`) }}</h2>
                </div>

                <div
                  v-if="single.index !== null"
                  class="product-single-content"
                  :class="single.class"
                >
                  <div
                    v-for="description in $t(`industria.pages.services.solutions.values[${single.index}].description`)"
                    :key="description"
                  >
                    {{ description }}.<br><br>
                  </div>
                </div>

                <div
                  class="d-flex align-center justify-end product-single-content"
                  :class="single.class"
                >
                  <v-btn
                    class="main-btn"
                    dark
                    @click="goBack"
                    aria-label="go-back"
                    :title="$t('misc.nav.back')"
                  >
                    <v-icon left>
                      mdi-arrow-left
                    </v-icon>
                    {{ $t('misc.nav.back') }}
                  </v-btn>
                </div>
              </div>
            </v-card>
          </v-expand-x-transition>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import solutions from '@mixins/industria/solutions/solutions';

export default {
  data: () => ({
    icons: [
      'mdi-gas-station',
      'mdi-file-document-multiple',
      'mdi-silverware-fork-knife',
      'mdi-wall',
      'mdi-pine-tree',
      'mdi-recycle',
      'mdi-flash',
      'mdi-flask-outline',
    ],
    single: {
      show: false,
      index: null,
      class: '',
    }
  }),
  mixins: [solutions],
  methods: {
    showSingle(i) {
      if (this.$vuetify.breakpoint.width <= 850) return;

      this.single.show = true;

      setTimeout(() => {
        this.single.index = i;
      }, 350);

      setTimeout(() => {
        this.single.class = 'show';
      }, 550);
    },
    goBack() {
      this.single.show = false;
      this.single.class = 'hide';

      setTimeout(() => {
        this.single.index = null;
      }, 350);
    },
    checkRouteParams() {
      let params = this.$route.params;

      if ('solutionsIndex' in params) {
        let index = params.solutionsIndex;
        if (index <= this.products.length - 1) {
          setTimeout(() => {
            const y = document.getElementById('products-wrapper').getBoundingClientRect().top + window.scrollY;
            window.scrollTo({ top: y, behavior: 'smooth' });

            setTimeout(() => {
              this.showSingle(index);
            }, 450);
          }, 0);

        } else if (index == this.products.length) {
          //! Goes to serviços part of the page
          setTimeout(() => {
            const y = document.getElementById('our-services-wrapper').getBoundingClientRect().top + window.scrollY;
            window.scrollTo({ top: y, behavior: 'smooth' });
          }, 0);
        }
      }
    }
  },
  created() {
    this.checkRouteParams();
  }
}
</script>

<style lang="scss" scoped>
.sector-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 6px;

  & > div {
    flex-direction: column;
    &:hover {
      background-color: rgba(0, 0, 0, 0.03);
    }
  }

  .sector-value {
    font-size: 12px !important;
    font-weight: normal;
    @media screen and (max-width: 450px) {
      font-size: 11px !important;
    }
    @media screen and (max-width: 380px) {
      font-size: 10px !important;
    }
    @media screen and (max-width: 350px) {
      font-size: 12px !important;
    }
  }

  @media screen and (max-width: 850px) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2px;
  }

  @media screen and (max-width: 650px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 350px) {
    grid-template-columns: 1fr;
  }
}

.intro-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  & > div:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
  }
}

.benefits-wrapper {
  position: relative;

  .overlay {
    background: linear-gradient(
      90deg,
      rgba(33, 37, 41, 0.9),
      rgba(33, 37, 41, 0.9)
    );
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .overlay-content {
    width: 100%;
    height: 100%;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    @media screen and (max-width: 500px) {
      & > div:nth-child(2) {
        font-size: 14px;
      }
    }
  }
}

.products-wrapper {
  min-height: 332px;

  .product-title {
    font-size: 12px !important;
    transition: 250ms ease-in-out;
  }

  .product-description {
    font-size: 14px;
  }

  .products-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 16px;

    & > div {
      max-width: 238px;
    }

    .v-card {
      cursor: pointer;
      &.hover {
        .product-title {
          opacity: 0.7;
        }
      }
    }

    @media screen and (max-width: 1050px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media screen and (max-width: 850px) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 550px) {
      grid-template-columns: 1fr;

      & > div {
        max-width: 400px;
      }
    }

    @media screen and (max-width: 450px) {
      & > div {
        max-width: 300px;
      }
    }

    @media screen and (max-width: 320px) {
      & > div {
        max-width: 250px;
      }
    }
  }
}

.product-single-content {
  opacity: 0;
  transition: 250ms ease-in-out;
  &.show {
    opacity: 1;
  }
  &.hide {
    opacity: 0;
    transition: none;
  }
}

::v-deep .img-wrapper {
  cursor: pointer;
  .v-image__image {
    transition: 250ms ease-in-out;
  }

  &.zoom {
    .v-image__image {
      opacity: 0.5;
      transform: scale(1.2);
    }
  }
}

.call-to-action {
  h6 {
    @media screen and (max-width: 500px) {
      font-size: 14px;
    }
  }
}

::v-deep .highlight {
  color: $palette0;
  font-weight: bold;
}
</style>