var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "about-page-wrapper", class: _vm.sector },
    [
      _c("hero-inner-page", {
        attrs: {
          src: _vm.$routes.asset(
            "img/" + _vm.sector + "/hero/about/hero-inner-page.jpg"
          ),
          routes: [_vm.$config.navigation[_vm.sector].about],
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "py-12 px-4 bg-main" }, [
        _c("div", { staticClass: "limit-width py-12" }, [
          _c("div", { staticClass: "about-1-wrapper" }, [
            _c("div", [
              _c("h1", { staticClass: "mb-6 highlight" }, [
                _vm._v(
                  _vm._s(_vm.$t(_vm.localePath + ".pages.about.journey.title"))
                ),
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.localePath + ".pages.about.journey.text[0]")
                  ) + "."
                ),
              ]),
              _c("br"),
              _c("br"),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.localePath + ".pages.about.journey.text[1]")
                  ) + "."
                ),
              ]),
              _c("br"),
              _c("br"),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.localePath + ".pages.about.journey.text[2]")
                  ) + "."
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { class: { "mt-6": _vm.$vuetify.breakpoint.width < 900 } },
              [
                _c("v-img", {
                  attrs: {
                    src: _vm.$routes.asset("img/components/about/bearing.jpg"),
                    alt: "Rolamentos Romafe",
                    "max-width":
                      _vm.$vuetify.breakpoint.width < 400
                        ? 220
                        : _vm.$vuetify.breakpoint.width < 600
                        ? 300
                        : 400,
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("hr", { staticClass: "mt-12" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "about-2-wrapper mt-12" },
            _vm._l(_vm.$t("pages.about.journey.values"), function (item) {
              return _c("div", { key: item.title }, [
                _c("h2", { staticClass: "mb-2 text-uppercase h5" }, [
                  _c("b", [_vm._v(_vm._s(item.title))]),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "highlight-description" }, [
                  _vm._v(_vm._s(item.description) + "."),
                ]),
              ])
            }),
            0
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "v-sheet",
        {
          staticClass: "about-3-wrapper",
          attrs: { height: "100%", tile: "", color: "#333" },
        },
        [
          _c("v-parallax", {
            attrs: {
              src: _vm.$routes.asset(
                _vm.isIndustria
                  ? "img/components/about/services.jpg"
                  : "img/components/about/products.jpg"
              ),
              "lazy-src": _vm.$routes.asset(
                _vm.isIndustria
                  ? "img/components/about/services-lazy.jpg"
                  : "img/components/about/products-lazy.jpg"
              ),
              alt: _vm.isIndustria
                ? "Soluções e serviços Romafe"
                : "Gamas de produtos Romafe",
              height: _vm.isIndustria ? 350 : 300,
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "overlay px-4" }, [
            _c(
              "div",
              { staticClass: "limit-width overlay-content white--text py-8" },
              [
                _c("h1", { staticClass: "highlight white--text" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        _vm.localePath +
                          ".pages.about.sectorcalltoaction.title[0]"
                      )
                    )
                  ),
                  _c("br"),
                  _c("span", { staticClass: "highlight" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          _vm.localePath +
                            ".pages.about.sectorcalltoaction.title[1]"
                        )
                      )
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mt-6" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        _vm.localePath +
                          ".pages.about.sectorcalltoaction.text[0]"
                      )
                    ) + "."
                  ),
                  _c("br"),
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          _vm.localePath +
                            ".pages.about.sectorcalltoaction.text[1]"
                        )
                      ) +
                      "."
                  ),
                  _c("br"),
                ]),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "pa-4 call-to-action about-4-wrapper" }, [
        _c("div", { staticClass: "limit-width" }, [
          _c("div", [
            _c("h2", { staticClass: "h6" }, [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    _vm.localePath +
                      ".pages.about.sectorcalltoaction.calltoaction"
                  )
                )
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ml-4" },
            [
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: {
                    to: {
                      name: _vm.isIndustria
                        ? _vm.$config.navigation.industria.services.link
                        : _vm.$config.navigation.auto.products.link,
                    },
                  },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "main-btn hover-alt",
                      attrs: {
                        dark: "",
                        title: _vm.isIndustria
                          ? _vm.$t(
                              _vm.localePath +
                                ".navigation.links." +
                                _vm.$config.navigation.industria.services
                                  .localePath
                            )
                          : _vm.$t(
                              _vm.localePath +
                                ".navigation.links." +
                                _vm.$config.navigation.auto.products.localePath
                            ),
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.isIndustria
                              ? _vm.$t(
                                  _vm.localePath +
                                    ".navigation.links." +
                                    _vm.$config.navigation.industria.services
                                      .localePath
                                )
                              : _vm.$t(
                                  _vm.localePath +
                                    ".navigation.links." +
                                    _vm.$config.navigation.auto.products
                                      .localePath
                                )
                          ) +
                          "\n          "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "py-12 px-4 bg-alt" }, [
        _c("div", { staticClass: "limit-width py-12" }, [
          _c("div", { staticClass: "about-5-wrapper" }, [
            _c(
              "div",
              [
                _c("h1", { staticClass: "mb-8 highlight alt" }, [
                  _vm._v(_vm._s(_vm.$t("pages.about.values.title"))),
                ]),
                _vm._v(" "),
                _c(
                  "v-card",
                  [
                    _c(
                      "v-tabs",
                      {
                        attrs: {
                          "background-color": "#FFF",
                          "icons-and-text": _vm.$vuetify.breakpoint.width > 600,
                          color: _vm.isIndustria ? "#ec921d" : "#cf372d",
                          "fixed-tabs": "",
                          "show-arrows": "",
                        },
                        model: {
                          value: _vm.values.tab,
                          callback: function ($$v) {
                            _vm.$set(_vm.values, "tab", $$v)
                          },
                          expression: "values.tab",
                        },
                      },
                      [
                        _c("v-tabs-slider"),
                        _vm._v(" "),
                        _vm._l(
                          _vm.$t("pages.about.values.values"),
                          function (item, i) {
                            return _c(
                              "v-tab",
                              {
                                key: item.title + i,
                                attrs: {
                                  href: "#tab-" + (i + 1),
                                  title: item.title,
                                },
                              },
                              [
                                _vm.$vuetify.breakpoint.width > 600
                                  ? _c("b", [_vm._v(_vm._s(item.title))])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("v-icon", { staticClass: "mb-1" }, [
                                  _vm._v(_vm._s(_vm.values.icons[i])),
                                ]),
                              ],
                              1
                            )
                          }
                        ),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "v-tabs-items",
                      {
                        model: {
                          value: _vm.values.tab,
                          callback: function ($$v) {
                            _vm.$set(_vm.values, "tab", $$v)
                          },
                          expression: "values.tab",
                        },
                      },
                      _vm._l(
                        _vm.$t("pages.about.values.values"),
                        function (item, i) {
                          return _c(
                            "v-tab-item",
                            {
                              key: item.title + i,
                              attrs: { value: "tab-" + (i + 1), eager: true },
                            },
                            [
                              _c("v-card", { attrs: { flat: "" } }, [
                                _c(
                                  "div",
                                  { staticClass: "card-content pa-4" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "px-4" },
                                      [
                                        _c("v-img", {
                                          attrs: {
                                            src: _vm.$routes.asset(
                                              "img/components/about/value-" +
                                                (i + 1) +
                                                ".jpg"
                                            ),
                                            alt: "Romafe - " + item.title,
                                            "max-width":
                                              _vm.$vuetify.breakpoint.width <
                                              400
                                                ? 200
                                                : _vm.$vuetify.breakpoint
                                                    .width < 500
                                                ? 250
                                                : 400,
                                            eager:
                                              _vm.$vuetify.breakpoint.mobile,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "px-4" }, [
                                      _c("h2", { staticClass: "mb-2" }, [
                                        _vm._v(_vm._s(item.subtitle)),
                                      ]),
                                      _vm._v(" "),
                                      _c("p", {
                                        domProps: {
                                          innerHTML: _vm._s(item.text + "."),
                                        },
                                      }),
                                    ]),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          )
                        }
                      ),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "v-sheet",
        {
          staticClass: "about-6-wrapper",
          attrs: { height: "100%", tile: "", color: "#333" },
        },
        [
          _c("v-parallax", {
            attrs: {
              src: _vm.$routes.asset("img/components/about/commitment.jpg"),
              "lazy-src": _vm.$routes.asset(
                "img/components/about/commitment-lazy.jpg"
              ),
              alt: "Compromisso qualidade Romafe",
              height: _vm.$vuetify.breakpoint.width < 450 ? 600 : 500,
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "overlay px-4" }, [
            _c(
              "div",
              { staticClass: "limit-width overlay-content white--text py-8" },
              [
                _c("h1", { staticClass: "highlight white--text" }, [
                  _vm._v(
                    _vm._s(_vm.$t("pages.about.commitment.title[0]")) + " "
                  ),
                  _c("span", { staticClass: "highlight" }, [
                    _vm._v(_vm._s(_vm.$t("pages.about.commitment.title[1]"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mt-6" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("pages.about.commitment.text[0]")) +
                      "."
                  ),
                  _c("br"),
                  _c("br"),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("pages.about.commitment.text[1]")) +
                      ".\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "qualidade-wrapper" }, [
                  _c("div", { staticClass: "mr-4 mb-4" }, [
                    _c(
                      "a",
                      {
                        staticClass: "quick-link blue--text",
                        attrs: {
                          href: _vm.$routes.asset("pdf/CERTIFICADO_TUV_pt.pdf"),
                          rel: "noopener noreferrer",
                          target: "_blank",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("pages.about.commitment.certificate")) +
                            " - PT"
                        ),
                      ]
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "quick-link blue--text",
                        attrs: {
                          href: _vm.$routes.asset("pdf/CERTIFICADO_TUV_en.pdf"),
                          rel: "noopener noreferrer",
                          target: "_blank",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("pages.about.commitment.certificate")) +
                            " - EN"
                        ),
                      ]
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "quick-link blue--text",
                        attrs: {
                          href: _vm.$routes.asset("pdf/CERTIFICADO_TUV_pt.pdf"),
                          rel: "noopener noreferrer",
                          target: "_blank",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("pages.about.commitment.policy")))]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-4" },
                    [
                      _c("v-img", {
                        attrs: {
                          width: "200",
                          eager: "",
                          src: _vm.$routes.asset(
                            "img/certificates/quality-certificate.jpg"
                          ),
                          alt: "Logo Qualidade Romafe - ISO 9001",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "py-12 px-4 bg-main" }, [
        _c("div", { staticClass: "limit-width py-12" }, [
          _c(
            "div",
            { staticClass: "about-7-wrapper" },
            [
              _c("h1", { staticClass: "mb-6 highlight" }, [
                _vm._v(_vm._s(_vm.$t("pages.about.policies.title"))),
              ]),
              _vm._v(" "),
              _vm._l(_vm.$t("pages.about.policies.values"), function (item, i) {
                return _c("div", { key: item.title }, [
                  _c(
                    "h2",
                    { staticClass: "mb-4 h4", class: { "mt-6": i != 0 } },
                    [_vm._v(_vm._s(item.title))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "list-indented" },
                    _vm._l(item.text, function (policy, i) {
                      return _c(
                        "p",
                        { key: i, staticClass: "mb-0" },
                        [
                          _c("v-icon", { staticClass: "list-bullet" }, [
                            _vm._v("mdi-circle-medium"),
                          ]),
                          _vm._v(_vm._s(policy)),
                          i != item.text.length - 1
                            ? _c("span", [_vm._v(";")])
                            : _c("span", [_vm._v(".")]),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ])
              }),
            ],
            2
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "pa-4 call-to-action alt about-4-wrapper" }, [
        _c("div", { staticClass: "limit-width" }, [
          _c("div", [
            _c("h2", { staticClass: "h6" }, [
              _vm._v(_vm._s(_vm.$t("pages.about.infocalltoaction"))),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ml-4" },
            [
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: {
                    to: {
                      name: _vm.$config.navigation[_vm.sector].contacts.link,
                    },
                  },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "main-btn alt",
                      attrs: { dark: "", title: _vm.$t("misc.contact.us") },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("misc.contact.us")) +
                          "\n          "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }