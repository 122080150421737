var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.$vuetify.breakpoint.mobile
    ? _c("v-app-bar", { attrs: { dark: "", color: "#004b81" } }, [
        _vm._v("\n  " + _vm._s(_vm.title) + "\n"),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }