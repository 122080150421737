const stats = {
  data: () => ({
    stats: {
      industry: ['', '60', '14 000 +', '130 000 +'],
      auto: ['6 000 m<sup>2</sup> +', '60', '14 000 +', '75 000 +'],
    },
  }),
  methods: {
    setYearsOfService() {
      var ageDifMs = Date.now() - new Date(1945, 9, 27);
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      let years = Math.abs(ageDate.getUTCFullYear() - 1970);
      this.stats.industry[0] = years;
    }
  },
  created() {
    this.setYearsOfService();
  }
}

export default stats;