var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.frontpageNews.length > 0
    ? _c("div", { staticClass: "news-wrapper py-12 px-4" }, [
        _c("div", { staticClass: "limit-width py-12" }, [
          _c("h2", { staticClass: "bolder" }, [
            _vm._v(_vm._s(_vm.$t("industria.pages.home.news.title"))),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "divider mt-4" }),
          _vm._v(" "),
          _c("div", { staticClass: "mt-6" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$t(this.sector + ".pages.home.news.description[0]")
                ) +
                "."
            ),
            _c("br"),
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$t(this.sector + ".pages.home.news.description[1]")
                ) +
                ".\n    "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "news-content-wrapper mt-6", class: _vm.sector },
            _vm._l(_vm.frontpageNews, function (item, i) {
              return _c(
                "v-img",
                {
                  key: item.id,
                  staticClass: "elevation-4",
                  class: "news-" + (i + 1),
                  attrs: {
                    src: i === 0 ? _vm.$routes.asset(item.imagem) : "",
                    "min-height": 152,
                    alt: item.titulo,
                    eager: _vm.$vuetify.breakpoint.mobile,
                  },
                },
                [
                  item.loaded === false &&
                  i === 0 &&
                  _vm.$vuetify.breakpoint.width > 1100
                    ? _c("v-skeleton-loader", {
                        staticClass: "news-primary-wrapper",
                        attrs: { type: "card" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  item.loaded !== false &&
                  i === 0 &&
                  _vm.$vuetify.breakpoint.width > 1100
                    ? _c("div", { staticClass: "news-primary-wrapper" }, [
                        _c("div", { staticClass: "news-content ma-4" }, [
                          _c(
                            "div",
                            { staticClass: "pa-4" },
                            [
                              _c("h3", { staticClass: "news-date mt-1" }, [
                                _vm._v(_vm._s(item.data)),
                              ]),
                              _vm._v(" "),
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: _vm.$config.navigation[_vm.sector]
                                        .newsSingle.link,
                                      params: {
                                        title: _vm.$routes.sanitize(
                                          item.titulo
                                        ),
                                      },
                                      query: { id: item.id },
                                    },
                                    title: item.titulo,
                                  },
                                },
                                [
                                  _c(
                                    "h3",
                                    {
                                      staticClass: "news-title h4 mt-2",
                                      attrs: { title: item.titulo },
                                    },
                                    [_vm._v(_vm._s(item.titulo))]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "divider mt-2" }),
                              _vm._v(" "),
                              _c("div", {
                                staticClass: "news-description mt-2",
                                domProps: { innerHTML: _vm._s(item.resumo) },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  item.loaded === false &&
                  (i !== 0 || _vm.$vuetify.breakpoint.width <= 1100)
                    ? _c("v-skeleton-loader", {
                        staticClass: "news-secondary-wrapper",
                        attrs: { type: "image, article" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  item.loaded !== false &&
                  (i !== 0 || _vm.$vuetify.breakpoint.width <= 1100)
                    ? _c(
                        "div",
                        { staticClass: "news-secondary-wrapper" },
                        [
                          _c("v-hover", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var hover = ref.hover
                                    return [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: _vm.$config.navigation[
                                                _vm.sector
                                              ].newsSingle.link,
                                              params: {
                                                title: _vm.$routes.sanitize(
                                                  item.titulo
                                                ),
                                              },
                                              query: { id: item.id },
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-img",
                                            {
                                              staticClass: "news",
                                              class: { zoom: hover },
                                              attrs: {
                                                src: _vm.$routes.asset(
                                                  item.imagem
                                                ),
                                                "min-height": 152,
                                                "max-width": 152,
                                                alt: "Notícia - " + item.titulo,
                                                eager:
                                                  _vm.$vuetify.breakpoint
                                                    .mobile,
                                              },
                                            },
                                            [
                                              _c(
                                                "v-scale-transition",
                                                {
                                                  attrs: {
                                                    origin: "center center",
                                                  },
                                                },
                                                [
                                                  hover
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "v-card--reveal white--text hover-overlay",
                                                          staticStyle: {
                                                            height: "100%",
                                                          },
                                                        },
                                                        [
                                                          _c("div", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "misc.more.read"
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color: "white",
                                                                size: "20",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-chevron-right"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "news-content" }, [
                            _c(
                              "div",
                              { staticClass: "py-2 px-4" },
                              [
                                _c("h3", { staticClass: "news-date mt-1" }, [
                                  _vm._v(_vm._s(item.data)),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: _vm.$config.navigation[_vm.sector]
                                          .newsSingle.link,
                                        params: {
                                          title: _vm.$routes.sanitize(
                                            item.titulo
                                          ),
                                        },
                                        query: { id: item.id },
                                      },
                                      title: item.titulo,
                                    },
                                  },
                                  [
                                    _c(
                                      "h3",
                                      {
                                        staticClass: "news-title mt-2 h6",
                                        attrs: { title: item.titulo },
                                      },
                                      [_vm._v(_vm._s(item.titulo))]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass: "news-description mt-1",
                                  domProps: { innerHTML: _vm._s(item.resumo) },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }