<template>
  <div
    class="news-page-single-wrapper"
    :class="sector"
  >
    <hero-inner-page
      :src="$routes.asset('/img/components/hero/news/hero-inner-page.jpg')"
      :routes="[$config.navigation[sector].news, $config.navigation[sector].newsSingle]"
    ></hero-inner-page>

    <div
      class="py-12 px-4"
      v-if="post"
    >
      <div class="limit-width py-12">
        <h1
          class="highlight post-title"
          :class="$vuetify.breakpoint.width > 900 ? 'mb-12 h1' : 'mb-6 h4'"
        >{{ post.titulo }}</h1>

        <div class="post-content-wrapper">
          <div class="img-wrapper">
            <v-img
              class="post-img mb-6 mt-2 elevation-8"
              :class="{ 'ml-10': $vuetify.breakpoint.width > 900 }"
              :alt="post.titulo"
              :src="$routes.asset(post.imagem)"
              :max-height="$vuetify.breakpoint.width > 900 ? 500 : 350"
              :max-width="$vuetify.breakpoint.width > 900 ? 500 : 350"
              :eager="$vuetify.breakpoint.mobile"
            >
            </v-img>
          </div>

          <div
            v-html="post.conteudo"
            class="post-content"
          ></div>
        </div>

      </div>
    </div>

    <div
      class="py-12 px-4"
      v-else
    >
      <div class="limit-width py-12">
        <v-skeleton-loader
          class="post-title-load"
          :class="$vuetify.breakpoint.width > 900 ? 'mb-12' : 'mb-6'"
          type="heading"
        ></v-skeleton-loader>

        <div class="post-content-wrapper-load">

          <v-skeleton-loader
            class="post-img-load"
            :class="$vuetify.breakpoint.width > 900 ? 'ml-6' : 'mb-6'"
            type="image"
            :min-height="$vuetify.breakpoint.width > 900 ? 500 : 350"
            :min-width="$vuetify.breakpoint.width > 900 ? 500 : 350"
          ></v-skeleton-loader>

          <v-skeleton-loader
            class="post-content-load"
            type="sentences, paragraph, text, sentences, paragraph, text, paragraph, paragraph, text, sentences"
          ></v-skeleton-loader>

        </div>
      </div>
    </div>

    <div class="pa-4 call-to-action alt about-4-wrapper">
      <div class="limit-width">
        <div>
          <h2 class="h6">{{ $t('pages.about.infocalltoaction') }}</h2>
        </div>
        <div class="ml-4">
          <router-link
            class="router-link"
            :to="{ name: $config.navigation[sector].contacts.link }"
          >
            <v-btn
              class="main-btn alt"
              dark
              :title="$t('misc.contact.us')"
            >
              {{ $t('misc.contact.us') }}
            </v-btn>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sector from '@mixins/sector/sector';

export default {
  mixins: [sector],
  methods: {
    getPost() {
      if (!this.post) this.$store.commit(`${this.sector}/news/getSingle`, this.id);
    },
  },
  computed: {
    id() {
      return this.$route.query.id;
    },
    title() {
      return this.$route.params.title;
    },
    post() {
      return this.$store.getters[`${this.sector}/news/getPostById`](this.id);
    }
  },
  created() {
    this.getPost();
  },
  watch: {
    'post': function (newVal, oldVal) {
      //Sets news meta data
      const descEl = document.querySelector('head meta[name="description"]');
      descEl.setAttribute('content', newVal?.description ?? '');
    },
    '$root.$i18n.locale': function (newVal, oldVal) {
      this.getPost();
    }
  },
}
</script>

<style lang="scss" scoped>
.news-page-single-wrapper {
  &.industria {
    ::v-deep .highlight {
      color: $palette0 !important;
    }
  }
  &.auto {
    ::v-deep .highlight {
      color: $palette1 !important;
    }
  }
}

.post-title {
  line-height: 38px !important;
  &.h4 {
    line-height: 26px !important;
  }
}

.post-img,
.post-img-load {
  float: right;
  @media screen and (max-width: 900px) {
    float: initial;
  }
  @media screen and (max-width: 600px) {
    margin: 0 auto;
  }
}

.post-content {
  text-align: justify;
}

.post-content-wrapper,
.post-content-wrapper-load {
  min-height: 450px;
}

::v-deep .post-title-load {
  & > div {
    width: 100%;
  }
}

::v-deep .post-img-load {
  & > div {
    height: 410px;
  }
}
</style>