<template>
    <div>
        <intro-app-bar :title="'Consulta de stocks'"></intro-app-bar>
        <div class="square_center">
            <v-card outlined elevation="5" width="100%" height="100%">
                <div class="blured">
                    <div class="row row_pesquisa">
                        <div class="col-lg-4" id="rmf-searchbar-row">
                            <div class="col-md-12">
                                <div class="input-group">
                                    <v-text-field v-model="search" outlined append-icon="mdi-magnify"
                                        label="Introduza código artigo/fabricante" single-line hide-details
                                        @click:append="refreshDataGrid" @keydown.enter="refreshDataGrid"
                                        disabled></v-text-field>
                                </div>
                                <div id="rmf-notas">
                                    <h5>Notas:</h5>
                                    <ul>
                                        <li>
                                            Critério da pesquisa: Total ou Parte
                                        </li>
                                        <li>Os espaços não são considerados</li>
                                        <li><i>Case-Insensitive</i></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12" id="row_stocks">
                            <DxDataGrid @initialized="saveGridInstance" :data-source="articleData" id="gridContainer"
                                :show-borders="true" key-expr="codart" :column-min-width="100" :column-max-width="100"
                                @cell-prepared="onCellPrepared" :show-column-lines="true" :show-row-lines="true"
                                :hover-state-enabled="true" :remote-operations="false">
                                <DxPaging :page-size="15" />
                                <DxColumn :width="300" data-field="codart" caption="Artigo" />
                                <DxColumn :width="300" data-field="codartext" caption="Cód. Fabricante" />
                                <DxColumn data-field="precotabela" caption="Preço Tabela (Euros)" alignment="right" />
                                <DxColumn data-field="chegada" caption="Dt. Prev. Chegada" alignment="right" />
                                <DxMasterDetail :enabled="true" template="master-detail" />
                                <template #master-detail="{ data }">
                                    <MasterDetail :codartint="data.key" />
                                </template>
                            </DxDataGrid>
                        </div>
                    </div>
                </div>
                <!-- v-alert positioned on top of the blurred content -->
                <v-alert class="alert-overlay" border="left" color="indigo" dark>
                    Estamos a melhorar esta página para si. Em breve teremos novidades!
                </v-alert>

            </v-card>
        </div>
    </div>
</template>
<script>
import {
    DxDataGrid,
    DxColumn,
    DxMasterDetail,
    DxScrolling,
    DxPaging,
    DxPager
} from "devextreme-vue/data-grid";

import MasterDetail from "/webintro/resources/js/components/intro/masterDetailGrid/MasterDetail.vue";
import { createStore } from 'devextreme-aspnet-data-nojquery';




export default {
    components: {
        DxPager,
        DxDataGrid,
        DxColumn,
        DxMasterDetail,
        MasterDetail,
        DxScrolling,
        DxPaging
    },
    data() {
        return {
            dataGridInstance: null,
            search: "",
            filtro_encoded: "",

        };
    },


    methods: {

        saveGridInstance: function (e) {
            this.dataGridInstance = e.component;
        },
        refreshDataGrid: function () {

            this.filtro_encoded = encodeURIComponent(encodeURIComponent(this.search));

            if (this.filtro_encoded) {

                this.dataGridInstance.refresh();

            }
            else {
                this.dataGridInstance.dispose();
                this.dataGridInstance.refresh();


            }
        },




        onCellPrepared(e) {
            if (e.rowType == "header" && e.columnIndex == 1) {
                e.cellElement.style.textAlign = "left";
            }
            if (e.rowType == "header" && e.columnIndex == 2) {
                e.cellElement.style.textAlign = "left";
            }
            if (e.rowType == "header" && e.columnIndex == 3) {
                e.cellElement.style.textAlign = "right";
            }
            if (e.rowType == "header" && e.columnIndex == 4) {
                e.cellElement.style.textAlign = "right";
            }
        }
    },

    computed: {
        articleData() {
            return this.filtro_encoded === "" ? null : {
                store: createStore({
                    /* key: 'codartint',
                    loadUrl: `${this.$routes.api.get_articles}/${this.filtro_encoded}` */
                    key: 'null',
                    loadUrl: `null`
                }),
            };
        },
    },
};
</script>

<style lang="scss" scoped>
#grid-container {
    height: 600px;
}

.row_pesquisa {
    margin-top: 0;
}

.square_center {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 10px;
}

#rmf-searchbar-button {
    height: 34px;
}

#rmf-searchbar-row {
    padding-right: 2.5px;
}

#rmf-notas {
    ul {
        list-style: disc;
        padding-left: 18px;
    }

    h5 {
        margin-top: 10px;
        margin-bottom: 0;
    }

    p {
        margin-bottom: 0;
    }
}

#row_stocks {
    margin-top: -25px;
    padding: 0 30px 30px 30px;
}

.blured {
    filter: blur(2px);
}

.alert-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}
</style>
