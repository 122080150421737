var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "language-wrapper",
      attrs: { title: _vm.$t("misc.language.change") },
    },
    [
      _c("v-select", {
        attrs: {
          items: _vm.items,
          "item-text": "language",
          "item-value": "language",
          "return-object": "",
          "single-line": "",
          "append-icon": "mdi-chevron-down",
          flat: "",
          solo: "",
          label: "language",
          "aria-label": "language",
        },
        on: { change: _vm.changeLocale },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "div",
                  { staticClass: "curr-selection" },
                  [
                    _c("v-img", {
                      attrs: { src: item.flag, "max-width": "24" },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml-1" }, [
                      _vm._v(_vm._s(item.language)),
                    ]),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "item",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "div",
                  { staticClass: "language-selection" },
                  [
                    _c("v-img", {
                      attrs: { src: item.flag, eager: true, "max-width": "24" },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml-1" }, [
                      _vm._v(_vm._s(item.language)),
                    ]),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.select,
          callback: function ($$v) {
            _vm.select = $$v
          },
          expression: "select",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }