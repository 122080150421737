function _getList(state, data, url) {
  let reset = data.reset ?? false;
  let timeout = 0;

  state.loading = true;

  if (reset === false) {
    state.data.push(
      { id: 'skeleton0', date: '', title: 'placeholder0', description: '', img: null, loaded: false, },
      { id: 'skeleton1', date: '', title: 'placeholder1', description: '', img: null, loaded: false, },
      { id: 'skeleton2', date: '', title: 'placeholder2', description: '', img: null, loaded: false, },
      { id: 'skeleton3', date: '', title: 'placeholder3', description: '', img: null, loaded: false, },
    );

    timeout = 350;
  }

  setTimeout(() => {
    axios.post(url, data)
      .then(function (response) {
        state.data.splice(state.data.length - 4, 4);

        if (response.data.length > 0) { // Has more news to load
          for (let i = 0; i < response.data.length; i++) {
            state.data.push(response.data[i]);
          }
        } else { 
          if (data.lastId == 0) {
            // Check first if has PT news
            data.lastId = 0;
            data.lang = "pt";
            _getList(state, data, url);
          } else {
            // No more news to load
            state.last = true
          }

        }
      }).finally(function () {
        state.loading = false;
      });
  }, timeout);
}

function _getFrontpage(state, url, data = {}) {
  state.loading = true;

  state.frontpage.push(
    { id: 'skeleton0', date: '', title: 'placeholder0', description: '', img: null, loaded: false, },
    { id: 'skeleton1', date: '', title: 'placeholder1', description: '', img: null, loaded: false, },
    { id: 'skeleton2', date: '', title: 'placeholder2', description: '', img: null, loaded: false, },
    { id: 'skeleton3', date: '', title: 'placeholder3', description: '', img: null, loaded: false, },
  );


  setTimeout(() => {
    axios.post(url, data)
      .then(function (response) {
        state.frontpage.splice(state.frontpage.length - 4, 4);

        if (response.data.length > 0) { // Should only return 4 news to frontpage
          for (let i = 0; i < response.data.length; i++) {
            state.frontpage.push(response.data[i]);
          }
        } else {
          data.lang = "pt"
          _getFrontpage(state, url, data)
        }
      }).finally(function () {
        state.loading = false;
      });
  }, 350);
}

function _getSingle(state, data, url) {
  state.loading = true;

  setTimeout(() => {
    axios.post(url, data)
      .then(function (response) {
        if (response.data.length == 0) {
          data.lang = "pt"
          _getSingle(state, data, url)
        } else {
          state.posts.push(response.data[0]);
        }
      }).finally(function () {
        state.loading = false;
      });
  }, 0);
}

function _reset(state) {
  state.data = [];
  state.posts = [];
  state.frontpage = [];
  state.last = false;

  state.data.push(
    { id: 'skeleton0', date: '', title: 'placeholder0', description: '', img: null, loaded: false, },
    { id: 'skeleton1', date: '', title: 'placeholder1', description: '', img: null, loaded: false, },
    { id: 'skeleton2', date: '', title: 'placeholder2', description: '', img: null, loaded: false, },
    { id: 'skeleton3', date: '', title: 'placeholder3', description: '', img: null, loaded: false, },
  );
}

const news = {
  namespaced: true,
  state: {
    last: false,
    loading: false,
    frontpage: [], //Contains frontpage news
    data: [], // Contains previews of news
    posts: [], // Contains single news
  },

  getters: {
    latest: (state) => {
      return state.data.slice(0, 4);
    },
    frontpage: (state) => {
      return state.frontpage.slice(0, 4);
    },
    getPostById: (state) => (id) => {
      return state.posts.find(post => post.id === id);
    }
  },

  actions: {

  },

  mutations: {
    get(state, data) {
      let postData = { 'lastId': state.data[state.data.length - 1]?.id || 0 };
      let url = this._vm.$routes.api.news_list;
      _getList(state, postData, url);
    },
    getFrontpage(state, data) {
      let url = this._vm.$routes.api.news_frontpage;
      _getFrontpage(state, url);
    },
    getSingle(state, data) {
      let postData = { 'id': data };
      let url = this._vm.$routes.api.news_single;
      _getSingle(state, postData, url);
    },
    reset(state, data) {
      let route = data.route;
      let sector = data.sector;
      let postData = { reset: true, lastId: 0 };

      if (route.name === this._vm.$config.navigation[sector].newsSingle.link) data.preview = false;

      _reset(state);
      _getFrontpage(state, this._vm.$routes.api.news_frontpage);
      _getList(state, postData, this._vm.$routes.api.news_list);

      if (route.name === this._vm.$config.navigation[sector].news.link || route.name === this._vm.$config.navigation[sector].newsSingle.link) {
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 0);
      }
    }
  },
}


export default news