var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contacts-page-wrapper", class: _vm.sector },
    [
      _c("hero-inner-page", {
        attrs: {
          src: _vm.$routes.asset(
            "/img/components/hero/privacypolicy/hero-inner-page.jpg"
          ),
          routes: _vm.isIndustria
            ? [_vm.$config.navigation.industria.privacyPolicy]
            : _vm.isAuto
            ? [_vm.$config.navigation.auto.privacyPolicy]
            : [],
          otherlinks: true,
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "py-12 px-4 bg-main" }, [
        _c("div", { staticClass: "limit-width py-12" }, [
          _c("div", { staticClass: "privacy-policy-wrapper" }, [
            _c("div", [
              _c("h1", { staticClass: "h1 mb-2 highlight" }, [
                _vm._v(
                  _vm._s(_vm.$t("pages.privacypolicy.dataprotection.title"))
                ),
              ]),
              _vm._v(" "),
              _c("div", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("pages.privacypolicy.dataprotection.text")
                  ),
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "list-indented" },
                _vm._l(
                  _vm.$t("pages.privacypolicy.dataprotection.values"),
                  function (item, i) {
                    return _c(
                      "p",
                      { key: item, staticClass: "mb-0" },
                      [
                        _c("v-icon", { staticClass: "list-bullet" }, [
                          _vm._v("mdi-circle-medium"),
                        ]),
                        _vm._v(_vm._s(item)),
                        i !=
                        _vm.$t("pages.privacypolicy.dataprotection.values")
                          .length -
                          1
                          ? _c("span", [_vm._v(";")])
                          : _c("span", [_vm._v(".")]),
                      ],
                      1
                    )
                  }
                ),
                0
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("h1", { staticClass: "h1 mb-2 mt-8 highlight" }, [
                _vm._v(
                  _vm._s(_vm.$t("pages.privacypolicy.personaldata.title"))
                ),
              ]),
              _vm._v(" "),
              _c("div", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("pages.privacypolicy.personaldata.text")
                  ),
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("h1", { staticClass: "h1 mb-2 mt-8 highlight" }, [
                _vm._v(_vm._s(_vm.$t("pages.privacypolicy.datasharing.title"))),
              ]),
              _vm._v(" "),
              _c("div", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("pages.privacypolicy.datasharing.text")
                  ),
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("h1", { staticClass: "h1 mb-2 mt-8 highlight" }, [
                _vm._v(_vm._s(_vm.$t("pages.privacypolicy.userrights.title"))),
              ]),
              _vm._v(" "),
              _c("div", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("pages.privacypolicy.userrights.text[0]")
                  ),
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "list-indented" },
                _vm._l(
                  _vm.$t("pages.privacypolicy.userrights.values"),
                  function (item, i) {
                    return _c(
                      "p",
                      { key: item, staticClass: "mb-0" },
                      [
                        _c("v-icon", { staticClass: "list-bullet" }, [
                          _vm._v("mdi-circle-medium"),
                        ]),
                        _vm._v(_vm._s(item)),
                        i !=
                        _vm.$t("pages.privacypolicy.userrights.values").length -
                          1
                          ? _c("span", [_vm._v(";")])
                          : _c("span", [_vm._v(".")]),
                      ],
                      1
                    )
                  }
                ),
                0
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("div", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("pages.privacypolicy.userrights.text[1]")
                  ),
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("h1", { staticClass: "h1 mb-2 mt-8 highlight" }, [
                _vm._v(_vm._s(_vm.$t("pages.privacypolicy.cookies.title"))),
              ]),
              _vm._v(" "),
              _c("div", {
                domProps: {
                  innerHTML: _vm._s(_vm.$t("pages.privacypolicy.cookies.text")),
                },
              }),
              _vm._v(" "),
              _c("h1", { staticClass: "h5 mt-4 highlight" }, [
                _vm._v(
                  _vm._s(_vm.$t("pages.privacypolicy.cookies.uses.title"))
                ),
              ]),
              _vm._v(" "),
              _c("div", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("pages.privacypolicy.cookies.uses.text")
                  ),
                },
              }),
              _vm._v(" "),
              _c("h1", { staticClass: "h5 mt-4 highlight" }, [
                _vm._v(
                  _vm._s(_vm.$t("pages.privacypolicy.cookies.block.title"))
                ),
              ]),
              _vm._v(" "),
              _c("div", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("pages.privacypolicy.cookies.block.text")
                  ),
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("h1", { staticClass: "h1 mb-2 mt-8 highlight" }, [
                _vm._v(_vm._s(_vm.$t("pages.privacypolicy.newsletter.title"))),
              ]),
              _vm._v(" "),
              _c("div", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("pages.privacypolicy.newsletter.text")
                  ),
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("h1", { staticClass: "h1 mb-2 mt-8 highlight" }, [
                _vm._v(
                  _vm._s(_vm.$t("pages.privacypolicy.collecteddata.title"))
                ),
              ]),
              _vm._v(" "),
              _c("div", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("pages.privacypolicy.collecteddata.text")
                  ),
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("h1", { staticClass: "h1 mb-2 mt-8 highlight" }, [
                _vm._v(_vm._s(_vm.$t("pages.privacypolicy.contacts.title"))),
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("pages.privacypolicy.contacts.text[0]")) +
                      "\n\n            "
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "router-link",
                      attrs: {
                        title: _vm.$t(
                          _vm.localePath + ".navigation.links.contacts"
                        ),
                        to: {
                          name: _vm.$config.navigation[_vm.sector].contacts
                            .link,
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.localePath + ".navigation.links.contacts")
                        )
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n\n            " +
                      _vm._s(_vm.$t("pages.privacypolicy.contacts.text[1]")) +
                      "\n          "
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("h1", { staticClass: "h1 mb-2 mt-8 highlight" }, [
                _vm._v(_vm._s(_vm.$t("pages.privacypolicy.revision.title"))),
              ]),
              _vm._v(" "),
              _c("div", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("pages.privacypolicy.revision.text")
                  ),
                },
              }),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }