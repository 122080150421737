import newsModule from './news/news';

const industria = {
  namespaced: true,
  modules: {
    news: newsModule,
  }
}


export default industria