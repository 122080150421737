var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-img",
    {
      staticClass: "hero-inner-page px-4",
      attrs: {
        src: _vm.src,
        height: 200,
        gradient: "90deg, rgba(33, 37, 41, 0.9), rgba(33, 37, 41, 0.9)",
      },
    },
    [
      _c("div", { staticClass: "limit-width hero-inner-wrapper" }, [
        _c(
          "div",
          { staticClass: "breadcrumb-sm" },
          [
            _c("router-link", { attrs: { to: { name: _vm.homeRoute.link } } }, [
              _c("h6", { staticClass: "quick-link regular ml-1" }, [
                _vm._v(
                  _vm._s(_vm.$t(_vm.localePath + ".navigation.links.home"))
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm._l(_vm.routes, function (route, i) {
              return _c(
                "div",
                { key: route.link },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: { color: "rgba(255, 255, 255, 0.85)", size: "16" },
                    },
                    [_vm._v("mdi-chevron-right")]
                  ),
                  _vm._v(" "),
                  i !== _vm.routes.length - 1
                    ? _c(
                        "router-link",
                        { attrs: { to: { name: route.link } } },
                        [
                          _c("h6", { staticClass: "quick-link regular ml-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  _vm.localePath +
                                    ".navigation." +
                                    _vm.linksPath +
                                    "." +
                                    route.localePath
                                )
                              )
                            ),
                          ]),
                        ]
                      )
                    : _c(
                        "h6",
                        { staticClass: "breadcrumb-last regular ml-1" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                _vm.localePath +
                                  ".navigation." +
                                  _vm.linksPath +
                                  "." +
                                  route.localePath
                              )
                            )
                          ),
                        ]
                      ),
                ],
                1
              )
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c("h1", { staticClass: "white--text mt-2" }, [
          _vm._v(
            _vm._s(
              _vm.$t(
                _vm.localePath +
                  ".navigation." +
                  _vm.linksPath +
                  "." +
                  _vm.lastRoute.localePath
              )
            )
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "divider mt-2" }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }