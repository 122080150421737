var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$vuetify.breakpoint.mobile
        ? _c(
            "v-app-bar",
            {
              attrs: {
                app: "",
                color: "#004b81",
                dark: "",
                flat: "",
                dense: "",
                fixed: "",
              },
            },
            [
              _c("v-app-bar-nav-icon", {
                attrs: { "aria-label": "menu" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.drawer = !_vm.drawer
                  },
                },
              }),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c("v-img", {
                staticStyle: { "margin-left": "-24px" },
                attrs: {
                  height: "38",
                  "max-width": "120",
                  contain: "",
                  src: _vm.$routes.asset("img/logo/romafe-white.png"),
                  alt: "Logo Romafe",
                  title: "Romafe",
                },
              }),
              _vm._v(" "),
              _c("v-spacer"),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-navigation-drawer",
        {
          attrs: { app: "", fixed: "" },
          scopedSlots: _vm._u([
            {
              key: "append",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-center" },
                    [_c("language-change")],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "social-media-wrapper pa-4" }, [
                    _c(
                      "div",
                      { staticClass: "social-media mb-4" },
                      _vm._l(_vm.$config.socials, function (ref, i) {
                        var title = ref[0]
                        var icon = ref[1]
                        var link = ref[2]
                        var color = ref[3]
                        return _c(
                          "v-btn",
                          {
                            key: i,
                            staticClass: "mx-1",
                            attrs: {
                              fab: "",
                              dark: "",
                              "x-small": "",
                              color: color,
                              href: link,
                              target: "_blank",
                              rel: "noopener noreferrer",
                              title: title,
                            },
                          },
                          [
                            _c(
                              "v-icon",
                              { attrs: { dark: "", color: "#FFF" } },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(icon) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-center" }, [
                      _vm._v(
                        "© ROMAFE. " +
                          _vm._s(_vm.$t("intro.navdrawer.copyright")) +
                          "."
                      ),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "router-link",
            {
              staticClass: "logo mb-12",
              attrs: { to: { name: _vm.$config.navigation.intro.home.link } },
            },
            [
              _c("v-img", {
                attrs: {
                  src: _vm.$routes.asset("img/logo/romafe-blue.png"),
                  alt: "Logo Romafe",
                  title: "Romafe",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list",
            { staticClass: "list" },
            _vm._l(_vm.links, function (ref, i) {
              var icon = ref[0]
              var link = ref[1]
              var isRouter = ref[2]
              var children = ref[3]
              return _c(
                "div",
                { key: i },
                [
                  isRouter && children.length == 0
                    ? _c(
                        "router-link",
                        {
                          staticClass: "router-link",
                          attrs: { to: { name: link } },
                        },
                        [
                          _c(
                            "v-list-item",
                            { staticClass: "list-item", attrs: { link: "" } },
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v(_vm._s(icon))])],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "intro.navdrawer.links[" + i + "]"
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !isRouter && children.length == 0
                    ? _c(
                        "v-list-item",
                        {
                          staticClass: "list-item",
                          attrs: { href: link, link: "" },
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v(_vm._s(icon))])],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("intro.navdrawer.links[" + i + "]")
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  children.length != 0
                    ? _c(
                        "v-list-group",
                        {
                          staticClass: "list-item",
                          attrs: { value: false, "prepend-icon": icon },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function () {
                                  return [
                                    _c("v-list-item-title", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "intro.navdrawer.links[" + i + "]"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _vm._v(" "),
                          _vm._l(children, function (item) {
                            return _c(
                              "v-list-item",
                              {
                                key: item.title,
                                staticClass: "list-item-child",
                                attrs: { href: item.link, target: "_blank" },
                              },
                              [
                                _c(
                                  "v-list-item-icon",
                                  [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(_vm._s(item.title)),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("v-spacer"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }