<template>
    <div ref="wrap">
        <v-footer class="footer-wrapper" :class="sector" ref="footer" padless absolute>
            <v-row class="footer-row" justify="center" no-gutters>
                <v-col class="pt-6 pb-2 px-4 white--text" :lg="3" :md="4" :sm="6" cols="12">
                    <div class="footer-heading pb-1 mb-1">{{ $t(`${localePath}.footer.navigation`) }}</div>
                    <div class="divider mb-2"></div>

                    <router-link v-for="item in navLinks" :key="item.link"
                        :title="$t(`${localePath}.navigation.links.${item.localePath}`)" class="router-link quick-link"
                        :to="{ name: item.link }">{{ $t(`${localePath}.navigation.links.${item.localePath}`)
                        }}<br></router-link>

                </v-col>

                <v-col class="pt-6 pb-2 px-4 white--text" :lg="3" :md="4" :sm="6" cols="12">
                    <div class="footer-heading pb-1 mb-1">{{ $t(`${localePath}.clientarea`) }}</div>
                    <div class="divider mb-2"></div>

                    <a :href="$config.platforms.weberp.link" class="quick-link" :title="$config.platforms.weberp.title">
                        {{ $t(`${localePath}.footer.shop`) }}
                    </a>

                    <div class="footer-heading pb-1 mb-1 mt-4">{{ $t(`${localePath}.footer.otherlinks`) }}</div>
                    <div class="divider mb-2"></div>

                    <div v-for="item in otherLinks" :key="item.link">
                        <router-link v-if="!item.file"
                            :title="$t(`${localePath}.navigation.otherlinks.${item.localePath}`)"
                            class="router-link quick-link" :to="{ name: item.link }">
                            <span>{{ $t(`${localePath}.navigation.otherlinks.${item.localePath}`) }}</span>
                            <br>
                        </router-link>

                        <a v-else :href="item.file" class="router-link quick-link" rel="noopener noreferrer"
                            target="_blank">{{ $t(`${localePath}.navigation.otherlinks.${item.localePath}`) }}</a>
                    </div>
                    <div>
                        <a href="https://www.livroreclamacoes.pt/Inicio/" class="router-link quick-link"
                            rel="noopener noreferrer" target="_blank">Livro de Reclamações</a>
                    </div>
                </v-col>

                <!--         <v-col
          class="pt-6 pb-2 px-4 white--text"
          :lg="3"
          :md="4"
          :sm="6"
          cols="12"
        >
          <div class="footer-heading pb-1 mb-1">{{ $t(`${localePath}.footer.reservedarea`) }}</div>
          <div class="divider mb-2"></div>

          <div
            v-for="(item) in reservedArea"
            :key="item.title"
          >
            <a
              :href="item.link"
              target="_blank"
              class="quick-link"
              :title="item.title"
            >
              {{ item.title }}
            </a>
          </div>
        </v-col> -->


                <v-col class="pt-6 pb-2 px-4 white--text" :lg="3" :md="12" :sm="6" cols="12">
                    <div class="footer-heading pb-1 mb-1">{{ $t(`${localePath}.footer.newsletter`) }}</div>
                    <div class="divider mb-2"></div>

                    <div>{{ $t(`${localePath}.footer.newslettersubscribe`) }}!</div>
                    <div style="max-width:260px;" class="mt-1">
                        <v-text-field v-model="email.value" :loading="email.loading" class="newsletter"
                            :class="{ 'hasSubscribed': email.hasSubscribed }" ref="email" color="#0063AA"
                            background-color="#FFF" :hide-details="true" outlined dense placeholder="Email"
                            maxlength="100"
                            :append-icon="(email.loading && !email.hasSubscribed) ? null : email.hasSubscribed ? 'mdi-check-bold' : 'mdi-bell-plus-outline'"
                            required name="email" type="email" @click:append="subscribeNewsletter()"
                            @keydown.enter="subscribeNewsletter()">
                            <template v-slot:progress>
                                <div class="newsletter-progress-wrapper">
                                    <v-progress-circular size="24" color="#ec921d" indeterminate
                                        height="4"></v-progress-circular>
                                </div>
                            </template>
                        </v-text-field>
                    </div>

                    <div class="footer-heading pb-1 mb-1 mt-4">{{ $t(`${localePath}.footer.contactus`) }}</div>
                    <div class="divider mb-2"></div>

                    <div class="contact-wrapper">
                        <v-icon size="20" dark>mdi-phone</v-icon>
                        <a :href="'tel:' + $config.contacts.phone" class="quick-link ml-2"
                            :title="$t('misc.contact.phone')">
                            {{ $config.contacts.phone }}
                        </a>
                    </div>

                    <div class="contact-wrapper">
                        <v-icon size="20" dark>mdi-mail</v-icon>
                        <a :href="'mailto:' + $config.contacts.mail" class="quick-link ml-2"
                            :title="$t('misc.contact.email')">
                            {{ $config.contacts.mail }}
                        </a>
                    </div>

                    <div class="footer-heading pb-1 mb-1 mt-4">{{ $t(`${localePath}.footer.followus`) }}</div>
                    <div class="divider mb-2"></div>
                    <div class="socials-wrapper mt-2">
                        <div v-for="([platform, icon, link], i) in $config.socials" :key="platform" :title="platform"
                            :style="i === 0 ? 'margin-left:-6px;' : ''">
                            <a :href="link" rel="noopener noreferrer" target="_blank" class="pa-1 quick-link"
                                :title="platform">
                                <v-icon color="white">
                                    {{ icon }}
                                </v-icon>
                            </a>
                        </div>
                    </div>
                </v-col>
            </v-row>

            <v-col class="pa-4 mt-6 text-center white--text footer-copyright" cols="12">
                {{ new Date().getFullYear() }} © <span style="font-weight:600">ROMAFE</span>. Todos os direitos
                reservados.
            </v-col>
        </v-footer>
    </div>
</template>

<script>
import sector from '@mixins/sector/sector';

export default {
    data: () => ({
        email: {
            value: '',
            loading: false,
            hasSubscribed: false,
        },
    }),
    mixins: [sector],
    methods: {
        subscribeNewsletter() {
            if (this.email.value.length === 0 || this.email.hasSubscribed) return;

            let data = { 'email': this.email.value };
            let self = this;

            this.email.loading = true;

            axios.post(this.$routes.api.newsletter, data)
                .then(function (response) {
                    //DO SOMETHING
                }).finally(function () {
                    setTimeout(() => {
                        self.email.loading = false;
                        self.email.hasSubscribed = true;
                    }, 350);
                });
        }
    },
    computed: {
        reservedArea() {
            let platforms = this.$config.platforms;
            let reservedArea = [];

            for (const key in platforms) {
                let platform = platforms[key];
                if (platform.showIntro === true) reservedArea.push({
                    title: platform.title, link: platform.link
                });
            }

            return reservedArea;
        }
    },
    mounted() {
        let height = this.$refs.footer.$el.clientHeight;
        this.$refs.wrap.style.paddingTop = `${height}px`;
    }
}
</script>

<style lang="scss" scoped>
.footer-wrapper.auto {
    .divider {
        background-color: $palette1 !important;
    }
}

.footer-wrapper.agricultura {
    .divider {
        background-color: $palette2 !important;
    }
}

.socials-wrapper {
    display: flex;

    &>div {
        &:hover {
            opacity: 0.5;
        }
    }
}

.v-btn:not(.v-btn--round).v-size--default {
    padding: 0 8px;
}

.footer-wrapper {
    background-color: $footer;
}

.footer-copyright {
    background-color: $footerDarken;
}

.footer-row {
    max-width: calc(1000px + 48px);
    margin: 0 auto;
}

.footer-heading {
    text-transform: uppercase;
    font-weight: 600;
    max-width: 250px;
}

.contact-wrapper {
    display: flex;
    align-items: center;

    & i.v-icon {
        opacity: 0.85;
    }
}

::v-deep .newsletter {
    & .v-icon.v-icon {
        font-size: 22px;
    }
}

.newsletter-progress-wrapper {
    height: 100%;
    display: flex;

    &>div {
        margin-top: 8px;
    }
}

::v-deep .hasSubscribed button.v-icon {
    color: #17b017 !important;
}
</style>
