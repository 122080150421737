var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "wrap" },
    [
      _c(
        "v-footer",
        {
          ref: "footer",
          staticClass: "footer-wrapper",
          class: _vm.sector,
          attrs: { padless: "", absolute: "" },
        },
        [
          _c(
            "v-row",
            {
              staticClass: "footer-row",
              attrs: { justify: "center", "no-gutters": "" },
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "pt-6 pb-2 px-4 white--text",
                  attrs: { lg: 3, md: 4, sm: 6, cols: "12" },
                },
                [
                  _c("div", { staticClass: "footer-heading pb-1 mb-1" }, [
                    _vm._v(
                      _vm._s(_vm.$t(_vm.localePath + ".footer.navigation"))
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "divider mb-2" }),
                  _vm._v(" "),
                  _vm._l(_vm.navLinks, function (item) {
                    return _c(
                      "router-link",
                      {
                        key: item.link,
                        staticClass: "router-link quick-link",
                        attrs: {
                          title: _vm.$t(
                            _vm.localePath +
                              ".navigation.links." +
                              item.localePath
                          ),
                          to: { name: item.link },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              _vm.localePath +
                                ".navigation.links." +
                                item.localePath
                            )
                          )
                        ),
                        _c("br"),
                      ]
                    )
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  staticClass: "pt-6 pb-2 px-4 white--text",
                  attrs: { lg: 3, md: 4, sm: 6, cols: "12" },
                },
                [
                  _c("div", { staticClass: "footer-heading pb-1 mb-1" }, [
                    _vm._v(_vm._s(_vm.$t(_vm.localePath + ".clientarea"))),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "divider mb-2" }),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "quick-link",
                      attrs: {
                        href: _vm.$config.platforms.weberp.link,
                        title: _vm.$config.platforms.weberp.title,
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t(_vm.localePath + ".footer.shop")) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "footer-heading pb-1 mb-1 mt-4" }, [
                    _vm._v(
                      _vm._s(_vm.$t(_vm.localePath + ".footer.otherlinks"))
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "divider mb-2" }),
                  _vm._v(" "),
                  _vm._l(_vm.otherLinks, function (item) {
                    return _c(
                      "div",
                      { key: item.link },
                      [
                        !item.file
                          ? _c(
                              "router-link",
                              {
                                staticClass: "router-link quick-link",
                                attrs: {
                                  title: _vm.$t(
                                    _vm.localePath +
                                      ".navigation.otherlinks." +
                                      item.localePath
                                  ),
                                  to: { name: item.link },
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        _vm.localePath +
                                          ".navigation.otherlinks." +
                                          item.localePath
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("br"),
                              ]
                            )
                          : _c(
                              "a",
                              {
                                staticClass: "router-link quick-link",
                                attrs: {
                                  href: item.file,
                                  rel: "noopener noreferrer",
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      _vm.localePath +
                                        ".navigation.otherlinks." +
                                        item.localePath
                                    )
                                  )
                                ),
                              ]
                            ),
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "a",
                      {
                        staticClass: "router-link quick-link",
                        attrs: {
                          href: "https://www.livroreclamacoes.pt/Inicio/",
                          rel: "noopener noreferrer",
                          target: "_blank",
                        },
                      },
                      [_vm._v("Livro de Reclamações")]
                    ),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  staticClass: "pt-6 pb-2 px-4 white--text",
                  attrs: { lg: 3, md: 12, sm: 6, cols: "12" },
                },
                [
                  _c("div", { staticClass: "footer-heading pb-1 mb-1" }, [
                    _vm._v(
                      _vm._s(_vm.$t(_vm.localePath + ".footer.newsletter"))
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "divider mb-2" }),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.localePath + ".footer.newslettersubscribe")
                      ) + "!"
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "mt-1",
                      staticStyle: { "max-width": "260px" },
                    },
                    [
                      _c("v-text-field", {
                        ref: "email",
                        staticClass: "newsletter",
                        class: { hasSubscribed: _vm.email.hasSubscribed },
                        attrs: {
                          loading: _vm.email.loading,
                          color: "#0063AA",
                          "background-color": "#FFF",
                          "hide-details": true,
                          outlined: "",
                          dense: "",
                          placeholder: "Email",
                          maxlength: "100",
                          "append-icon":
                            _vm.email.loading && !_vm.email.hasSubscribed
                              ? null
                              : _vm.email.hasSubscribed
                              ? "mdi-check-bold"
                              : "mdi-bell-plus-outline",
                          required: "",
                          name: "email",
                          type: "email",
                        },
                        on: {
                          "click:append": function ($event) {
                            return _vm.subscribeNewsletter()
                          },
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.subscribeNewsletter()
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "progress",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "newsletter-progress-wrapper",
                                  },
                                  [
                                    _c("v-progress-circular", {
                                      attrs: {
                                        size: "24",
                                        color: "#ec921d",
                                        indeterminate: "",
                                        height: "4",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.email.value,
                          callback: function ($$v) {
                            _vm.$set(_vm.email, "value", $$v)
                          },
                          expression: "email.value",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "footer-heading pb-1 mb-1 mt-4" }, [
                    _vm._v(
                      _vm._s(_vm.$t(_vm.localePath + ".footer.contactus"))
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "divider mb-2" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "contact-wrapper" },
                    [
                      _c("v-icon", { attrs: { size: "20", dark: "" } }, [
                        _vm._v("mdi-phone"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "quick-link ml-2",
                          attrs: {
                            href: "tel:" + _vm.$config.contacts.phone,
                            title: _vm.$t("misc.contact.phone"),
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$config.contacts.phone) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "contact-wrapper" },
                    [
                      _c("v-icon", { attrs: { size: "20", dark: "" } }, [
                        _vm._v("mdi-mail"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "quick-link ml-2",
                          attrs: {
                            href: "mailto:" + _vm.$config.contacts.mail,
                            title: _vm.$t("misc.contact.email"),
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$config.contacts.mail) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "footer-heading pb-1 mb-1 mt-4" }, [
                    _vm._v(_vm._s(_vm.$t(_vm.localePath + ".footer.followus"))),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "divider mb-2" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "socials-wrapper mt-2" },
                    _vm._l(_vm.$config.socials, function (ref, i) {
                      var platform = ref[0]
                      var icon = ref[1]
                      var link = ref[2]
                      return _c(
                        "div",
                        {
                          key: platform,
                          style: i === 0 ? "margin-left:-6px;" : "",
                          attrs: { title: platform },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "pa-1 quick-link",
                              attrs: {
                                href: link,
                                rel: "noopener noreferrer",
                                target: "_blank",
                                title: platform,
                              },
                            },
                            [
                              _c("v-icon", { attrs: { color: "white" } }, [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(icon) +
                                    "\n                            "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass: "pa-4 mt-6 text-center white--text footer-copyright",
              attrs: { cols: "12" },
            },
            [
              _vm._v(
                "\n            " + _vm._s(new Date().getFullYear()) + " © "
              ),
              _c("span", { staticStyle: { "font-weight": "600" } }, [
                _vm._v("ROMAFE"),
              ]),
              _vm._v(". Todos os direitos\n            reservados.\n        "),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }