var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-sheet",
        {
          staticClass: "hero-wrapper",
          attrs: { height: "100%", tile: "", color: "#228B22" },
        },
        [
          _c("v-parallax", {
            attrs: {
              src: _vm.$routes.asset("img/agricultura/bg-1.jpg"),
              "lazy-src": _vm.$routes.asset(
                "img/components/about/products-lazy.jpg"
              ),
              alt: "Romafe Agricultura",
              height: _vm.$vuetify.breakpoint.mobile ? 500 : 750,
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "overlay pa-6" }, [
            _c("div", { staticClass: "limit-width overlay-content" }, [
              _c("div", {
                staticClass: "hero-headline white--text",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("agricultura.pages.home.hero.title")
                  ),
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "divider mt-6" }),
              _vm._v(" "),
              _c("div", {
                staticClass: "hero-description mt-6 white--text",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("agricultura.pages.home.hero.subtitle")
                  ),
                },
              }),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "features-wrapper" }, [
        _c(
          "div",
          { staticClass: "limit-width white--text d-flex" },
          _vm._l(
            _vm.$t("agricultura.pages.home.hero.values"),
            function (item, i) {
              return _c(
                "div",
                {
                  key: item.title,
                  staticClass: "d-flex py-8 px-4 feature-single",
                  class: {
                    alt:
                      i ==
                      _vm.$t("agricultura.pages.home.hero.values").length - 1,
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "v-icon",
                        { staticClass: "features-icon", attrs: { size: "34" } },
                        [_vm._v(_vm._s(item.icon))]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "ml-4 d-flex flex-column" }, [
                    _c("h2", { staticClass: "features-title" }, [
                      _c("b", [_vm._v(_vm._s(item.title))]),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "features-description mt-2" }, [
                      _vm._v(_vm._s(item.description)),
                    ]),
                  ]),
                ]
              )
            }
          ),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }