<template>
  <div>
    <hero-inner-page
      :src="$routes.asset('img/auto/hero/products/hero-inner-page.jpg')"
      title="Produtos"
      :routes="[$config.navigation.auto.products]"
    ></hero-inner-page>

    <!-- Select category -->
    <div class="pt-12 pb-6 px-4 bg-main">
      <div class="limit-width py-12 product-select-wrapper">
        <div
          v-for="(item, i) in $t('auto.pages.products.categories')"
          :key="item.title"
          class="pr-8"
        >
          <div
            @click="selected = i"
            class="product-select-title"
            :class="{'selected': i == selected}"
          >
            <h2>{{ item.title }}</h2>
          </div>
        </div>

      </div>
    </div>

    <!-- Category main image and description -->
    <div class="px-4 bg-main">
      <div
        class="limit-width pb-12 d-flex align-center"
        :class="{'has-gap' : category.img}"
      >
        <div v-if="$vuetify.breakpoint.width >= 1020">
          <v-img
            :src="$routes.asset(`img/auto/products/${category.img}`)"
            :alt="category.title"
            :max-width="$vuetify.breakpoint.width < 400 ? 220 : $vuetify.breakpoint.width < 600 ? 300 : 400"
          ></v-img>
        </div>

        <div>
          <div>
            <h1 class="highlight mb-2">{{ category.title }}</h1>
          </div>
          <div v-html="category.description"></div>
        </div>

      </div>
    </div>

    <!-- Sub-categories -->
    <div
      v-for="(item, i) in subCategory"
      :key="item.title"
    >
      <auto-products-page-layout-single
        v-if="item.layout == 0 || item.layout == 1"
        :item="item"
        :isImageLeft="item.layout == 0 ? true : false"
        :class="{'pb-12': i == category.sub.length - 1}"
      ></auto-products-page-layout-single>

      <auto-products-page-layout-multiple
        v-if="item.layout == 2 || item.layout == 3"
        :item="item"
        :itemsPerRow="item.layout"
        :class="{'pb-12': i == category.sub.length - 1}"
      ></auto-products-page-layout-multiple>
    </div>

  </div>
</template>

<script>
export default {
  //Layout:
  // 0 -> Single img right
  // 1 -> Single img left
  // 2 || 3 -> Multiple bg, X items per row
  data: () => ({
    selected: 0,
  }),
  methods: {
    checkRouteParams() {
      let params = this.$route.params;
      if ('productIndex' in params) this.selected = params.productIndex;
    }
  },
  computed: {
    category() {
      return this.$t(`auto.pages.products.categories[${this.selected}]`)
    },
    subCategory() {
      return this.category.sub;
    }
  },
  created() {
    this.checkRouteParams();
  }
}
</script>

<style lang="scss" scoped>
::v-deep h2.highlight {
  color: $palette1;
}

::v-deep .has-gap {
  column-gap: 36px;
}

.product-select-wrapper {
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 600px) {
    flex-wrap: none;
    flex-direction: column;
  }

  .product-select-title {
    text-transform: uppercase;
    cursor: pointer;
    h2 {
      font-size: 18px;
      line-height: 34px;
    }

    &:hover:not(.selected) {
      opacity: 0.6;
    }
    &.selected {
      color: $palette1;
    }
  }
}
</style>