<template>
    <div class="error-wrapper px-4"
        :class="{ 'intro': isIntro, 'industria': isIndustria, 'auto': isAuto, 'agricultura': isAgricultura }">

        <div class="plug-wrapper">
            <v-icon :size="$vuetify.breakpoint.width < 400 ? 240 : $vuetify.breakpoint.width < 700 ? 300 : 500"
                class="plug">mdi-power-plug-off-outline</v-icon>
        </div>

        <div class="text-wrapper text-center">
            <h1>ERRO 404</h1>
            <h2 class="mt-6 h5">Não conseguimos encontrar a página pretendida.</h2>
        </div>

        <router-link class="router-link" :to="{ name: homepage }">
            <v-btn class="main-btn mt-6" dark height="48">
                Voltar ao início
            </v-btn>
        </router-link>

    </div>
</template>

<script>
import sector from '@mixins/sector/sector';

export default {
    mixins: [sector],
    computed: {
        homepage() {
            let links = [this.$config.navigation.intro.home.link, this.$config.navigation.industria.home.link, this.$config.navigation.auto.home.link, this.$config.navigation.agricultura.home.link];
            let homepage;

            if (this.isIntro) homepage = links[0];
            else if (this.isIndustria) homepage = links[1];
            else if (this.isAuto) homepage = links[2];
            else if (this.isAgricultura) homepage = links[3];
            else throw 'Homepage not recognized: is not intro, industria nor auto';

            return homepage;
        }
    },
}
</script>

<style lang="scss" scoped>
.error-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &.intro {
        background: linear-gradient(to right, #005491, #0063aa);
        height: 100vh;
    }

    &.intro,
    &.industria,
    &.agricultura &.auto {
        .plug-wrapper {
            position: absolute;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .v-icon {
                opacity: 0.2;

                @media screen and (max-width: 400px) {
                    opacity: 0.15;
                }
            }
        }
    }

    &.intro {
        .text-wrapper {
            z-index: 1;

            h1 {
                color: #f2f2f2;
                font-size: 100px;
                line-height: 100px;
            }

            h2 {
                color: #dbdbdb;
                font-weight: normal;
            }

            @media screen and (max-width: 700px) {
                h1 {
                    font-size: 60px;
                    line-height: 60px;
                }
            }

            @media screen and (max-width: 400px) {
                h1 {
                    font-size: 32px;
                    line-height: 32px;
                }
            }
        }
    }

    &.industria,
    &.agricultura &.auto {
        min-height: 600px;

        .text-wrapper {
            z-index: 1;

            h1 {
                color: $footer;
                font-size: 100px;
                line-height: 100px;
            }

            h2 {
                color: $footer;
                font-weight: normal;
            }

            @media screen and (max-width: 700px) {
                h1 {
                    font-size: 60px;
                    line-height: 60px;
                }
            }

            @media screen and (max-width: 400px) {
                h1 {
                    font-size: 32px;
                    line-height: 32px;
                }
            }
        }

        .plug-wrapper {
            .v-icon {
                opacity: 0.1;
            }
        }
    }
}
</style>
