<template>
  <v-app-bar
    dark
    color="#004b81"
    v-if="!$vuetify.breakpoint.mobile"
  >
    {{ title }}
  </v-app-bar>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    }
  }
}
</script>

<style>
</style>