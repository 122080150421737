var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("industria-home-carousel"),
      _vm._v(" "),
      _c("industria-home-solutions"),
      _vm._v(" "),
      _c("admin-quote", { attrs: { name: "jvaz" } }),
      _vm._v(" "),
      _c("industria-home-about"),
      _vm._v(" "),
      _c("home-brand-showcase"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }