var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contacts-page-wrapper", class: _vm.sector },
    [
      _c("hero-inner-page", {
        attrs: {
          src: _vm.$routes.asset(
            "/img/components/hero/contacts/hero-inner-page.jpg"
          ),
          routes: _vm.isIndustria
            ? [_vm.$config.navigation.industria.contacts]
            : _vm.isAuto
            ? [_vm.$config.navigation.auto.contacts]
            : [],
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "py-12 px-4" }, [
        _c(
          "div",
          { staticClass: "contacts-wrapper limit-width py-12" },
          _vm._l(_vm.$config.branches, function (item, i) {
            return _c("v-hover", {
              key: i,
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var hover = ref.hover
                      return [
                        _c(
                          "v-card",
                          {
                            staticClass: "mb-6 contact-card",
                            class: { even: i % 2 === 0, odd: i % 2 !== 0 },
                            attrs: { elevation: hover ? 4 : 2, outlined: "" },
                          },
                          [
                            _vm.$vuetify.breakpoint.width > 850
                              ? _c("div", { staticClass: "img-wrapper" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "open-map-btn",
                                      attrs: {
                                        href: item.link,
                                        target: "_blank",
                                        rel: "noopener noreferrer",
                                        title: "Romafe - " + item.local,
                                      },
                                    },
                                    [
                                      _c("v-hover", {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var hover = ref.hover
                                                return [
                                                  _c(
                                                    "v-img",
                                                    {
                                                      attrs: {
                                                        alt:
                                                          item.local +
                                                          " - " +
                                                          item.tipo,
                                                        src: item.img,
                                                        height: _vm.imgSize,
                                                        width: _vm.imgSize,
                                                        "max-height":
                                                          _vm.imgSize,
                                                        "max-width":
                                                          _vm.imgSize,
                                                        eager:
                                                          _vm.$vuetify
                                                            .breakpoint.mobile,
                                                        gradient:
                                                          "90deg, rgba(33, 37, 41, 0.2), rgba(33, 37, 41, 0.1)",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-scale-transition",
                                                        {
                                                          attrs: {
                                                            origin:
                                                              "center center",
                                                          },
                                                        },
                                                        [
                                                          hover
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "v-card--reveal white--text hover-overlay",
                                                                  staticStyle: {
                                                                    height:
                                                                      "100%",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        color:
                                                                          "#f2f2f2",
                                                                        size: "48",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-map-marker"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "v-container",
                              { staticClass: "pa-4", attrs: { fluid: "" } },
                              [
                                _c("h2", [_vm._v(_vm._s(item.local))]),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass: "divider my-2",
                                  staticStyle: { "max-width": "50px" },
                                }),
                                _vm._v(" "),
                                _c("h3", { staticClass: "regular h6" }, [
                                  _vm._v(_vm._s(_vm.type(item.tipo))),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "my-2 contact-card-info" },
                                  [
                                    _c("div", [_vm._v(_vm._s(item.morada))]),
                                    _vm._v(" "),
                                    _c("div", [_vm._v(_vm._s(item.morada2))]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v("Tel: "),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "quick-link blue--text",
                                          attrs: {
                                            href: "tel:" + item.telefone,
                                          },
                                        },
                                        [_vm._v(_vm._s(item.telefone))]
                                      ),
                                      _vm._v(
                                        " (Chamada para a rede fixa nacional)\n                            "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v("Fax: " + _vm._s(item.fax)),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "font-style": "italic" },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "quick-link blue--text",
                                            attrs: {
                                              href: "mailto:" + item.mail,
                                            },
                                          },
                                          [_vm._v(_vm._s(item.mail))]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "open-map-btn",
                                      attrs: {
                                        href: item.link,
                                        target: "_blank",
                                        rel: "noopener noreferrer",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "main-btn",
                                          attrs: {
                                            dark: "",
                                            title: _vm.$t("contacts.map.open"),
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.$t("contacts.map.open")
                                              ) +
                                              "\n                                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }