<template>
  <div id="partners" class="showcase-wrapper py-12 px-4">
    <div class="limit-width text-center py-12">

      <h2 class="bolder">{{ $t('misc.brandshowcase.title') }}</h2>
      <div class="divider mt-4"></div>

      <div
        class="logo-wrapper mt-12"
        :class="sector"
      >
        <a
          class="single-logo py-3 px-5 elevation-16"
          v-for="([title, link], index) in links[sector]"
          :key="index"
          :href="link"
          rel="noopener noreferrer"
          target="_blank"
          :title="title"
        >

          <v-img
            class="img"
            :class="{'mobile': $vuetify.breakpoint.mobile}"
            :src="imgFolder + (index + 1) + '.jpg'"
            :alt="'Parceiro ROMAFE - ' + title"
            width="110"
            height="80"
            :eager="$vuetify.breakpoint.mobile"
            contain
          >
          </v-img>

          <div class="logo-overlay">
            <div class="py-1 px-2">{{ title }}</div>
          </div>
        </a>
      </div>

    </div>
  </div>
</template>

<script>
import sector from '@mixins/sector/sector';

export default {
  data: () => ({
    links: {
      auto: [
        ['SKF', 'https://www.skf.com',],
        ['MANNOL', 'https://www.mannol.de/',],
        ['TRW', 'https://www.trwaftermarket.com',],
        ['VALEO', 'https://www.valeo.com',],
        ['UFI', 'https://www.ufifilters.com',],
        ['CORTECO', 'https://www.corteco.com',],
        ['NTN SNR', 'https://www.ntn-snr.com',],
        ['TAB', 'https://www.tabspain.com',],
        ['HELLA', 'https://www.hella.com',],
        ['IADA', 'https://www.iada.es/en/home/',],
        ['NGK NTK', 'https://www.ngkntk.com/',],
      ],
      industria: [
        ['SKF', 'https://www.skf.com',],
        ['NTN SNR', 'https://www.ntn-snr.com',],
        ['CRAFT', 'https://www.craft-bearings.com/'],
        ['MANNOL', 'https://www.mannol.de/',],
        ['THK', 'https://www.thk.com/?q=br',],
        ['CORTECO', 'https://www.corteco.com',],
        ['UFI', 'https://www.ufifilters.com',],
        ['RINGSPANN', 'https://www.ringspann.com',],
        ['ESAB', 'https://www.pt.esab.net',],
        ['DORMER PRAMET', 'https://www.dormerpramet.com',],
      ]
    }
  }),
  mixins: [sector],
  computed: {
    imgFolder() {
      return this.$routes.asset(`img/${this.sector}/partnerships/partner-`);
    },
  }
}
</script>

<style lang="scss" scoped>
.single-logo {
  background-color: #fff;
  border-radius: 8px;
  position: relative;
  &:hover {
    .img {
      filter: grayscale(0%);
    }
    .logo-overlay > div {
      opacity: 0.5;
    }
  }
}

.img {
  filter: grayscale(100%);
  transition: 100ms ease-in-out;
  &.mobile {
    filter: grayscale(0%);
  }
}

::v-deep .logo-overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  & > div {
    transition: 100ms ease-in-out;
    opacity: 0;
    font-family: $titleFont;
    text-decoration: none;
    font-size: 12px;
    color: #333;
    font-weight: 600;
  }
}

.showcase-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.logo-wrapper {
  display: grid;
  //grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 18px;

  &.auto {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media screen and (max-width: 1100px) {
    //grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    &.auto {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  @media screen and (max-width: 850px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr 1fr 1fr;
    &.auto {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  @media screen and (max-width: 500px) {
    gap: 8px;
    grid-template-columns: 1fr 1fr;
    &.auto {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media screen and (max-width: 320px) {
    grid-template-columns: 1fr;
    &.auto {
      grid-template-columns: 1fr;
    }
  }
}
</style>