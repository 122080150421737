<template>

  <v-img
    :src="hasBackground ? $routes.asset(`img/auto/products/${item.img}`) : null"
    :gradient="hasBackground ? '90deg, rgba(33, 37, 41, 0.95), rgba(33, 37, 41, 0.95)' : null"
    aspect-ratio="hotfix"
    :alt="item.title"
  >
    <div class="px-4">
      <div class="limit-width py-12 d-flex align-center">
        <div style="width:100%" :class="{'white--text': hasBackground}">

          <div class="pt-6" v-if="item.title || item.description">
            <div v-if="item.title">
              <h2 class="highlight mb-2">{{ item.title }}</h2>
            </div>
            <div
              v-if="item.description"
              v-html="item.description"
            ></div>
          </div>

          <div
            class="d-flex sub-wrapper pb-12"
            :class="`items-per-row-${itemsPerRow}`"
          >

            <div
              v-for="sub in item.sub"
              :key="sub.title"
              :class="$vuetify.breakpoint.width >= 1020 ? 'mt-12' : 'mt-6'"
            >

              <div v-if="$vuetify.breakpoint.width >= 1020">
                <v-img
                  :src="$routes.asset(`img/auto/products/${sub.img}`)"
                  :alt="sub.title"
                  :height="200"
                  :max-width="itemsPerRow == 2 ? 480 : 306"
                ></v-img>
              </div>

              <div class="mt-4">
                <h2 class="highlight my-2 sub-title">{{ sub.title }}</h2>
              </div>

              <div>
                <div
                  v-html="sub.description"
                  class="sub-description"
                ></div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </v-img>

</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    itemsPerRow: {
      type: Number,
      required: true
    }
  },
  computed: {
    hasBackground() {
      return this.item.img;
    }
  }
}
</script>

<style lang="scss" scoped>
.sub-title {
  font-size: 18px;
}

.sub-wrapper {
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  &.items-per-row-2 {
    & > div {
      max-width: 480px;
    }
  }
  &.items-per-row-3 {
    & > div {
      max-width: 306px;
    }
  }
}

.sub-description {
  font-size: 14px;
}
</style>