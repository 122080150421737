//! Vue
import Vue from "vue";
//! Vue Router
import VueRouter from "vue-router";
Vue.use(VueRouter);

//! Config
import config from "../config/config";

//! ERRORS
import NotFoundPage from "../js/views/error/NotFoundPage";

//! INTRO
import IntroApp from "../js/views/intro/IntroApp";
import IntroAboutPage from "../js/views/intro/about/IntroAboutPage";
import IntroHomePage from "../js/views/intro/home/IntroHomePage";
import IntroContactsPage from "../js/views/intro/contacts/IntroContactsPage";
import NewIntroContactsPage from "../js/views/intro/contacts/NewIntroContactsPage";

//! STOCKS
/* import ConsultaStocksPage from '../js/views/stocks/ConsultaStocksPage' */

//! INDUSTRIA
import IndustriaHomePage from "../js/views/industria/home/IndustriaHomePage";
import IndustriaServicesPage from "../js/views/industria/services/IndustriaServicesPage";

//! AUTO
import AutoHomePage from "../js/views/auto/home/AutoHomePage";
import AutoProductsPage from "../js/views/auto/products/AutoProductsPage";

//! AGRICULTURA
import AgriculturaHomePage from "../js/views/agricultura/home/AgriculturaHomePage";

//! GLOBAL
import SectorApp from "../js/views/SectorApp";
import AboutPage from "../js/components/global/pages/about/AboutPage";
import ContactsPage from "../js/components/global/pages/contacts/ContactsPage";
import PrivacyPolicyPage from "../js/components/global/pages/privacypolicy/PrivacyPolicyPage";

//TITLES
function setTitle(title, type) {
    let preTitle =
        type === 0
            ? "ROMAFE Indústria"
            : type === 1
            ? "ROMAFE Auto"
            : type === 2
            ? "ROMAFE Agricultura"
            : "ROMAFE";
    let msg = preTitle;

    if (title.length != 0) msg += " - " + title;
    return msg;
}

//ROUTER
const router = new VueRouter({
    mode: "history",
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0, behavior: "smooth" };
    },
    routes: [
        //TODO: replace '/intro' with ''
        //!INDUSTRIA
        {
            path: "/industria",
            component: SectorApp,
            children: [
                {
                    path: "/industria",
                    name: config.navigation.industria.home.link,
                    component: IndustriaHomePage,
                    meta: {
                        title: setTitle("", 0),
                        metaTags: [
                            {
                                name: "description",
                                content:
                                    "A referência no mercado no setor da manutenção industrial. Dispomos de produtos e serviços capazes de satisfazer as exigências da Indústria.",
                            },
                            {
                                property: "og:description",
                                content:
                                    "A referência no mercado no setor da manutenção industrial. Dispomos de produtos e serviços capazes de satisfazer as exigências da Indústria.",
                            },
                        ],
                    },
                },
                {
                    path: "/industria/sobre",
                    name: config.navigation.industria.about.link,
                    component: AboutPage,
                    meta: {
                        title: setTitle("Sobre", 0),
                        metaTags: [
                            {
                                name: "description",
                                content:
                                    "Fundada em 1945, contamos com uma vasta experiência no ramo da manutenção industrial. Primamos por altos critérios de rigor, qualidade e inovação.",
                            },
                            {
                                property: "og:description",
                                content:
                                    "Fundada em 1945, contamos com uma vasta experiência no ramo da manutenção industrial. Primamos por altos critérios de rigor, qualidade e inovação.",
                            },
                        ],
                    },
                },
                {
                    path: "/industria/solucoes",
                    name: config.navigation.industria.services.link,
                    component: IndustriaServicesPage,
                    meta: {
                        title: setTitle("Soluções", 0),
                        metaTags: [
                            {
                                name: "description",
                                content:
                                    "Fornecemos soluções no setor da Indústria à medida das necessidades de cada cliente. Fazemos o acompanhamento, medição, análise e ajuste dos seus equipamentos.",
                            },
                            {
                                property: "og:description",
                                content:
                                    "Fornecemos soluções no setor da Indústria à medida das necessidades de cada cliente. Fazemos o acompanhamento, medição, análise e ajuste dos seus equipamentos.",
                            },
                        ],
                    },
                },
                {
                    path: "/industria/contactos",
                    name: config.navigation.industria.contacts.link,
                    component: ContactsPage,
                    meta: {
                        title: setTitle("Contactos", 0),
                        metaTags: [
                            {
                                name: "description",
                                content:
                                    "Contacte-nos para saber mais sobre as nossas soluções. Saiba como contactar a nossa sede no Porto ou as nossas filiais no Minho, Trás-os-Montes, Lisboa e Beiras.",
                            },
                            {
                                property: "og:description",
                                content:
                                    "Contacte-nos para saber mais sobre as nossas soluções. Saiba como contactar a nossa sede no Porto ou as nossas filiais no Minho, Trás-os-Montes, Lisboa e Beiras.",
                            },
                        ],
                    },
                },
                {
                    path: "industria/politica-privacidade",
                    name: config.navigation.industria.privacyPolicy.link,
                    component: PrivacyPolicyPage,
                    meta: {
                        title: setTitle("Política de privacidade", 0),
                        metaTags: [
                            {
                                name: "description",
                                content: "Política de privacidade da Romafe.",
                            },
                            {
                                property: "og:description",
                                content: "Política de privacidade da Romafe.",
                            },
                        ],
                    },
                },
                {
                    path: "/industria/*",
                    name: "not-found-industria",
                    component: NotFoundPage,
                    meta: {
                        title: setTitle("Página não encontrada"),
                    },
                },
            ],
        },
        //!AUTO
        {
            path: "/auto",
            component: SectorApp,
            children: [
                {
                    path: "/auto",
                    name: config.navigation.auto.home.link,
                    component: AutoHomePage,
                    meta: {
                        title: setTitle("", 1),
                        metaTags: [
                            {
                                name: "description",
                                content:
                                    "Uma referência no mercado a fornecer soluções de produtos e serviços premium para o setor automóvel.",
                            },
                            {
                                property: "og:description",
                                content:
                                    "Uma referência no mercado a fornecer soluções de produtos e serviços premium para o setor automóvel.",
                            },
                        ],
                    },
                },
                {
                    path: "/auto/sobre",
                    name: config.navigation.auto.about.link,
                    component: AboutPage,
                    meta: {
                        title: setTitle("Sobre", 1),
                        metaTags: [
                            {
                                name: "description",
                                content:
                                    "Fundada em 1945, contamos com uma vasta experiência no ramo do setor automóvel. Primamos por altos critérios de rigor, qualidade e inovação.",
                            },
                            {
                                property: "og:description",
                                content:
                                    "Fundada em 1945, contamos com uma vasta experiência no ramo do setor automóvel. Primamos por altos critérios de rigor, qualidade e inovação.",
                            },
                        ],
                    },
                },
                {
                    path: "/auto/produtos",
                    name: config.navigation.auto.products.link,
                    component: AutoProductsPage,
                    meta: {
                        title: setTitle("Produtos", 1),
                        metaTags: [
                            {
                                name: "description",
                                content:
                                    "Fornecemos produtos no setor automóvel, adequados às necessidades de cada cliente.",
                            },
                            {
                                property: "og:description",
                                content:
                                    "Fornecemos produtos no setor automóvel, adequados às necessidades de cada cliente.",
                            },
                        ],
                    },
                },
                {
                    path: "/auto/contactos",
                    name: config.navigation.auto.contacts.link,
                    component: ContactsPage,
                    meta: {
                        title: setTitle("Contactos", 1),
                        metaTags: [
                            {
                                name: "description",
                                content:
                                    "Contacte-nos para saber mais sobre as nossas soluções. Saiba como contactar a nossa sede no Porto ou as nossas filiais no Minho, Trás-os-Montes, Lisboa e Beiras.",
                            },
                            {
                                property: "og:description",
                                content:
                                    "Contacte-nos para saber mais sobre as nossas soluções. Saiba como contactar a nossa sede no Porto ou as nossas filiais no Minho, Trás-os-Montes, Lisboa e Beiras.",
                            },
                        ],
                    },
                },
                {
                    path: "/auto/politica-privacidade",
                    name: config.navigation.auto.privacyPolicy.link,
                    component: PrivacyPolicyPage,
                    meta: {
                        title: setTitle("Política de privacidade", 1),
                        metaTags: [
                            {
                                name: "description",
                                content: "Política de privacidade da Romafe.",
                            },
                            {
                                property: "og:description",
                                content: "Política de privacidade da Romafe.",
                            },
                        ],
                    },
                },
                {
                    path: "/auto/*",
                    name: "not-found-auto",
                    component: NotFoundPage,
                    meta: {
                        title: setTitle("Página não encontrada"),
                    },
                },
            ],
        },
        //!AGRICULTURA
        {
            path: "/agricultura",
            component: SectorApp,
            children: [
                {
                    path: "/agricultura",
                    name: config.navigation.agricultura.home.link,
                    component: AgriculturaHomePage,
                    meta: {
                        title: setTitle("", 2),
                        metaTags: [
                            {
                                name: "description",
                                content:
                                    "Uma referência no mercado a fornecer soluções de produtos e serviços premium para o setor agricola.",
                            },
                            {
                                property: "og:description",
                                content:
                                    "Uma referência no mercado a fornecer soluções de produtos e serviços premium para o setor agricola.",
                            },
                        ],
                    },
                },
                {
                    path: "/agricultura/*",
                    name: "not-found-agricultura",
                    component: NotFoundPage,
                    meta: {
                        title: setTitle("Página não encontrada"),
                    },
                },
            ],
        },
        //!INTRO
        {
            path: "",
            component: IntroApp,
            children: [
                {
                    path: "",
                    name: config.navigation.intro.home.link,
                    component: IntroHomePage,
                    meta: {
                        title: setTitle("Rolling Your Way"),
                        metaTags: [
                            {
                                name: "description",
                                content:
                                    "Rolling Your Way - Soluções de produtos e serviços de Engenharia à medida de cada cliente.",
                            },
                            {
                                property: "og:description",
                                content:
                                    "Rolling Your Way - Soluções de produtos e serviços de Engenharia à medida de cada cliente.",
                            },
                        ],
                    },
                },
                {
                    path: "/sobre",
                    name: config.navigation.intro.about.link,
                    component: IntroAboutPage,
                    meta: {
                        title: setTitle("Sobre"),
                        metaTags: [
                            {
                                name: "description",
                                content:
                                    "Há 77 anos a ser uma referência no mercado e um parceiro impulsionador dos negócios dos nossos clientes.",
                            },
                            {
                                property: "og:description",
                                content:
                                    "Há 77 anos a ser uma referência no mercado e um parceiro impulsionador dos negócios dos nossos clientes.",
                            },
                        ],
                    },
                },
                {
                    path: "/contactos",
                    name: config.navigation.intro.contacts.link,

                    //! BEFORE: IntroContactsPage
                    //RICARDO MONTEIRO
                    component: NewIntroContactsPage,
                    meta: {
                        title: setTitle("Contactos"),
                        metaTags: [
                            {
                                name: "description",
                                content:
                                    "Saiba como contactar a nossa sede no Porto ou as nossas filiais no Minho, Trás-os-Montes, Lisboa e Beiras.",
                            },
                            {
                                property: "og:description",
                                content:
                                    "Saiba como contactar a nossa sede no Porto ou as nossas filiais no Minho, Trás-os-Montes, Lisboa e Beiras.",
                            },
                        ],
                    },
                },
                /*                 {
                    path: "/consulta",
                    name: config.navigation.intro.stocks.link,

                    component: ConsultaStocksPage,
                    meta: {
                        title: setTitle("Consulta"),
                        metaTags: [
                            {
                                name: "description",
                                content:
                                    "Aqui pode consultar os nossos stocks.",
                            },
                            {
                                property: "og:description",
                                content: "Aqui pode consultar os nossos stock.",
                            },
                        ],
                    },
                }, */
                {
                    path: "/*",
                    name: "not-found-intro",
                    component: NotFoundPage,
                    meta: {
                        title: setTitle("Página não encontrada"),
                    },
                },
            ],
        },
    ],
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title || "ROMAFE";
    next();
});

export default router;
