var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "290" },
      on: { input: _vm.closeDialog },
      model: {
        value: _vm.dialog.value,
        callback: function ($$v) {
          _vm.$set(_vm.dialog, "value", $$v)
        },
        expression: "dialog.value",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "headline" }, [
            _c("div", { domProps: { innerHTML: _vm._s(_vm.dialog.title) } }),
          ]),
          _vm._v(" "),
          _c("v-card-text", [
            _c("div", { domProps: { innerHTML: _vm._s(_vm.dialog.content) } }),
          ]),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { color: "#0063AA", dark: "" },
                  on: { click: _vm.closeDialog },
                },
                [_vm._v("\n        OK\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }