<template>
  <div
    class="about-page-wrapper"
    :class="sector"
  >
    <hero-inner-page
      :src="$routes.asset(`img/${sector}/hero/about/hero-inner-page.jpg`)"
      :routes="[$config.navigation[sector].about]"
    ></hero-inner-page>

    <div class="py-12 px-4 bg-main">
      <div class="limit-width py-12">

        <div class="about-1-wrapper">
          <div>
            <h1 class="mb-6 highlight">{{ $t(`${localePath}.pages.about.journey.title`) }}</h1>

            <span>{{ $t(`${localePath}.pages.about.journey.text[0]`) }}.</span><br><br>
            <span>{{ $t(`${localePath}.pages.about.journey.text[1]`) }}.</span><br><br>
            <span>{{ $t(`${localePath}.pages.about.journey.text[2]`) }}.</span>
          </div>

          <div :class="{ 'mt-6': $vuetify.breakpoint.width < 900 }">
            <v-img
              :src="$routes.asset('img/components/about/bearing.jpg')"
              alt="Rolamentos Romafe"
              :max-width="$vuetify.breakpoint.width < 400 ? 220 : $vuetify.breakpoint.width < 600 ? 300 : 400"
            ></v-img>
          </div>
        </div>

        <hr class="mt-12">

        <div class="about-2-wrapper mt-12">
          <div
            v-for="item in $t(`pages.about.journey.values`)"
            :key="item.title"
          >
            <h2 class="mb-2 text-uppercase h5"><b>{{ item.title }}</b></h2>
            <span class="highlight-description">{{ item.description }}.</span>
          </div>
        </div>

      </div>
    </div>

    <v-sheet
      height="100%"
      tile
      color="#333"
      class="about-3-wrapper"
    >
      <v-parallax
        :src="$routes.asset(isIndustria ? 'img/components/about/services.jpg' : 'img/components/about/products.jpg')"
        :lazy-src="$routes.asset(isIndustria ? 'img/components/about/services-lazy.jpg' : 'img/components/about/products-lazy.jpg')"
        :alt="isIndustria ? 'Soluções e serviços Romafe' : 'Gamas de produtos Romafe'"
        :height="isIndustria ? 350 : 300"
      >
      </v-parallax>

      <div class="overlay px-4">
        <div class="limit-width overlay-content white--text py-8">
          <h1 class="highlight white--text">{{ $t(`${localePath}.pages.about.sectorcalltoaction.title[0]`) }}<br><span class="highlight">{{ $t(`${localePath}.pages.about.sectorcalltoaction.title[1]`) }}</span></h1>
          <div class="mt-6">{{ $t(`${localePath}.pages.about.sectorcalltoaction.text[0]`) }}.<br>
            {{ $t(`${localePath}.pages.about.sectorcalltoaction.text[1]`) }}.<br></div>
        </div>
      </div>
    </v-sheet>

    <div class="pa-4 call-to-action about-4-wrapper">
      <div class="limit-width">
        <div>
          <h2 class="h6">{{ $t(`${localePath}.pages.about.sectorcalltoaction.calltoaction`) }}</h2>
        </div>
        <div class="ml-4">
          <router-link
            class="router-link"
            :to="{ name: isIndustria ? $config.navigation.industria.services.link : $config.navigation.auto.products.link }"
          >
            <v-btn
              class="main-btn hover-alt"
              dark
              :title="isIndustria ? $t(`${localePath}.navigation.links.${$config.navigation.industria.services.localePath}`) : $t(`${localePath}.navigation.links.${$config.navigation.auto.products.localePath}`)"
            >
              {{ isIndustria ? $t(`${localePath}.navigation.links.${$config.navigation.industria.services.localePath}`) : $t(`${localePath}.navigation.links.${$config.navigation.auto.products.localePath}`) }}
            </v-btn>
          </router-link>
        </div>
      </div>
    </div>

    <div class="py-12 px-4 bg-alt">
      <div class="limit-width py-12">

        <div class="about-5-wrapper">
          <div>
            <h1 class="mb-8 highlight alt">{{ $t(`pages.about.values.title`) }}</h1>

            <v-card>
              <v-tabs
                v-model="values.tab"
                background-color="#FFF"
                :icons-and-text="$vuetify.breakpoint.width > 600"
                :color="isIndustria ? '#ec921d' : '#cf372d'"
                fixed-tabs
                show-arrows
              >
                <v-tabs-slider></v-tabs-slider>

                <v-tab
                  :href="'#tab-' + (i + 1)"
                  :title="item.title"
                  v-for="(item, i) in $t(`pages.about.values.values`)"
                  :key="item.title + i"
                >
                  <b v-if="$vuetify.breakpoint.width > 600">{{ item.title }}</b>
                  <v-icon class="mb-1">{{ values.icons[i] }}</v-icon>
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="values.tab">
                <v-tab-item
                  v-for="(item, i) in $t(`pages.about.values.values`)"
                  :key="item.title + i"
                  :value="'tab-' + (i + 1)"
                  :eager="true"
                >
                  <v-card flat>
                    <div class="card-content pa-4">
                      <div class="px-4">
                        <v-img
                          :src="$routes.asset(`img/components/about/value-${(i + 1)}.jpg`)"
                          :alt="'Romafe - ' + item.title"
                          :max-width="$vuetify.breakpoint.width < 400 ? 200 : $vuetify.breakpoint.width < 500 ? 250 : 400"
                          :eager="$vuetify.breakpoint.mobile"
                        >
                        </v-img>
                      </div>
                      <div class="px-4">
                        <h2 class="mb-2">{{ item.subtitle }}</h2>
                        <p v-html="item.text + '.'"></p>
                      </div>
                    </div>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </div>
        </div>

      </div>
    </div>

    <v-sheet
      height="100%"
      tile
      color="#333"
      class="about-6-wrapper"
    >
      <v-parallax
        :src="$routes.asset('img/components/about/commitment.jpg')"
        :lazy-src="$routes.asset('img/components/about/commitment-lazy.jpg')"
        alt="Compromisso qualidade Romafe"
        :height="$vuetify.breakpoint.width < 450 ? 600 : 500"
      >
      </v-parallax>

      <div class="overlay px-4">
        <div class="limit-width overlay-content white--text py-8">
          <h1 class="highlight white--text">{{ $t(`pages.about.commitment.title[0]`) }} <span class="highlight">{{ $t(`pages.about.commitment.title[1]`) }}</span></h1>

          <div class="mt-6">
            {{ $t(`pages.about.commitment.text[0]`) }}.<br><br>
            {{ $t(`pages.about.commitment.text[1]`) }}.
          </div>

          <div class="qualidade-wrapper">
            <div class="mr-4 mb-4">
              <a
                :href="$routes.asset('pdf/CERTIFICADO_TUV_pt.pdf')"
                class="quick-link blue--text"
                rel="noopener noreferrer"
                target="_blank"
              >{{ $t(`pages.about.commitment.certificate`) }} - PT</a><br>
              <a
                :href="$routes.asset('pdf/CERTIFICADO_TUV_en.pdf')"
                class="quick-link blue--text"
                rel="noopener noreferrer"
                target="_blank"
              >{{ $t(`pages.about.commitment.certificate`) }} - EN</a><br>
              <a
                :href="$routes.asset('pdf/CERTIFICADO_TUV_pt.pdf')"
                class="quick-link blue--text"
                rel="noopener noreferrer"
                target="_blank"
              >{{ $t('pages.about.commitment.policy') }}</a>
            </div>

            <div class="mb-4">
              <v-img
                width="200"
                eager
                :src="$routes.asset('img/certificates/quality-certificate.jpg')"
                alt="Logo Qualidade Romafe - ISO 9001"
              ></v-img>
            </div>
          </div>
        </div>
      </div>

    </v-sheet>

    <div class="py-12 px-4 bg-main">
      <div class="limit-width py-12">

        <div class="about-7-wrapper">
          <h1 class="mb-6 highlight">{{ $t(`pages.about.policies.title`) }}</h1>

          <div
            v-for="(item, i) in $t('pages.about.policies.values')"
            :key="item.title"
          >
            <h2
              class="mb-4 h4"
              :class="{'mt-6' : i != 0}"
            >{{ item.title }}</h2>

            <div class="list-indented">
              <p
                v-for="(policy, i) in item.text"
                :key="i"
                class="mb-0"
              >
                <v-icon class="list-bullet">mdi-circle-medium</v-icon>{{ policy }}<span v-if="i != item.text.length - 1">;</span><span v-else>.</span>
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="pa-4 call-to-action alt about-4-wrapper">
      <div class="limit-width">
        <div>
          <h2 class="h6">{{ $t('pages.about.infocalltoaction') }}</h2>
        </div>
        <div class="ml-4">
          <router-link
            class="router-link"
            :to="{ name: $config.navigation[sector].contacts.link }"
          >
            <v-btn
              class="main-btn alt"
              dark
              :title="$t('misc.contact.us')"
            >
              {{ $t('misc.contact.us') }}
            </v-btn>
          </router-link>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import sector from '@mixins/sector/sector';

export default {
  data: () => ({
    values: {
      tab: null,
      icons: ['mdi-fingerprint', 'mdi-handshake', 'mdi-lightbulb', 'mdi-account-group', 'mdi-hand-heart']
    },
  }),
  mixins: [sector],
}
</script>

<style lang="scss" scoped>
.about-page-wrapper {
  &.industria {
    ::v-deep .value-highlight {
      color: $palette0;
    }
  }
  &.auto {
    ::v-deep .value-highlight {
      color: $palette1;
    }
  }
}

.about-1-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  & > div:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
  }
}

.about-2-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;

  .highlight-description {
    font-size: 14px;
  }

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 750px) {
    grid-template-columns: 1fr;
  }
}

.about-3-wrapper,
.about-6-wrapper {
  position: relative;

  .overlay {
    background: linear-gradient(
      90deg,
      rgba(33, 37, 41, 0.9),
      rgba(33, 37, 41, 0.9)
    );
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .overlay-content {
    width: 100%;
    height: 100%;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    @media screen and (max-width: 500px) {
      & > div:nth-child(2) {
        font-size: 14px;
      }
    }
  }
}

.about-4-wrapper {
  h6 {
    @media screen and (max-width: 500px) {
      font-size: 14px;
    }
  }
}

.about-5-wrapper {
  .card-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    min-height: 300px;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;

      &:nth-child(2) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
    }

    @media screen and (max-width: 850px) {
      grid-template-columns: 1fr;
      min-height: 450px;
    }
  }
}

.about-6-wrapper {
  .qualidade-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 450px) {
      flex-wrap: wrap;
    }
  }
}

::v-deep .value-highlight {
  font-weight: bold;
}

.card-highlight {
  font-size: 20px;
}

.card-section {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 680px) {
    grid-template-columns: 1fr;
  }
}
</style>