var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-img",
    {
      attrs: {
        src: _vm.hasBackground
          ? _vm.$routes.asset("img/auto/products/" + _vm.item.img)
          : null,
        gradient: _vm.hasBackground
          ? "90deg, rgba(33, 37, 41, 0.95), rgba(33, 37, 41, 0.95)"
          : null,
        "aspect-ratio": "hotfix",
        alt: _vm.item.title,
      },
    },
    [
      _c("div", { staticClass: "px-4" }, [
        _c("div", { staticClass: "limit-width py-12 d-flex align-center" }, [
          _c(
            "div",
            {
              class: { "white--text": _vm.hasBackground },
              staticStyle: { width: "100%" },
            },
            [
              _vm.item.title || _vm.item.description
                ? _c("div", { staticClass: "pt-6" }, [
                    _vm.item.title
                      ? _c("div", [
                          _c("h2", { staticClass: "highlight mb-2" }, [
                            _vm._v(_vm._s(_vm.item.title)),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.item.description
                      ? _c("div", {
                          domProps: { innerHTML: _vm._s(_vm.item.description) },
                        })
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "d-flex sub-wrapper pb-12",
                  class: "items-per-row-" + _vm.itemsPerRow,
                },
                _vm._l(_vm.item.sub, function (sub) {
                  return _c(
                    "div",
                    {
                      key: sub.title,
                      class:
                        _vm.$vuetify.breakpoint.width >= 1020
                          ? "mt-12"
                          : "mt-6",
                    },
                    [
                      _vm.$vuetify.breakpoint.width >= 1020
                        ? _c(
                            "div",
                            [
                              _c("v-img", {
                                attrs: {
                                  src: _vm.$routes.asset(
                                    "img/auto/products/" + sub.img
                                  ),
                                  alt: sub.title,
                                  height: 200,
                                  "max-width": _vm.itemsPerRow == 2 ? 480 : 306,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt-4" }, [
                        _c("h2", { staticClass: "highlight my-2 sub-title" }, [
                          _vm._v(_vm._s(sub.title)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("div", {
                          staticClass: "sub-description",
                          domProps: { innerHTML: _vm._s(sub.description) },
                        }),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }