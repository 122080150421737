var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "py-12 px-4" }, [
      _c("div", { staticClass: "limit-width py-12" }, [
        _c(
          "div",
          { staticClass: "blocks-wrapper white--text" },
          _vm._l(_vm.data, function (item, i) {
            return _c(
              "div",
              {
                key: item.title + i,
                staticClass: "elevation-2",
                class: [
                  "item-" + i,
                  _vm.isBig(i)
                    ? "item-big"
                    : _vm.isMedium(i)
                    ? "item-medium"
                    : "item-small",
                  { "item-campanhas": item.productIndex === null },
                ],
                on: {
                  click: function ($event) {
                    item.productIndex !== null
                      ? _vm.$router.push({
                          name: _vm.$config.navigation.auto.products.link,
                          params: { productIndex: item.productIndex },
                        })
                      : _vm.openClientArea()
                  },
                },
              },
              [
                _vm.isBig(i) || !_vm.isMedium(i)
                  ? _c("div", { staticClass: "item-overlay" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center" },
                        [
                          _c("h2", { staticClass: "h4 item-overlay-title" }, [
                            _vm._v(_vm._s(item.title)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-icon",
                            {
                              staticClass: "features-icon ml-1",
                              attrs: { dark: "" },
                            },
                            [_vm._v("mdi-chevron-right")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.isBig(i)
                        ? _c("div", {
                            staticClass: "item-overlay-description",
                            domProps: { innerHTML: _vm._s(item.description) },
                          })
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("v-hover", {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var hover = ref.hover
                          return [
                            _c(
                              "div",
                              [
                                _vm.isBig(i) || !_vm.isMedium(i)
                                  ? _c("v-img", {
                                      staticClass: "img-wrapper",
                                      class: { zoom: hover },
                                      attrs: {
                                        src: _vm.$routes.asset(
                                          "img/auto/products/" + item.img
                                        ),
                                        alt: item.title,
                                        width: _vm.getImgSize(i),
                                        height: _vm.getImgSize(i),
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _vm._v(" "),
                _vm.isMedium(i)
                  ? _c("div", { staticClass: "content-wrapper" }, [
                      _c("div", { staticClass: "px-12" }, [
                        _c("h2", { staticClass: "h4 item-title" }, [
                          _vm._v(_vm._s(item.title)),
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "item-description",
                          domProps: { innerHTML: _vm._s(item.description) },
                        }),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isMedium(i)
                  ? _c("div", { staticClass: "item-overlay" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center" },
                        [
                          _c("h2", { staticClass: "h6 item-overlay-title" }, [
                            _vm._v(_vm._s(_vm.$t("misc.more.know"))),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-icon",
                            {
                              staticClass: "features-icon ml-1",
                              attrs: { dark: "", size: "16" },
                            },
                            [_vm._v("mdi-chevron-right")]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            )
          }),
          0
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "pa-4 call-to-action" }, [
      _c("div", { staticClass: "limit-width" }, [
        _c("div", [
          _c("h2", { staticClass: "h6" }, [
            _vm._v(
              _vm._s(_vm.$t("auto.pages.home.products.campaigns.calltoaction"))
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ml-4" }, [
          _c(
            "a",
            {
              staticClass: "router-link",
              attrs: {
                target: "_blank",
                href: _vm.$config.platforms.weberp.link,
              },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "main-btn hover-alt",
                  attrs: {
                    dark: "",
                    title: _vm.$t("auto.pages.home.products.campaigns.title"),
                  },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.$t("auto.pages.home.products.campaigns.title")
                      ) +
                      "\n          "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }