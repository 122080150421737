var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "features-wrapper py-12 px-4" }, [
    _c("div", { staticClass: "limit-width py-12 text-center" }, [
      _c("h2", { staticClass: "bolder" }, [
        _vm._v(_vm._s(_vm.$t("industria.pages.home.features.title"))),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "divider mt-4" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "feature-wrapper mt-12" },
        _vm._l(
          _vm.$t("industria.pages.services.solutions.values"),
          function (item, i) {
            return _c("v-hover", {
              key: item.title,
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var hover = ref.hover
                      return [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: _vm.solutions.link,
                                params: { solutionsIndex: i },
                              },
                            },
                          },
                          [
                            _c(
                              "v-img",
                              {
                                staticClass: "elevation-4 img-wrapper",
                                class: { zoom: hover },
                                attrs: {
                                  src: _vm.solutions.values[i].img,
                                  alt: item.title,
                                  "max-width":
                                    _vm.$vuetify.breakpoint.width > 700
                                      ? 320
                                      : _vm.$vuetify.breakpoint.width > 450
                                      ? 240
                                      : 260,
                                  height:
                                    _vm.$vuetify.breakpoint.width > 700
                                      ? 160
                                      : "auto",
                                  "min-height": 160,
                                  gradient:
                                    "to top right, rgba(0,0,0,.15), rgba(0,0,0,.05)",
                                  eager: _vm.$vuetify.breakpoint.mobile,
                                },
                              },
                              [
                                !_vm.$vuetify.breakpoint.mobile
                                  ? _c("v-expand-transition", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "v-card--reveal white--text hover-overlay",
                                          staticStyle: { height: "100%" },
                                        },
                                        [
                                          _c(
                                            "h3",
                                            { staticClass: "h6 regular" },
                                            [_vm._v(_vm._s(item.title))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color: "white",
                                                size: "20",
                                              },
                                            },
                                            [_vm._v("mdi-chevron-right")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "feature-overlay" }, [
                                  _c("div", { staticClass: "py-2 px-4" }, [
                                    _c("h3", { staticClass: "h6" }, [
                                      _vm._v(_vm._s(item.title)),
                                    ]),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }
        ),
        1
      ),
      _vm._v(" "),
      _c("hr", { staticClass: "mt-12" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "performance-wrapper mt-10" },
        _vm._l(_vm.$t("company.stats.industry"), function (item, i) {
          return _c("div", { key: i }, [
            _c("h1", { staticClass: "bolder" }, [
              _vm._v(_vm._s(_vm.stats.industry[i])),
            ]),
            _vm._v(" "),
            _c(
              "h2",
              { staticClass: "regular text-uppercase performance-title" },
              [_vm._v(_vm._s(item))]
            ),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }