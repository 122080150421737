<template>
  <v-img
    class="hero-inner-page px-4"
    :src="src"
    :height="200"
    gradient="90deg, rgba(33, 37, 41, 0.9), rgba(33, 37, 41, 0.9)"
  >
    <div class="limit-width hero-inner-wrapper">

      <div class="breadcrumb-sm">
        <router-link :to="{ name: homeRoute.link }">
          <h6 class="quick-link regular ml-1">{{ $t(`${localePath}.navigation.links.home`) }}</h6>
        </router-link>

        <div
          v-for="(route, i) in routes"
          :key="route.link"
        >
          <v-icon
            color="rgba(255, 255, 255, 0.85)"
            size="16"
          >mdi-chevron-right</v-icon>

          <router-link
            :to="{ name: route.link }"
            v-if="i !== routes.length - 1"
          >
            <h6 class="quick-link regular ml-1">{{ $t(`${localePath}.navigation.${linksPath}.${route.localePath}`) }}</h6>
          </router-link>

          <h6
            v-else
            class="breadcrumb-last regular ml-1"
          >{{ $t(`${localePath}.navigation.${linksPath}.${route.localePath}`) }}</h6>
        </div>
      </div>

      <h1 class="white--text mt-2">{{ $t(`${localePath}.navigation.${linksPath}.${lastRoute.localePath}`) }}</h1>
      <div class="divider mt-2"></div>
    </div>
  </v-img>
</template>

<script>
import sector from '@mixins/sector/sector';

export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    routes: {
      type: Array,
      required: true,
    },
    otherlinks: {
      type: Boolean,
      default: false,
    }
  },
  mixins: [sector],
  computed: {
    homeRoute() {
      let home;

      if (this.isIndustria) home = this.$config.navigation.industria.home;
      else if (this.isAuto) home = this.$config.navigation.auto.home;
      else this.sectorNotDefined();

      return home;
    },
    lastRoute() {
      return this.routes[this.routes.length - 1];
    },
    linksPath() {
      return this.otherlinks === true ? 'otherlinks' : 'links';
    }
  },
}
</script>

<style lang="scss" scoped>
.hero-inner-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.breadcrumb-sm {
  text-transform: uppercase;
  display: flex;
  align-items: center;

  & > div {
    display: flex;
    align-items: center;
  }

  .breadcrumb-last {
    color: rgba(255, 255, 255, 0.4);
  }

  h6 {
    font-size: 12px;
  }
}
</style>