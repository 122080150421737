<template>
    <div>
        <intro-nav-drawer></intro-nav-drawer>
        <transition :name="name" mode="out-in">
            <router-view class="intro-router-view"></router-view>
        </transition>
    </div>
</template>

<script>
export default {
    data: () => ({
        to: null,
        from: null,
    }),
    computed: {
        name() {
            let transition = '';

            if (this.to === this.$config.navigation.intro.home.link) transition = 'slither-vert';
            if (this.to === this.$config.navigation.intro.contacts.link || this.to === this.$config.navigation.intro.about.link) transition = 'slither-hor';


            return transition;
        }
    },
    watch: {
        '$route.name': function (newVal, oldVal) {
            this.to = newVal;
            this.from = oldVal;
        }
    }
}
</script>

<style lang="scss" scoped>
.slither-hor-enter-active {
    transition: transform 650ms;
}

.slither-hor-leave-active {
    transition: transform 450ms;
}

.slither-hor-enter,
.slither-hor-leave-to {
    transform: translateX(-100%);
}

.slither-hor-enter,
.slither-hor-leave-to {
    transform: translateX(-100%);
}

.slither-hor-enter-to,
.slither-hor-leave {
    transform: translateX(0);
}

.slither-vert-enter-active,
.slither-vert-leave-active {
    transition: transform 500ms;
}

.slither-vert-enter,
.slither-vert-leave-to {
    transform: translateY(-100%);
}

.slither-vert-enter-to,
.slither-vert-leave {
    transform: translateY(0);
}
</style>
