var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    {
      staticClass: "appbar-wrapper",
      class: _vm.sector,
      attrs: { fixed: "", app: "" },
    },
    [
      _c(
        "div",
        { staticClass: "navbar limit-width" },
        [
          _c(
            "div",
            { staticClass: "logo" },
            [
              _c(
                "router-link",
                { attrs: { to: { path: "/" } } },
                [
                  _c("v-img", {
                    attrs: { src: _vm.logoSrc, alt: "Logo Romafe" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          !_vm.$vuetify.breakpoint.mobile
            ? _c(
                "div",
                { staticClass: "link-wrapper" },
                [
                  _vm._l(_vm.navLinks, function (item, i) {
                    return _c("v-hover", {
                      key: i,
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var hover = ref.hover
                              return [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "router-link",
                                    attrs: { to: { name: item.link } },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "nav-link",
                                        class: { hover: hover },
                                      },
                                      [
                                        _c(
                                          "v-list-item",
                                          [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c(
                                                  "v-list-item-title",
                                                  { staticClass: "nav-title" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          _vm.localePath +
                                                            ".navigation.links." +
                                                            item.localePath
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex justify-center align-center",
                      attrs: { id: "new" },
                    },
                    [_c("language-change")],
                    1
                  ),
                ],
                2
              )
            : _c(
                "div",
                [
                  _c(
                    "v-menu",
                    {
                      staticClass: "hidden-md-and-up",
                      attrs: { "min-width": "200" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-app-bar-nav-icon",
                                _vm._g(
                                  {
                                    staticClass: "appbar-nav-icon",
                                    attrs: { "aria-label": "menu" },
                                  },
                                  on
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _vm._v(" "),
                      _c(
                        "v-list",
                        [
                          _vm._l(_vm.navLinks, function (item, i) {
                            return _c(
                              "router-link",
                              {
                                key: i,
                                staticClass: "router-link",
                                class: { "router-link-auto": _vm.isAuto },
                                attrs: { to: { name: item.link } },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "nav-link mobile" },
                                  [
                                    _c(
                                      "v-list-item",
                                      [
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c(
                                              "v-list-item-title",
                                              { staticClass: "nav-title" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.localePath +
                                                        ".navigation.links." +
                                                        item.localePath
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "d-flex justify-center align-center",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                },
                              },
                            },
                            [_c("language-change")],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }