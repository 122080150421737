<template>
  <div
    class="news-wrapper py-12 px-4"
    v-if="frontpageNews.length > 0"
  >
    <div class="limit-width py-12">

      <h2 class="bolder">{{ $t('industria.pages.home.news.title') }}</h2>
      <div class="divider mt-4"></div>

      <div class="mt-6">
        {{ $t(`${this.sector}.pages.home.news.description[0]`) }}.<br>
        {{ $t(`${this.sector}.pages.home.news.description[1]`) }}.
      </div>

      <div
        class="news-content-wrapper mt-6"
        :class="sector"
      >
        <v-img
          v-for="(item, i) in frontpageNews"
          :key="item.id"
          class="elevation-4"
          :class="'news-' + (i + 1)"
          :src="i === 0 ? $routes.asset(item.imagem) : ''"
          :min-height="152"
          :alt="item.titulo"
          :eager="$vuetify.breakpoint.mobile"
        >

          <v-skeleton-loader
            v-if="item.loaded === false && i === 0 && $vuetify.breakpoint.width > 1100"
            type="card"
            class="news-primary-wrapper"
          ></v-skeleton-loader>

          <div
            v-if="item.loaded !== false && i === 0 && $vuetify.breakpoint.width > 1100"
            class="news-primary-wrapper"
          >
            <div class="news-content ma-4">
              <div class="pa-4">
                <h3 class="news-date mt-1">{{ item.data }}</h3>

                <router-link
                  :to="{ name: $config.navigation[sector].newsSingle.link, params: { title: $routes.sanitize(item.titulo) }, query: { id: item.id } }"
                  :title="item.titulo"
                >
                  <h3
                    class="news-title h4 mt-2"
                    :title="item.titulo"
                  >{{ item.titulo }}</h3>
                </router-link>

                <div class="divider mt-2"></div>
                <div
                  class="news-description mt-2"
                  v-html="item.resumo"
                ></div>
              </div>
            </div>
          </div>

          <v-skeleton-loader
            v-if="item.loaded === false && (i !== 0 || $vuetify.breakpoint.width <= 1100)"
            type="image, article"
            class="news-secondary-wrapper"
          ></v-skeleton-loader>

          <div
            v-if="item.loaded !== false && (i !== 0 || $vuetify.breakpoint.width <= 1100)"
            class="news-secondary-wrapper"
          >
            <v-hover v-slot:default="{ hover }">
              <router-link :to="{ name: $config.navigation[sector].newsSingle.link, params: { title: $routes.sanitize(item.titulo) }, query: { id: item.id } }">
                <v-img
                  class="news"
                  :src="$routes.asset(item.imagem)"
                  :min-height="152"
                  :max-width="152"
                  :alt="'Notícia - ' + item.titulo"
                  :class="{ zoom: hover }"
                  :eager="$vuetify.breakpoint.mobile"
                >

                  <v-scale-transition origin="center center">

                    <div
                      v-if="hover"
                      class="v-card--reveal white--text hover-overlay"
                      style="height: 100%;"
                    >
                      <div>{{ $t('misc.more.read') }}</div>
                      <v-icon
                        color="white"
                        size="20"
                      >mdi-chevron-right</v-icon>
                    </div>

                  </v-scale-transition>

                </v-img>
              </router-link>
            </v-hover>

            <div class="news-content">
              <div class="py-2 px-4">
                <h3 class="news-date mt-1">{{ item.data }}</h3>

                <router-link
                  :to="{ name: $config.navigation[sector].newsSingle.link, params: { title: $routes.sanitize(item.titulo) }, query: { id: item.id } }"
                  :title="item.titulo"
                >
                  <h3
                    class="news-title mt-2 h6"
                    :title="item.titulo"
                  >{{ item.titulo }}</h3>
                </router-link>

                <div
                  class="news-description mt-1"
                  v-html="item.resumo"
                ></div>
              </div>
            </div>
          </div>

        </v-img>
      </div>

    </div>
  </div>

</template>

<script>
import sector from '@mixins/sector/sector';

export default {
  mixins: [sector],
  computed: {
    frontpageNews() {
      return this.$store.getters[`${this.sector}/news/frontpage`];
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-skeleton-loader {
  &.news-primary-wrapper {
    height: 100%;
    width: 100%;
    > div {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      > div {
        &:nth-child(1) {
          flex: 2;
        }
        &:nth-child(2) {
          flex: 1;
        }
      }
    }
  }

  &.news-secondary-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    > div {
      height: 100%;
      width: 100%;
      &:nth-child(1) {
        max-width: 152px;
      }
      &:nth-child(2) {
        flex: 1;
      }
    }
  }
}

.news-wrapper {
  background-color: $bgAlt;
}

.news-content-wrapper {
  height: 491px;
  display: grid;
  gap: 18px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;

  grid-template-areas:
    "news1 news2"
    "news1 news3"
    "news1 news4";

  @for $i from 1 through 4 {
    & .news-#{$i} {
      grid-area: news#{$i};
    }
  }

  @media screen and (max-width: 1100px) {
    height: 662px; //152px (min-height) * 4 + 18px (gap) * 3
    grid-template-columns: 1fr;
    grid-template-areas:
      "news1"
      "news2"
      "news3"
      "news4";
  }
}

.news {
  z-index: 1;
  cursor: pointer;
}

::v-deep .news-content-wrapper {
  .news {
    &-date {
      font-size: 14px;
      color: $palette0;
      @media screen and (max-width: 600px) {
        font-size: 12px;
      }
    }
    &-title {
      cursor: pointer;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      transition: 150ms ease-in-out;
      &:hover {
        color: $palette0;
      }
      @media screen and (max-width: 1100px) {
        -webkit-line-clamp: 1;
        line-height: 22px;
      }
      @media screen and (max-width: 600px) {
        font-size: 14px !important;
        -webkit-line-clamp: 2;
      }
    }
    &-description {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      @media screen and (max-width: 600px) {
        font-size: 12px;
        -webkit-line-clamp: 3;
      }
    }

    overflow: hidden;

    .v-image__image {
      transition: 350ms ease-in-out;
    }

    &.zoom {
      .v-image__image {
        transform: scale(1.2);
      }
    }
  }

  &.auto .news {
    &-date, &-title:hover {
      color: $palette1;
    }
  }
}

::v-deep .news-primary-wrapper .news-description {
  -webkit-line-clamp: 3;
}

.news-primary-wrapper {
  height: 100%;
  display: flex;
  align-items: flex-end;
  .news-content {
    & > div {
      background-color: rgba(255, 255, 255, 0.95);
    }
  }
}

.news-secondary-wrapper {
  background-color: #fff;
  .news-content {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    & > div {
      height: 100%;
      margin-left: 152px;
    }
  }
}
</style>