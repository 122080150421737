<template>
    <div>
        <transition :name="name" mode="out-in">
            <router-view></router-view>
        </transition>

        <cookies-bar></cookies-bar>
    </div>
</template>

<script>
import sector from '@mixins/sector/sector';

export default {
    data: () => ({
        to: null,
        from: null,
    }),
    mixins: [sector],
    methods: {
        axiosInterceptor() {
            let self = this;

            axios.interceptors.request.use(function (config) {
                // Do something before request is sent
                if (config.method === 'post') {
                    //!Adiciona o setor correspondente (industria, auto, agricultura)
                    let sector = self.isIndustria ? 0 : self.isAuto ? 1 : self.isAgricultura ? 2 : -1;
                    config.data.tipoact = sector;
                    //!Adiciona o locale correspondente (pt, en...)
                    if (!config.data.lang) {
                        let lang = self.$root.$i18n.locale;
                        config.data.lang = lang;
                    }
                }
                return config;
            }, function (error) {
                // Do something with request error
                return Promise.reject(error);
            });
        },
        fixHeight() {
            if (!this.isIntro) return;

            let appWrap = document.getElementsByClassName("v-application--wrap");
            appWrap[0].style.minHeight = window.innerHeight + 'px';

            let navDrawer = document.getElementsByClassName("v-navigation-drawer");
            if (navDrawer.length > 0) navDrawer[0].style.height = window.innerHeight + 'px';
        },
    },
    created() {
        this.axiosInterceptor();
    },
    mounted() {
        this.fixHeight();
        window.addEventListener('resize', this.fixHeight);
    },
    computed: {
        name() {
            let transition = '';

            if (this.to === this.$config.navigation.industria.home.link || this.to === this.$config.navigation.auto.home.link || this.to === this.$config.navigation.agricultura.home.link) transition = 'fade';

            return transition;
        },
    },
    watch: {
        '$route.name': function (newVal, oldVal) {
            this.to = newVal;
            this.from = oldVal;
        }
    }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
    transition-property: opacity;
    transition-duration: 0.25s;
}

.fade-enter-active {
    transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
    opacity: 0;
}
</style>
