<template>
    <v-app-bar class="appbar-wrapper" :class="sector" fixed app>
        <div class="navbar limit-width">
            <div class="logo">
                <router-link :to="{ path: '/' }">
                    <v-img :src="logoSrc" alt="Logo Romafe"></v-img>
                </router-link>
            </div>

            <v-spacer></v-spacer>

            <div v-if="!$vuetify.breakpoint.mobile" class="link-wrapper">
                <v-hover v-slot="{ hover }" v-for="(item, i) in navLinks" :key="i">
                    <router-link class="router-link" :to="{ name: item.link }">
                        <div class="nav-link" :class="{ 'hover': hover }">

                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="nav-title">{{
                                        $t(`${localePath}.navigation.links.${item.localePath}`) }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                        </div>
                    </router-link>
                </v-hover>

                <div id="new" class="d-flex justify-center align-center">
                    <language-change></language-change>
                </div>

                <!--         <a
          :href="$config.platforms.weberp.link"
          class="nav-link nav-title ml-4"
        >
          <v-btn
            class="client-area"
            dark
            :title="$t(`${localePath}.clientarea`)"
          >
            {{ $t(`${localePath}.clientarea`) }}
          </v-btn>
        </a> -->

            </div>

            <div v-else>
                <v-menu class="hidden-md-and-up" min-width="200">
                    <template v-slot:activator="{ on }">
                        <v-app-bar-nav-icon v-on="on" aria-label="menu" class="appbar-nav-icon"></v-app-bar-nav-icon>
                    </template>

                    <v-list>
                        <router-link v-for="(item, i) in navLinks" :key="i" class="router-link"
                            :class="{ 'router-link-auto': isAuto }" :to="{ name: item.link }">
                            <div class="nav-link mobile">
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title class="nav-title">{{
                                            $t(`${localePath}.navigation.links.${item.localePath}`)
                                            }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </div>
                        </router-link>

                        <!--   <a
              :href="$config.platforms.weberp.link"
              class="nav-link nav-title px-3 py-2"
            >
              <v-btn
                class="nav-tittle"
                dark
                :title="$t(`${localePath}.clientarea`)"
              >
                {{ $t(`${localePath}.clientarea`) }}
              </v-btn>
            </a> -->

                        <div class="d-flex justify-center align-center" @click.stop.prevent>
                            <language-change></language-change>
                        </div>
                    </v-list>
                </v-menu>
            </div>

        </div>

    </v-app-bar>
</template>

<script>
import sector from '@mixins/sector/sector';

export default {
    mixins: [sector],
    computed: {
        logoSrc() {
            let color = this.isIndustria ? 'blue' : 'white';
            return this.$routes.asset(`img/logo/romafe-${color}.png`);
        },
    }
}
</script>


<style lang="scss" scoped>
.appbar-wrapper {
    &.industria {
        background-color: #fff !important;

        .router-link {
            color: #444;

            & .v-list-item__title {
                color: #444;
            }
        }

        .router-link-exact-active {
            & .v-list-item__title {
                color: $romafe;
            }

            & .nav-link:not(.mobile) {
                border-top: 4px solid $romafe;
            }
        }

        .nav-link {
            &.hover:not(.mobile) {
                border-top: 4px solid $romafe;

                & .nav-title {
                    color: $romafe;
                }
            }
        }
    }

    &.auto {
        background-color: $palette1 !important;

        .router-link {
            color: #fff;

            & .v-list-item__title {
                color: #fff;
            }
        }

        .router-link-exact-active {
            & .v-list-item__title {
                color: darken($palette1, 25%);
            }

            & .nav-link:not(.mobile) {
                border-top: 4px solid darken($palette1, 25%);
            }
        }

        .nav-link {
            &.hover:not(.mobile) {
                border-top: 4px solid darken($palette1, 25%);

                & .nav-title {
                    color: darken($palette1, 25%);
                }
            }
        }

        .appbar-nav-icon i {
            color: #fff;
        }
    }

    &.agricultura {
        background-color: $palette2 !important;

        .router-link {
            color: #fff;

            & .v-list-item__title {
                color: #fff;
            }
        }

        .router-link-exact-active {
            & .v-list-item__title {
                color: $palette2Sec;
            }

            & .nav-link:not(.mobile) {
                border-top: 4px solid $palette2Sec;
            }
        }

        .nav-link {
            &.hover:not(.mobile) {
                border-top: 4px solid darken($palette2Sec, 10%);

                & .nav-title {
                    color: darken($palette2Sec, 10%);
                }
            }
        }

        .appbar-nav-icon i {
            color: #fff;
        }
    }
}

::v-deep .v-toolbar__content,
.v-toolbar__extension {
    padding: 0px 16px;
}

.navbar {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;

    & .logo {
        transition: width 0.1s;
        max-width: 100px;
    }
}

.nav-link {
    height: 100%;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 600;
    color: #444;
    font-size: 14px;

    & .nav-title {
        font-size: 13px;
    }

    &.hover:not(.mobile) {
        & .v-list-item__content {
            margin-top: -4px;
        }
    }

    &.no-router {

        & .v-list-item:hover::before,
        .v-list-item:active::before,
        .v-list-item:focus::before {
            opacity: 0;
        }
    }

    & ::v-deep .v-list-item {
        padding: 0 12px;
    }
}

.link-wrapper {
    display: flex;
    height: 100%;
}

.router-link-exact-active {
    & .nav-link {
        &:not(.mobile) {
            & .v-list-item__content {
                margin-top: -4px;
            }
        }

        &.mobile {
            & .nav-title {
                color: $palette0;
            }
        }
    }
}

.router-link-exact-active.router-link-auto .nav-title {
    color: $palette1 !important;
}

.client-area {
    letter-spacing: normal;
    background-color: $palette0 !important;
}
</style>
