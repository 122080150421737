var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("hero-inner-page", {
        attrs: {
          src: _vm.$routes.asset("img/auto/hero/products/hero-inner-page.jpg"),
          title: "Produtos",
          routes: [_vm.$config.navigation.auto.products],
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "pt-12 pb-6 px-4 bg-main" }, [
        _c(
          "div",
          { staticClass: "limit-width py-12 product-select-wrapper" },
          _vm._l(_vm.$t("auto.pages.products.categories"), function (item, i) {
            return _c("div", { key: item.title, staticClass: "pr-8" }, [
              _c(
                "div",
                {
                  staticClass: "product-select-title",
                  class: { selected: i == _vm.selected },
                  on: {
                    click: function ($event) {
                      _vm.selected = i
                    },
                  },
                },
                [_c("h2", [_vm._v(_vm._s(item.title))])]
              ),
            ])
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "px-4 bg-main" }, [
        _c(
          "div",
          {
            staticClass: "limit-width pb-12 d-flex align-center",
            class: { "has-gap": _vm.category.img },
          },
          [
            _vm.$vuetify.breakpoint.width >= 1020
              ? _c(
                  "div",
                  [
                    _c("v-img", {
                      attrs: {
                        src: _vm.$routes.asset(
                          "img/auto/products/" + _vm.category.img
                        ),
                        alt: _vm.category.title,
                        "max-width":
                          _vm.$vuetify.breakpoint.width < 400
                            ? 220
                            : _vm.$vuetify.breakpoint.width < 600
                            ? 300
                            : 400,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", [
              _c("div", [
                _c("h1", { staticClass: "highlight mb-2" }, [
                  _vm._v(_vm._s(_vm.category.title)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", {
                domProps: { innerHTML: _vm._s(_vm.category.description) },
              }),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm._l(_vm.subCategory, function (item, i) {
        return _c(
          "div",
          { key: item.title },
          [
            item.layout == 0 || item.layout == 1
              ? _c("auto-products-page-layout-single", {
                  class: { "pb-12": i == _vm.category.sub.length - 1 },
                  attrs: {
                    item: item,
                    isImageLeft: item.layout == 0 ? true : false,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.layout == 2 || item.layout == 3
              ? _c("auto-products-page-layout-multiple", {
                  class: { "pb-12": i == _vm.category.sub.length - 1 },
                  attrs: { item: item, itemsPerRow: item.layout },
                })
              : _vm._e(),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }