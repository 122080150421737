<template>
  <div
    class="news-page-wrapper"
    :class="sector"
  >
    <hero-inner-page
      :src="$routes.asset('/img/components/hero/news/hero-inner-page.jpg')"
      :routes="[$config.navigation[sector].news]"
    ></hero-inner-page>

    <div class="py-12 px-4">
      <div class="limit-width py-12">

        <v-hover
          v-slot="{ hover }"
          v-for="(item) in news.data"
          :key="item.id"
        >
          <v-card
            :elevation="hover ? 4 : 2"
            outlined
            class="news-card mb-6"
          >

            <div v-if="$vuetify.breakpoint.width > 850">
              <router-link :to="{ name: $config.navigation[sector].newsSingle.link, params: { title: $routes.sanitize(item.titulo) }, query: { id: item.id } }">
                <v-hover v-slot="{ hover }">
                  <v-img
                    class="img-wrapper"
                    :class="{ zoom: hover }"
                    :alt="'Notícia - ' + item.titulo"
                    :src="item.imagem != null ? $routes.asset(item.imagem) : null"
                    :height="imgSize"
                    :width="imgSize"
                    :max-height="imgSize"
                    :max-width="imgSize"
                    :eager="$vuetify.breakpoint.mobile"
                  >
                    <v-skeleton-loader
                      v-if="item.loaded === false && $vuetify.breakpoint.width > 850"
                      type="image"
                      class="news-img"
                    ></v-skeleton-loader>
                  </v-img>
                </v-hover>
              </router-link>
            </div>

            <v-skeleton-loader
              v-if="item.loaded === false"
              type="article, paragraph"
              class="news-article"
            ></v-skeleton-loader>

            <v-container
              fluid
              class="pa-4"
              v-else
            >
              <div>
                <h1 class="news-date h5"><span>{{ item.data }}</span></h1>

                <router-link
                  :to="{ name: $config.navigation[sector].newsSingle.link, params: { title: $routes.sanitize(item.titulo) }, query: { id: item.id } }"
                  :title="item.title"
                >
                  <h2 class="news-title h5 mt-2">{{ item.titulo }} </h2>
                </router-link>

                <div
                  class="divider my-2"
                  style="max-width: 50px;"
                ></div>

                <div
                  class="news-description"
                  v-html="item.resumo"
                ></div>
              </div>

              <div>
                <div
                  class="news-more"
                  :class="{ 'mt-4': $vuetify.breakpoint.width <= 850 }"
                >
                  <router-link :to="{ name: $config.navigation[sector].newsSingle.link, params: { title: $routes.sanitize(item.titulo) }, query: { id: item.id } }">
                    <v-btn
                      class="main-btn"
                      dark
                      :title="$t('misc.more.read')"
                    >
                      {{ $t('misc.more.read') }}
                    </v-btn>
                  </router-link>
                </div>
              </div>

            </v-container>
          </v-card>
        </v-hover>

        <div class="text-center">
          <v-btn
            class="main-btn load-more mt-6"
            height="48"
            :disabled="news.loading || news.last"
            @click="loadMore"
          >
            {{ news.last ? $t('misc.news.empty') : $t('misc.more.load') }}
          </v-btn>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import sector from '@mixins/sector/sector';

export default {
  data: () => ({
    imgSize: 279,
  }),
  mixins: [sector],
  methods: {
    loadMore() {
      this.$store.commit(`${this.sector}/news/get`, { 'loadmore': true });
    }
  },
  computed: {
    news() {
      return this.$store.state[this.sector].news;
    },
  },
}
</script>

<style lang="scss" scoped>
.news-page-wrapper {
  &.industria {
    .news {
      &-date {
        color: $palette0;
      }
      &-title {
        &:hover {
          color: $palette0;
        }
      }
    }
  }

  &.auto {
    .news {
      &-date {
        color: $palette1;
      }
      &-title {
        &:hover {
          color: $palette1;
        }
      }
    }
  }
}

::v-deep .v-skeleton-loader {
  &.news-img {
    height: 100%;
    width: 100%;
    > div {
      height: 100%;
      width: 100%;
    }
  }

  &.news-article {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    > div {
      flex: 1;
      height: 100%;
      width: 100%;
      &:nth-child(2) {
        padding: 16px;
      }
    }
  }
}

.news-card {
  display: flex;
  height: 280px;

  .container {
    height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @media screen and (max-width: 850px) {
    height: 100%;

    .container {
      height: 100%;
    }
  }
}

::v-deep .img-wrapper {
  cursor: pointer;
  .v-image__image {
    transition: 350ms ease-in-out;
  }

  &.zoom {
    .v-image__image {
      transform: scale(1.2);
    }
  }
}

::v-deep .news {
  &-more {
    text-align: right;
  }
  &-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    transition: 150ms ease-in-out;
  }
  &-description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}

::v-deep .load-more {
  &.v-btn--disabled {
    .v-btn__content {
      color: rgba(0, 0, 0, 0.26) !important;
    }
  }

  .v-btn__content {
    color: #fff;
  }
}
</style>