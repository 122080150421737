//type:
// 0 -> info
// 1 -> error
// 2 -> success

const dialog = {
  namespaced: true,
  state: {
    value: false,
    title: '',
    content: '',
    type: 0,
  },

  getters: {

  },

  actions: {

  },

  mutations: {
    openDialog(state, data) {
      state.title = data.title;
      state.content = data.content;
      state.type = data.type || 0;
      state.value = true;
    },
    closeDialog(state) {
      state.value = false;
    }
  },
}


export default dialog