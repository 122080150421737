<template>
  <div>
    <div class="py-12 px-4">
      <div class="limit-width py-12">

        <div class="blocks-wrapper white--text">
          <div
            v-for="(item, i) in data"
            :key="item.title + i"
            class="elevation-2"
            :class="[`item-${i}`, isBig(i) ? 'item-big' : isMedium(i) ? 'item-medium' : 'item-small', {'item-campanhas': item.productIndex === null}]"
            @click="item.productIndex !== null ? $router.push({'name': $config.navigation.auto.products.link, params: { productIndex: item.productIndex}}) : openClientArea()"
          >

            <!-- Big and small -->
            <div
              class="item-overlay"
              v-if="(isBig(i) || !isMedium(i))"
            >
              <div class="d-flex align-items-center">
                <h2 class="h4 item-overlay-title">{{ item.title }}</h2>
                <v-icon
                  class="features-icon ml-1"
                  dark
                >mdi-chevron-right</v-icon>
              </div>

              <!-- Big -->
              <div
                class="item-overlay-description"
                v-if="isBig(i)"
                v-html="item.description"
              ></div>
            </div>

            <v-hover v-slot="{ hover }">
              <div>
                <v-img
                  v-if="(isBig(i) || !isMedium(i))"
                  :src="$routes.asset(`img/auto/products/${item.img}`)"
                  class="img-wrapper"
                  :class="{ zoom: hover }"
                  :alt="item.title"
                  :width="getImgSize(i)"
                  :height="getImgSize(i)"
                ></v-img>
              </div>
            </v-hover>

            <!-- Medium -->
            <div
              class="content-wrapper"
              v-if="isMedium(i)"
            >
              <div class="px-12">
                <h2 class="h4 item-title">{{ item.title }}</h2>
                <div
                  class="item-description"
                  v-html="item.description"
                ></div>
              </div>
            </div>

            <div
              class="item-overlay"
              v-if="isMedium(i)"
            >
              <div class="d-flex align-items-center">
                <h2 class="h6 item-overlay-title">{{ $t('misc.more.know') }}</h2>
                <v-icon
                  class="features-icon ml-1"
                  dark
                  size="16"
                >mdi-chevron-right</v-icon>
              </div>
            </div>

          </div>

        </div>

      </div>
    </div>

    <div class="pa-4 call-to-action">
      <div class="limit-width">
        <div>
          <h2 class="h6">{{ $t('auto.pages.home.products.campaigns.calltoaction') }}</h2>
        </div>
        <div class="ml-4">
          <a
            class="router-link"
            target="_blank"
            :href="$config.platforms.weberp.link"
          >
            <v-btn
              class="main-btn hover-alt"
              dark
              :title="$t('auto.pages.home.products.campaigns.title')"
            >
              {{ $t('auto.pages.home.products.campaigns.title') }}
            </v-btn>
          </a>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data: () => ({
    data: []
  }),
  methods: {
    openClientArea() {
      window.open(this.$config.platforms.weberp.link, '_blank').focus();
    },
    setData() {
      this.data = [];

      // null = campanhas
      let positions = [8, 0, 3, null, 2, 9, 1, 4, 7, 5, 6, 10, 11];

      for (let i = 0; i < positions.length; i++) {
        let productIndex = positions[i];
        let product = this.products[productIndex];

        if (productIndex !== null) {
          //Push product to array
          this.data.push({
            title: product.title,
            description: product.description,
            img: product.homeImg,
            productIndex: productIndex,
          });
        }
        else this.data.push({
          title: this.$t('auto.pages.home.products.campaigns.title'),
          description: this.$t('auto.pages.home.products.campaigns.description'),
          productIndex: productIndex
        }) //Push campanhas block
      }
    },
    getImgSize(i) {
      let size = null;
      if (this.$vuetify.breakpoint.width > 550)
        size = this.isBig(i) ? 496 : !this.isMedium(i) ? 160 : "auto";
      else
        size = this.isBig(i) ? 324 : !this.isMedium(i) ? 158 : "auto";

      return size;
    },
    isBig(i) {
      return i === 0 || i === 7;
    },
    isMedium(i) {
      return i === 3 || i === 9;
    }
  },
  created() {
    this.setData();
  },
  computed: {
    products() {
      return this.$t('auto.pages.products.categories');
    },
  },
  watch: {
    '$root.$i18n.locale': function (newVal, oldVal) {
      if (this.$route.name === this.$config.navigation.auto.home.link) this.setData();
    }
  }
}
</script>

<style lang="scss" scoped>
.blocks-wrapper {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(6, 1fr);
  grid-template-areas:
    "area0 area0 area0 area1 blank0 area2"
    "area0 area0 area0 area3 area3 blank1"
    "area0 area0 area0 area3 area3 area4"
    "area5 blank2 area6 area7 area7 area7"
    "area8 area9 area9 area7 area7 area7"
    "area10 area9 area9 area7 area7 area7";

  @for $i from 0 through 10 {
    .item-#{$i} {
      grid-area: area#{$i};
    }
  }

  //Big: 496px
  //Medium: 328px
  //Small: 160px

  & > div {
    cursor: pointer;
    position: relative;
    transition: 200ms ease-in-out;
    &:hover {
      opacity: 0.9;
      .item-overlay-description,
      .item-description {
        opacity: 0.5;
      }
      .item-big .item-overlay,
      .item-small .item-overlay {
        background: linear-gradient(
          to right top,
          darken(rgba(207, 55, 45, 0.9), 5%),
          lighten(rgba(207, 55, 45, 0.9), 8%)
        );
      }
    }
  }

  .item-title,
  .item-overlay-title {
    transition: 200ms ease-in-out;
    text-transform: uppercase;
  }

  .item-description,
  .item-overlay-description {
    transition: 200ms ease-in-out;
    margin-top: 6px;
    font-size: 13px;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .item-description {
    -webkit-line-clamp: 3;
  }

  .item-overlay-description {
    -webkit-line-clamp: 2;
  }

  .item-overlay {
    background-color: rgba(207, 55, 45, 0.9);
    z-index: 2;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 12px;

    background: linear-gradient(
      to right top,
      darken(rgba(207, 55, 45, 0.9), 5%),
      lighten(rgba(207, 55, 45, 0.9), 5%)
    );
  }

  .item-big {
    height: 496px;
  }

  .item-medium {
    height: 328px;
    background: $palette1;

    .content-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .item-overlay-title {
      font-size: 14px !important;
    }
    .item-overlay {
      background: transparent;
    }

    background: linear-gradient(
      to right top,
      darken($palette1, 5%),
      lighten($palette1, 5%)
    );

    &:not(.item-campanhas) {
      &:hover {
        background: linear-gradient(
          to right top,
          darken($palette1, 5%),
          lighten($palette1, 8%)
        );
      }
    }
  }

  .item-small {
    height: 160px;
    .item-overlay-title,
    .v-icon {
      font-size: 12px !important;
    }

    .item-overlay {
      padding: 8px 10px;
    }
  }

  .item-campanhas {
    background: transparent;
    border: 2px solid $palette1;

    .item-title,
    .item-overlay-title,
    .v-icon {
      color: $palette1;
    }

    .item-description {
      color: black;
    }
  }

  @media screen and (max-width: 1050px) {
    width: 496px;
    margin: 0 auto;

    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      "area0 area0 area0"
      "area0 area0 area0"
      "area0 area0 area0"
      "area1 area2 area4"
      "area3 area3 area5"
      "area3 area3 area6"
      "area7 area7 area7"
      "area7 area7 area7"
      "area7 area7 area7"
      "area8 area9 area9"
      "area10 area9 area9";
  }

  @media screen and (max-width: 550px) {
    width: 324px;
    margin: 0 auto;

    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "area0 area0"
      "area0 area0"
      "area1 area2"
      "area3 area3"
      "area3 area3"
      "area4 area5"
      "area7 area7"
      "area7 area7"
      "area6 area8"
      "area9 area9"
      "area9 area9"
      "area10 blank0";

    .item-big {
      height: 324px;
    }

    .item-medium {
      height: 324px;
    }

    .item-small {
      height: 158px;
    }
  }
}

::v-deep .img-wrapper {
  cursor: pointer;
  .v-image__image {
    transition: 250ms ease-in-out;
  }

  &.zoom {
    .v-image__image {
      transform: scale(1.05);
    }
  }
}

.call-to-action {
  h6 {
    @media screen and (max-width: 500px) {
      font-size: 14px;
    }
  }
}
</style>