var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "services-page-wrapper" },
    [
      _c("hero-inner-page", {
        attrs: {
          src: _vm.$routes.asset(
            "img/industria/hero/services/hero-inner-page.jpg"
          ),
          routes: [_vm.$config.navigation.industria.services],
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "py-12 px-4 bg-main" }, [
        _c("div", { staticClass: "limit-width py-12" }, [
          _c("div", { staticClass: "intro-wrapper" }, [
            _c("div", [
              _c("h1", { staticClass: "mb-6 highlight" }, [
                _vm._v(_vm._s(_vm.$t("industria.pages.services.intro.title"))),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  _vm._s(_vm.$t("industria.pages.services.intro.text[0]")) + "."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  _vm._s(_vm.$t("industria.pages.services.intro.text[1]")) + "."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  _vm._s(_vm.$t("industria.pages.services.intro.text[2]")) + "."
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "mb-0" }, [
                _c("b", [
                  _vm._v(
                    _vm._s(_vm.$t("industria.pages.services.intro.text[3]")) +
                      "."
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { class: { "mt-6": _vm.$vuetify.breakpoint.width < 900 } },
              [
                _c("v-img", {
                  attrs: {
                    src: _vm.$routes.asset(
                      "img/industria/services/solutions.jpg"
                    ),
                    alt: "Conjunto soluções Romafe",
                    "max-width":
                      _vm.$vuetify.breakpoint.width < 400
                        ? 220
                        : _vm.$vuetify.breakpoint.width < 600
                        ? 300
                        : 400,
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("hr", { staticClass: "my-12" }),
          _vm._v(" "),
          _c("h2", { staticClass: "mb-2 text-uppercase h5 text-center" }, [
            _c("b", [
              _vm._v(_vm._s(_vm.$t("industria.pages.services.sectors.title"))),
            ]),
          ]),
          _vm._v(" "),
          _c("div", {
            staticClass: "divider mt-4",
            staticStyle: { margin: "0 auto" },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "sector-wrapper mt-6" },
            _vm._l(
              _vm.$t("industria.pages.services.sectors.values"),
              function (item, i) {
                return _c(
                  "div",
                  {
                    key: item,
                    staticClass: "d-flex justify-center align-center mt-4",
                    class:
                      _vm.$vuetify.breakpoint.width < 450 ? "pa-1" : "pa-4",
                  },
                  [
                    _c(
                      "v-icon",
                      {
                        attrs: {
                          size:
                            _vm.$vuetify.breakpoint.width < 450 ? "32" : "38",
                        },
                      },
                      [_vm._v(_vm._s(_vm.icons[i]))]
                    ),
                    _vm._v(" "),
                    _c(
                      "h3",
                      { staticClass: "mt-4 sector-value text-uppercase h6" },
                      [_vm._v(_vm._s(item))]
                    ),
                  ],
                  1
                )
              }
            ),
            0
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "v-sheet",
        {
          staticClass: "benefits-wrapper",
          attrs: { height: "100%", tile: "", color: "#333" },
        },
        [
          _c("v-parallax", {
            attrs: {
              src: _vm.$routes.asset("img/industria/services/benefits.jpg"),
              "lazy-src": _vm.$routes.asset(
                "img/industria/services/benefits-lazy.jpg"
              ),
              alt: "Vantagens ao escolher a Romafe",
              height: 350,
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "overlay px-4" }, [
            _c(
              "div",
              { staticClass: "limit-width overlay-content white--text py-8" },
              [
                _c("h1", { staticClass: "highlight white--text" }, [
                  _vm._v(
                    _vm._s(_vm.$t("industria.pages.services.benefits.title[0]"))
                  ),
                  _c("br"),
                  _c("span", { staticClass: "highlight" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("industria.pages.services.benefits.title[1]")
                      )
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mt-6" }, [
                  _c(
                    "div",
                    { staticClass: "list-indented" },
                    _vm._l(
                      _vm.$t("industria.pages.services.benefits.values"),
                      function (benefit, i) {
                        return _c(
                          "p",
                          { key: i, staticClass: "mb-0" },
                          [
                            _c("v-icon", { staticClass: "list-bullet" }, [
                              _vm._v("mdi-circle-medium"),
                            ]),
                            _vm._v(_vm._s(benefit)),
                            i !=
                            _vm.$t("industria.pages.services.benefits.values")
                              .length -
                              1
                              ? _c("span", [_vm._v(";")])
                              : _c("span", [_vm._v(".")]),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  ),
                ]),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "pa-4 call-to-action" }, [
        _c("div", { staticClass: "limit-width" }, [
          _c("div", [
            _c("h2", { staticClass: "h6" }, [
              _vm._v(
                _vm._s(_vm.$t("industria.pages.services.benefits.calltoaction"))
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ml-4" },
            [
              _c(
                "router-link",
                {
                  staticClass: "router-link",
                  attrs: {
                    to: {
                      name: _vm.$config.navigation.industria.contacts.link,
                    },
                  },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "main-btn alt",
                      attrs: { dark: "", title: _vm.$t("misc.contact.us") },
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("misc.contact.us")) +
                          "\n            "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "py-12 px-4 bg-alt",
          attrs: { id: "our-services-wrapper" },
        },
        [
          _c("div", { staticClass: "limit-width py-12" }, [
            _c("h1", { staticClass: "mb-6 highlight" }, [
              _vm._v(_vm._s(_vm.$t("industria.pages.services.services.title"))),
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "v-expansion-panels",
                  _vm._l(
                    _vm.$t("industria.pages.services.services.values"),
                    function (item, i) {
                      return _c(
                        "v-expansion-panel",
                        { key: i },
                        [
                          _c("v-expansion-panel-header", [
                            _c("div", {
                              domProps: { innerHTML: _vm._s(item.title) },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("v-expansion-panel-content", [
                            item.sub
                              ? _c(
                                  "div",
                                  _vm._l(item.sub, function (sub, j) {
                                    return _c("div", { key: j }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "mb-2",
                                          class: { "mt-4": j != 0 },
                                        },
                                        [
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(j + 1) +
                                                ". " +
                                                _vm._s(sub.title)
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ])
                                  }),
                                  0
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.text
                              ? _c("div", [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(item.text + "."),
                                    },
                                  }),
                                ])
                              : _vm._e(),
                          ]),
                        ],
                        1
                      )
                    }
                  ),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("hr", { staticClass: "my-12" }),
            _vm._v(" "),
            _c("h2", { staticClass: "mb-2 text-uppercase h5 text-center" }, [
              _c("b", [
                _vm._v(
                  _vm._s(_vm.$t("industria.pages.services.solutions.title"))
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", {
              staticClass: "divider mt-4 mb-6",
              staticStyle: { margin: "0 auto" },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "products-wrapper limit-width d-flex justify-center",
                attrs: { id: "products-wrapper" },
              },
              [
                _c("v-expand-x-transition", [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.single.show === false &&
                            _vm.single.index === null,
                          expression:
                            "single.show === false && single.index === null",
                        },
                      ],
                      staticClass: "products-grid",
                    },
                    _vm._l(_vm.products, function (item, i) {
                      return _c(
                        "div",
                        {
                          key: i,
                          on: {
                            click: function ($event) {
                              return _vm.showSingle(i)
                            },
                          },
                        },
                        [
                          _c("v-hover", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var hover = ref.hover
                                    return [
                                      _c(
                                        "v-card",
                                        { class: { hover: hover } },
                                        [
                                          _c("v-img", {
                                            staticClass: "img-wrapper",
                                            class: { zoom: hover },
                                            attrs: { src: item.img },
                                          }),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "pa-2" }, [
                                            _c(
                                              "h3",
                                              {
                                                staticClass:
                                                  "text-uppercase h6 highlight product-title",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "industria.pages.services.solutions.values[" +
                                                        i +
                                                        "].title"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "v-expand-x-transition",
                  [
                    _c(
                      "v-card",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.single.show === true &&
                              _vm.single.index !== null,
                            expression:
                              "single.show === true && single.index !== null",
                          },
                        ],
                        staticClass: "pa-4",
                        attrs: {
                          height:
                            _vm.$vuetify.breakpoint.width > 850 ? 322 : "auto",
                          width: "100%",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-column justify-space-between",
                            staticStyle: { height: "100%" },
                          },
                          [
                            _vm.single.index !== null
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "product-single-content",
                                    class: _vm.single.class,
                                  },
                                  [
                                    _c(
                                      "h2",
                                      {
                                        staticClass:
                                          "text-uppercase h6 highlight mb-2",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "industria.pages.services.solutions.values[" +
                                                _vm.single.index +
                                                "].title"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.single.index !== null
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "product-single-content",
                                    class: _vm.single.class,
                                  },
                                  _vm._l(
                                    _vm.$t(
                                      "industria.pages.services.solutions.values[" +
                                        _vm.single.index +
                                        "].description"
                                    ),
                                    function (description) {
                                      return _c("div", { key: description }, [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(description) +
                                            "."
                                        ),
                                        _c("br"),
                                        _c("br"),
                                      ])
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-center justify-end product-single-content",
                                class: _vm.single.class,
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "main-btn",
                                    attrs: {
                                      dark: "",
                                      "aria-label": "go-back",
                                      title: _vm.$t("misc.nav.back"),
                                    },
                                    on: { click: _vm.goBack },
                                  },
                                  [
                                    _c("v-icon", { attrs: { left: "" } }, [
                                      _vm._v(
                                        "\n                      mdi-arrow-left\n                    "
                                      ),
                                    ]),
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.$t("misc.nav.back")) +
                                        "\n                  "
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }