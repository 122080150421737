var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("intro-app-bar", {
        attrs: { title: _vm.$t("intro.pages.contacts.title") },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "contactos-wrapper pa-4" },
        _vm._l(_vm.$config.branches, function (item, index) {
          return _c("v-hover", {
            key: index,
            class: "item" + index,
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ref) {
                    var hover = ref.hover
                    return [
                      _c(
                        "v-card",
                        {
                          staticClass: "card-outter",
                          class: { "card-hover": hover },
                          attrs: { elevation: hover ? 4 : 2, outlined: "" },
                        },
                        [
                          _c(
                            "v-img",
                            {
                              attrs: {
                                alt: item.local + " - " + item.tipo,
                                src: item.img,
                                eager: _vm.$vuetify.breakpoint.mobile,
                                gradient:
                                  "90deg, rgba(33, 37, 41, 0.2), rgba(33, 37, 41, 0.1)",
                              },
                            },
                            [
                              _c(
                                "v-scale-transition",
                                { attrs: { origin: "center center" } },
                                [
                                  hover
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "v-card--reveal white--text hover-overlay",
                                          staticStyle: { height: "100%" },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color: "#f2f2f2",
                                                size: "48",
                                              },
                                            },
                                            [_vm._v("mdi-map-marker")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-card-title", { staticClass: "blue-border" }, [
                            _vm._v(_vm._s(item.local)),
                          ]),
                          _vm._v(" "),
                          _c("v-card-subtitle", { staticClass: "subtitle" }, [
                            _c("span", [_vm._v(_vm._s(_vm.type(item.tipo)))]),
                          ]),
                          _vm._v(" "),
                          _c("v-card-text", [
                            _c("div", [_vm._v(_vm._s(item.morada))]),
                            _vm._v(" "),
                            _c("div", [_vm._v(_vm._s(item.morada2))]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v("Tel: "),
                              _c(
                                "a",
                                {
                                  staticClass: "quick-link blue--text",
                                  attrs: { href: "tel:" + item.telefone },
                                },
                                [_vm._v(_vm._s(item.telefone))]
                              ),
                              _vm._v(" (Chamada para a rede fixa nacional)"),
                            ]),
                            _vm._v(" "),
                            _c("div", [_vm._v("Fax: " + _vm._s(item.fax))]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticStyle: { "font-style": "italic" } },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "quick-link blue--text",
                                    attrs: { href: "mailto:" + item.mail },
                                  },
                                  [_vm._v(_vm._s(item.mail))]
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-card-actions",
                            { staticClass: "card-actions" },
                            [
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: item.link,
                                    target: "_blank",
                                    rel: "noopener noreferrer",
                                  },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "#ec921d",
                                        text: "",
                                        title: _vm.$t("contacts.map.open"),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(_vm.$t("contacts.map.open")) +
                                          "\n                        "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }