<template>
  <div>
    <v-sheet
      height="100%"
      tile
      color="#333"
      class="hero-wrapper"
    >
      <v-parallax
        :src="$routes.asset('img/components/about/products.jpg')"
        :lazy-src="$routes.asset('img/components/about/products-lazy.jpg')"
        alt="Romafe Auto"
        :height="$vuetify.breakpoint.mobile ? 500 : 750"
      >
      </v-parallax>

      <div class="overlay pa-6">
        <div class="limit-width overlay-content">
          <div
            v-html="$t('auto.pages.home.hero.title')"
            class="hero-headline white--text"
          ></div>
          <div class="divider mt-6"></div>

          <div
            v-html="$t('auto.pages.home.hero.subtitle')"
            class="hero-description mt-6 white--text"
          ></div>
        </div>
      </div>

    </v-sheet>

    <div class="features-wrapper">
      <div class="limit-width white--text d-flex">

        <div
          v-for="(item, i) in $t('auto.pages.home.hero.values')"
          :key="item.title"
          class="d-flex py-8 px-4 feature-single"
          :class="{alt: i == $t('auto.pages.home.hero.values').length - 1}"
          @click="i == $t('auto.pages.home.hero.values').length - 1 ? openClientArea() : null"
        >
          <div>
            <v-icon
              class="features-icon"
              size="34"
            >{{ item.icon }}</v-icon>
          </div>

          <div class="ml-4 d-flex flex-column">
            <h2 class="features-title"><b>{{ item.title }}</b></h2>
            <span class="features-description mt-2">{{ item.description }}</span>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
  }),
  methods: {
    openClientArea() {
      window.open(this.$config.platforms.weberp.link, '_blank').focus();
    }
  }
}
</script>

<style lang="scss" scoped>
.hero-wrapper {
  position: relative;

  .overlay {
    background: linear-gradient(
      90deg,
      rgba(33, 37, 41, 0.9),
      rgba(33, 37, 41, 0.9)
    );
    position: absolute;
    display: flex;
    align-items: center;
    z-index: 2;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .overlay-content {
    width: 100%;
    height: 100%;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 500px) {
      & > div:nth-child(2) {
        font-size: 14px;
      }
    }
  }
}

::v-deep .overlay {
  .hero-headline {
    > * {
      font-size: 52px !important;
      line-height: 62px !important;
      font-weight: 800;
      @media screen and (max-width: 1100px) {
        font-size: 42px !important;
        line-height: 52px !important;
      }
      @media screen and (max-width: 800px) {
        font-size: 26px !important;
        line-height: 32px !important;
      }
    }
    .highlight {
      color: $palette1;
      font-family: $titleFont;
    }
  }

  .hero-description {
    > * {
      font-weight: 400;
      @media screen and (max-width: 1100px) {
        font-size: 16px !important;
      }
      @media screen and (max-width: 800px) {
        font-size: 14px !important;
      }
    }
  }
}

.features-wrapper {
  background-color: $footer;
  & > div {
    flex-wrap: wrap;
    > div {
      min-width: 240px;
      flex: 1;
    }
  }

  .features-icon {
    color: $palette1;
  }

  .features-title {
    font-size: 14px;
  }

  .features-description {
    font-size: 14px;
  }
}

.feature-single.alt {
  background-color: $palette1;
  cursor: pointer;
  transition: 150ms ease-in-out;

  &:hover {
    background-color: lighten($palette1, 5%);
  }

  .features-icon {
    color: darken($palette1, 20%);
  }
}
</style>