var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "quote-wrapper white--text",
      class: [
        _vm.$vuetify.breakpoint.width > 600 ? "px-12" : "px-4",
        _vm.sector,
      ],
    },
    [
      _c("div", { staticClass: "quote" }, [
        _c("h1", [
          _vm._v("“" + _vm._s(_vm.admin.quote[0]) + " "),
          _c("span", { staticClass: "heart" }, [
            _vm._v(_vm._s(_vm.admin.quote[1])),
          ]),
          _vm._v(" " + _vm._s(_vm.admin.quote[2]) + ".”"),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "mt-8" }, [
          _vm._v(_vm._s(_vm.admin.name) + " - " + _vm._s(_vm.admin.job)),
        ]),
      ]),
      _vm._v(" "),
      _c("v-img", {
        staticClass: "quote-person",
        attrs: {
          alt: _vm.admin.name + " - " + _vm.admin.job,
          src: _vm.$routes.asset("img/employees/" + _vm.name + "-quote.png"),
          "max-height": _vm.$vuetify.breakpoint.width > 750 ? 420 : 300,
          "max-width": 230,
          contain: "",
          eager: _vm.$vuetify.breakpoint.mobile,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }