<template>
  <div>
    <intro-app-bar :title="$t('intro.pages.contacts.title')"></intro-app-bar>

    <div class="contactos-wrapper pa-4">

      <v-hover
        v-slot="{ hover }"
        v-for="(item, index) in $config.branches"
        :class="'item' + index"
        :key="index"
      >
        <v-card
          :elevation="hover ? 4 : 2"
          outlined
          :class="{ 'card-hover': hover }"
          class="card-outter"
        >


          <v-card-title class="blue-border">{{ item.local }}</v-card-title>
          <v-card-subtitle class="subtitle"><span>{{ type(item.tipo) }}</span></v-card-subtitle>

          <v-card-text>

            <div>{{ item.morada }}</div>
            <div>{{ item.morada2 }}</div>
            <div>Tel: <a
                :href="'tel:' + item.telefone"
                class="quick-link blue--text"
              >{{ item.telefone }}</a></div>
            <div>Fax: {{ item.fax }}</div>
            <div style="font-style:italic"><a
                :href="'mailto:' + item.mail"
                class="quick-link blue--text"
              >{{ item.mail }}</a></div>

          </v-card-text>

          <v-card-actions class="card-actions">
            <v-spacer></v-spacer>

            <a
              :href="item.link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <v-btn
                color="#ec921d"
                text
                :title="$t('contacts.map.open')"
              >
                {{ $t('contacts.map.open') }}
              </v-btn>
            </a>
          </v-card-actions>
        </v-card>
      </v-hover>

    </div>
  </div>
</template>

<script>
export default {
  methods: {
    type(val) {
      let tipo;

      if (val === 'Sede') tipo = this.$t('contacts.headquarters');
      else if (val === 'Ponto de Venda') tipo = this.$t('contacts.branch')
      else throw 'Tipo de local não definido: valores definidos: Sede e Ponto de Venda'

      return tipo;
    }
  }
}
</script>

<style lang="scss" scoped>
.contactos-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
    "item0 item1 item2"
    "item3 item4 null";
  gap: 16px;
  & .item0 {
    grid-area: item0;
  }
  & .item1 {
    grid-area: item1;
  }
  & .item2 {
    grid-area: item2;
  }
  & .item3 {
    grid-area: item3;
  }
  & .item4 {
    grid-area: item4;
  }

  @media screen and (max-width: 1400px) {
    grid-template-columns: 1fr 1fr;

    grid-template-areas:
      "item0 item1"
      "item2 item3"
      "item4 null";
  }

  @media screen and (max-width: 980px) {
    grid-template-columns: 1fr;

    grid-template-areas:
      "item0"
      "item1"
      "item2"
      "item3"
      "item4";
  }
}

.card-outter {
  position: relative;
  padding-bottom: 50px;
}

.card-actions {
  position: absolute;
  bottom: 0;
  right: 0;
}

.blue-border {
  border-top: 5px solid $romafe;
}

.subtitle {
  color: rgba(0, 0, 0, 0.4) !important;
}
</style>