<template>
  <div>
    <global-app-bar></global-app-bar>

    <transition
      name="fade"
      mode="out-in"
    >
      <router-view :class="sector + '-router-view'"></router-view>
    </transition>

    <global-footer></global-footer>
  </div>
</template>

<script>
import sector from '@mixins/sector/sector';

export default {
  mixins: [sector],
  created() {
    this.$store.commit(`${this.sector}/news/getFrontpage`);
    this.$store.commit(`${this.sector}/news/get`);
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.25s;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>