var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("intro-app-bar", { attrs: { title: "Consulta de stocks" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "square_center" },
        [
          _c(
            "v-card",
            {
              attrs: {
                outlined: "",
                elevation: "5",
                width: "100%",
                height: "100%",
              },
            },
            [
              _c("div", { staticClass: "blured" }, [
                _c("div", { staticClass: "row row_pesquisa" }, [
                  _c(
                    "div",
                    {
                      staticClass: "col-lg-4",
                      attrs: { id: "rmf-searchbar-row" },
                    },
                    [
                      _c("div", { staticClass: "col-md-12" }, [
                        _c(
                          "div",
                          { staticClass: "input-group" },
                          [
                            _c("v-text-field", {
                              attrs: {
                                outlined: "",
                                "append-icon": "mdi-magnify",
                                label: "Introduza código artigo/fabricante",
                                "single-line": "",
                                "hide-details": "",
                                disabled: "",
                              },
                              on: {
                                "click:append": _vm.refreshDataGrid,
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.refreshDataGrid($event)
                                },
                              },
                              model: {
                                value: _vm.search,
                                callback: function ($$v) {
                                  _vm.search = $$v
                                },
                                expression: "search",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { attrs: { id: "rmf-notas" } }, [
                          _c("h5", [_vm._v("Notas:")]),
                          _vm._v(" "),
                          _c("ul", [
                            _c("li", [
                              _vm._v(
                                "\n                                        Critério da pesquisa: Total ou Parte\n                                    "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _vm._v("Os espaços não são considerados"),
                            ]),
                            _vm._v(" "),
                            _c("li", [_c("i", [_vm._v("Case-Insensitive")])]),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-12", attrs: { id: "row_stocks" } },
                    [
                      _c(
                        "DxDataGrid",
                        {
                          attrs: {
                            "data-source": _vm.articleData,
                            id: "gridContainer",
                            "show-borders": true,
                            "key-expr": "codart",
                            "column-min-width": 100,
                            "column-max-width": 100,
                            "show-column-lines": true,
                            "show-row-lines": true,
                            "hover-state-enabled": true,
                            "remote-operations": false,
                          },
                          on: {
                            initialized: _vm.saveGridInstance,
                            "cell-prepared": _vm.onCellPrepared,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "master-detail",
                              fn: function (ref) {
                                var data = ref.data
                                return [
                                  _c("MasterDetail", {
                                    attrs: { codartint: data.key },
                                  }),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c("DxPaging", { attrs: { "page-size": 15 } }),
                          _vm._v(" "),
                          _c("DxColumn", {
                            attrs: {
                              width: 300,
                              "data-field": "codart",
                              caption: "Artigo",
                            },
                          }),
                          _vm._v(" "),
                          _c("DxColumn", {
                            attrs: {
                              width: 300,
                              "data-field": "codartext",
                              caption: "Cód. Fabricante",
                            },
                          }),
                          _vm._v(" "),
                          _c("DxColumn", {
                            attrs: {
                              "data-field": "precotabela",
                              caption: "Preço Tabela (Euros)",
                              alignment: "right",
                            },
                          }),
                          _vm._v(" "),
                          _c("DxColumn", {
                            attrs: {
                              "data-field": "chegada",
                              caption: "Dt. Prev. Chegada",
                              alignment: "right",
                            },
                          }),
                          _vm._v(" "),
                          _c("DxMasterDetail", {
                            attrs: { enabled: true, template: "master-detail" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "v-alert",
                {
                  staticClass: "alert-overlay",
                  attrs: { border: "left", color: "indigo", dark: "" },
                },
                [
                  _vm._v(
                    "\n                Estamos a melhorar esta página para si. Em breve teremos novidades!\n            "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }