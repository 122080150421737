<template>
  <v-footer
    padless
    class="cookies-bar-wrapper"
    :class="{ 'mobile': $vuetify.breakpoint.mobile, 'isIntro': isIntro }"
    v-if="accepted === null"
  >
    <div
      class="cookies-bar white--text"
      :class="[$vuetify.breakpoint.width < 800 ? 'px-4' : 'px-12', $vuetify.breakpoint.width <= 650 ? 'py-4' : 'py-2']"
    >

      <div class="cookies-msg">{{ $t('misc.cookies.message') }}.</div>

      <div
        class="action-wrapper"
        :class="{'mt-4': $vuetify.breakpoint.width <= 650, 'ml-12': $vuetify.breakpoint.width > 650}"
      >
        <v-btn
          class="main-btn"
          dark
          height="32"
          @click="accept"
          :title="$t('misc.cookies.button.accept')"
          aria-label="accept"
        >
          {{ $t('misc.cookies.button.accept') }}
        </v-btn>

        <v-btn
          icon
          color="#FFF"
          class="ml-2"
          small
          @click="reject"
          :title="$t('misc.cookies.button.close')"
          aria-label="close"
        >
          <v-icon size="16">mdi-close</v-icon>
        </v-btn>
      </div>

    </div>
  </v-footer>
</template>

<script>
import { bootstrap } from 'vue-gtag'

export default {
  data: () => ({
    accepted: null,
  }),
  methods: {
    checkAccepted() {
      return this.accepted = JSON.parse(localStorage.getItem('acceptedGtag'));
    },
    enablePlugin() {
      bootstrap().then((gtag) => { });
    },
    accept() {
      localStorage.setItem('acceptedGtag', true);
      this.checkAccepted();
      this.enablePlugin();
    },
    reject() {
      this.accepted = false;
    }
  },
  computed: {
    isIntro() {
      let intro = Object.values(this.$config.navigation.intro);
      return intro.some((el) => el.link === this.$route.name);
    }
  },
  created() {
    if (this.checkAccepted() === true) this.enablePlugin();
  },
}
</script>

<style lang="scss" scoped>
.cookies-bar-wrapper {
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  z-index: 5;
  bottom: 0;
  width: 100%;
  //background: linear-gradient(to right, #005491, #0063aa);

  &.isIntro:not(.mobile) {
    .cookies-bar {
      padding-right: calc(256px + 48px) !important; //Nav drawer width
    }
  }

  .cookies-bar {
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 650px) {
      flex-direction: column;
    }

    .cookies-msg {
      font-size: 14px;
    }

    .action-wrapper {
      display: flex;
      align-items: center;
    }
  }
}
</style>