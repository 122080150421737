<template>
  <div
    class="contacts-page-wrapper"
    :class="sector"
  >
    <hero-inner-page
      :src="$routes.asset('/img/components/hero/privacypolicy/hero-inner-page.jpg')"
      :routes="isIndustria ? [$config.navigation.industria.privacyPolicy] : isAuto ? [$config.navigation.auto.privacyPolicy] : []"
      :otherlinks="true"
    ></hero-inner-page>

    <div class="py-12 px-4 bg-main">
      <div class="limit-width py-12">

        <div class="privacy-policy-wrapper">
          <div>
            <h1 class="h1 mb-2 highlight">{{ $t('pages.privacypolicy.dataprotection.title') }}</h1>
            <div v-html="$t('pages.privacypolicy.dataprotection.text')"></div>

            <div class="list-indented">
              <p
                v-for="(item, i) in $t('pages.privacypolicy.dataprotection.values')"
                :key="item"
                class="mb-0"
              >
                <v-icon class="list-bullet">mdi-circle-medium</v-icon>{{ item }}<span v-if="i != $t('pages.privacypolicy.dataprotection.values').length - 1">;</span><span v-else>.</span>
              </p>
            </div>

          </div>

          <div>
            <h1 class="h1 mb-2 mt-8 highlight">{{ $t('pages.privacypolicy.personaldata.title') }}</h1>
            <div v-html="$t('pages.privacypolicy.personaldata.text')"></div>
          </div>

          <div>
            <h1 class="h1 mb-2 mt-8 highlight">{{ $t('pages.privacypolicy.datasharing.title') }}</h1>
            <div v-html="$t('pages.privacypolicy.datasharing.text')"></div>
          </div>

          <div>
            <h1 class="h1 mb-2 mt-8 highlight">{{ $t('pages.privacypolicy.userrights.title') }}</h1>
            <div v-html="$t('pages.privacypolicy.userrights.text[0]')"></div>

            <div class="list-indented">
              <p
                v-for="(item, i) in $t('pages.privacypolicy.userrights.values')"
                :key="item"
                class="mb-0"
              >
                <v-icon class="list-bullet">mdi-circle-medium</v-icon>{{ item }}<span v-if="i != $t('pages.privacypolicy.userrights.values').length - 1">;</span><span v-else>.</span>
              </p>
            </div>

            <br>
            <div v-html="$t('pages.privacypolicy.userrights.text[1]')"></div>

          </div>

          <div>
            <h1 class="h1 mb-2 mt-8 highlight">{{ $t('pages.privacypolicy.cookies.title') }}</h1>
            <div v-html="$t('pages.privacypolicy.cookies.text')"></div>

            <h1 class="h5 mt-4 highlight">{{ $t('pages.privacypolicy.cookies.uses.title') }}</h1>
            <div v-html="$t('pages.privacypolicy.cookies.uses.text')"></div>

            <h1 class="h5 mt-4 highlight">{{ $t('pages.privacypolicy.cookies.block.title') }}</h1>
            <div v-html="$t('pages.privacypolicy.cookies.block.text')"></div>
          </div>

          <div>
            <h1 class="h1 mb-2 mt-8 highlight">{{ $t('pages.privacypolicy.newsletter.title') }}</h1>
            <div v-html="$t('pages.privacypolicy.newsletter.text')"></div>
          </div>

          <div>
            <h1 class="h1 mb-2 mt-8 highlight">{{ $t('pages.privacypolicy.collecteddata.title') }}</h1>
            <div v-html="$t('pages.privacypolicy.collecteddata.text')"></div>
          </div>

          <div>
            <h1 class="h1 mb-2 mt-8 highlight">{{ $t('pages.privacypolicy.contacts.title') }}</h1>
            <div>
              {{ $t('pages.privacypolicy.contacts.text[0]')}}

              <router-link
                :title="$t(`${localePath}.navigation.links.contacts`)"
                class="router-link"
                :to="{ name: $config.navigation[sector].contacts.link }"
              >{{ $t(`${localePath}.navigation.links.contacts`) }}</router-link>

              {{ $t('pages.privacypolicy.contacts.text[1]')}}
            </div>
          </div>

          <div>
            <h1 class="h1 mb-2 mt-8 highlight">{{ $t('pages.privacypolicy.revision.title') }}</h1>
            <div v-html="$t('pages.privacypolicy.revision.text')"></div>
          </div>

        </div>

      </div>
    </div>

  </div>
</template>

<script>
import sector from '@mixins/sector/sector';

export default {
  data: () => ({

  }),
  mixins: [sector],
}
</script>

<style lang="scss" scoped>
</style>