<template>
  <div
    class="quote-wrapper white--text"
    :class="[$vuetify.breakpoint.width > 600 ? 'px-12' : 'px-4', sector]"
  >
    <div class="quote">
      <h1>“{{ admin.quote[0] }} <span class="heart">{{ admin.quote[1] }}</span> {{ admin.quote[2] }}.”</h1>
      <span class="mt-8">{{ admin.name }} - {{ admin.job }}</span>
    </div>

    <v-img
      :alt="`${admin.name} - ${admin.job}`"
      class="quote-person"
      :src="$routes.asset(`img/employees/${name}-quote.png`)"
      :max-height="$vuetify.breakpoint.width > 750 ? 420 : 300"
      :max-width="230"
      contain
      :eager="$vuetify.breakpoint.mobile"
    ></v-img>
  </div>
</template>

<script>
import sector from '@mixins/sector/sector';

export default {
  mixins: [sector],
  props: {
    name: {
      type: String,
      required: true
    }
  },
  computed: {
    admin() {
      return this.$t(`company.admins.${this.name}`);
    }
  }
}
</script>

<style lang="scss" scoped>
.quote-wrapper {
  background: linear-gradient(
    to right,
    darken($romafe, 15%),
    darken($romafe, 5%)
  );
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.auto {
    background: linear-gradient(to right, darken($palette1, 15%), $palette1);
  }

  &.intro {
    height: 480px;
  }

  .quote {
    z-index: 1;
    line-height: 44px;
    max-width: 600px;
    h1 {
      font-size: 34px;
      font-weight: 800;
    }
    ::v-deep .heart {
      font-family: $titleFont;
      text-decoration: underline;
      text-decoration-color: $palette0;
      text-decoration-thickness: 6px;
    }
  }

  @media screen and (max-width: 950px) {
    .quote {
      line-height: 36px;
      h1 {
        font-size: 26px;
      }
    }
  }

  @media screen and (max-width: 800px) {
    .quote {
      line-height: 32px;
      h1 {
        font-size: 22px;
      }
    }
  }

  @media screen and (max-width: 650px) {
    height: 360px !important;

    .quote-person {
      position: absolute;
      right: 0;
      opacity: 0.2;
    }

    ::v-deep .quote .heart {
      text-decoration-thickness: 3px;
    }
  }

  @media screen and (max-width: 450px) {
    .quote {
      line-height: 30px;
      h1 {
        font-size: 20px;
      }
    }
  }
}

.quote-person {
  align-self: flex-end;
}
</style>