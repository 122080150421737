<template>
  <div class="px-4 bg-main">
    <div class="limit-width py-12 d-flex align-center">
      <div
        class="d-flex align-center"
         :class="{'has-gap' : item.img}"
        v-if="isImageLeft"
      >
        <div>
          <div>
            <h2 class="highlight mb-2">{{ item.title }}</h2>
          </div>
          <div v-html="item.description"></div>
        </div>

        <div v-if="$vuetify.breakpoint.width >= 1020">
          <v-img
            :src="$routes.asset(`img/auto/products/${item.img}`)"
            :alt="item.title"
            :max-width="$vuetify.breakpoint.width < 400 ? 220 : $vuetify.breakpoint.width < 600 ? 300 : 400"
          ></v-img>
        </div>

      </div>

      <div
        class="d-flex align-center"
        :class="{'has-gap' : item.img}"
        v-else
      >
        <div v-if="$vuetify.breakpoint.width >= 1020">
          <v-img
            :src="$routes.asset(`img/auto/products/${item.img}`)"
            :alt="item.title"
            :max-width="$vuetify.breakpoint.width < 400 ? 220 : $vuetify.breakpoint.width < 600 ? 300 : 400"
          ></v-img>
        </div>

        <div>
          <div>
            <h2 class="highlight mb-2">{{ item.title }}</h2>
          </div>
          <div v-html="item.description"></div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    isImageLeft: {
      type: Boolean,
      required: true
    }
  },
}
</script>

<style lang="scss" scoped>
</style>