var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DxDataGrid",
        {
          attrs: {
            "data-source": _vm.masterDetailData,
            "show-borders": true,
            "column-auto-width": true,
          },
        },
        [
          _c("DxColumn", {
            attrs: {
              "data-field": "codarm",
              caption: "#Armazém",
              alignment: "right",
            },
          }),
          _vm._v(" "),
          _c("DxColumn", {
            attrs: {
              "data-field": "desarm",
              "data-type": "date",
              caption: "Des.Arm",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }