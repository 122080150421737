<template>
    <div>
        <DxDataGrid
            :data-source ="masterDetailData" 
            :show-borders="true"
            :column-auto-width="true"
        >
            <DxColumn
              data-field="codarm"
              caption="#Armazém"
              alignment="right"
            />
            <DxColumn 
              data-field="desarm" 
              data-type="date" 
              caption="Des.Arm" />
        </DxDataGrid>
    </div>
</template>

<script>
import { DxDataGrid, DxColumn } from "devextreme-vue/data-grid";
import { createStore } from 'devextreme-aspnet-data-nojquery';



export default {
    components: {
        DxDataGrid,
        DxColumn
    },
     props: {
       codartint: {
         default: null,
       },
     },
    data() {
        return {
          md_encoded: "",
        };
    },
  
  computed: {


    masterDetailData() {
      this.md_encoded = encodeURIComponent(encodeURIComponent(this.codartint));
      return this.codartint === null ? null : {
        store: createStore({
          key: 'codart',
          loadUrl: `${this.$routes.api.get_stock_art}/${this.md_encoded}`
        }),
      };
    },
  },
};
</script>