<template>
  <div></div>
</template>

<script>
export default {
  props: {
    api: {
      type: String,
      required: true,
    },
  },
  methods: {
    setApi() {
      let api = JSON.parse(this.api);
      this.$routes.api = api;
    }
  },
  created() {
    this.setApi();
  },
}
</script>