var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "news-page-wrapper", class: _vm.sector },
    [
      _c("hero-inner-page", {
        attrs: {
          src: _vm.$routes.asset(
            "/img/components/hero/news/hero-inner-page.jpg"
          ),
          routes: [_vm.$config.navigation[_vm.sector].news],
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "py-12 px-4" }, [
        _c(
          "div",
          { staticClass: "limit-width py-12" },
          [
            _vm._l(_vm.news.data, function (item) {
              return _c("v-hover", {
                key: item.id,
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var hover = ref.hover
                        return [
                          _c(
                            "v-card",
                            {
                              staticClass: "news-card mb-6",
                              attrs: { elevation: hover ? 4 : 2, outlined: "" },
                            },
                            [
                              _vm.$vuetify.breakpoint.width > 850
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: _vm.$config.navigation[
                                                _vm.sector
                                              ].newsSingle.link,
                                              params: {
                                                title: _vm.$routes.sanitize(
                                                  item.titulo
                                                ),
                                              },
                                              query: { id: item.id },
                                            },
                                          },
                                        },
                                        [
                                          _c("v-hover", {
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var hover = ref.hover
                                                    return [
                                                      _c(
                                                        "v-img",
                                                        {
                                                          staticClass:
                                                            "img-wrapper",
                                                          class: {
                                                            zoom: hover,
                                                          },
                                                          attrs: {
                                                            alt:
                                                              "Notícia - " +
                                                              item.titulo,
                                                            src:
                                                              item.imagem !=
                                                              null
                                                                ? _vm.$routes.asset(
                                                                    item.imagem
                                                                  )
                                                                : null,
                                                            height: _vm.imgSize,
                                                            width: _vm.imgSize,
                                                            "max-height":
                                                              _vm.imgSize,
                                                            "max-width":
                                                              _vm.imgSize,
                                                            eager:
                                                              _vm.$vuetify
                                                                .breakpoint
                                                                .mobile,
                                                          },
                                                        },
                                                        [
                                                          item.loaded ===
                                                            false &&
                                                          _vm.$vuetify
                                                            .breakpoint.width >
                                                            850
                                                            ? _c(
                                                                "v-skeleton-loader",
                                                                {
                                                                  staticClass:
                                                                    "news-img",
                                                                  attrs: {
                                                                    type: "image",
                                                                  },
                                                                }
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              item.loaded === false
                                ? _c("v-skeleton-loader", {
                                    staticClass: "news-article",
                                    attrs: { type: "article, paragraph" },
                                  })
                                : _c(
                                    "v-container",
                                    {
                                      staticClass: "pa-4",
                                      attrs: { fluid: "" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "h1",
                                            { staticClass: "news-date h5" },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(item.data)),
                                              ]),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: {
                                                  name: _vm.$config.navigation[
                                                    _vm.sector
                                                  ].newsSingle.link,
                                                  params: {
                                                    title: _vm.$routes.sanitize(
                                                      item.titulo
                                                    ),
                                                  },
                                                  query: { id: item.id },
                                                },
                                                title: item.title,
                                              },
                                            },
                                            [
                                              _c(
                                                "h2",
                                                {
                                                  staticClass:
                                                    "news-title h5 mt-2",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.titulo) + " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("div", {
                                            staticClass: "divider my-2",
                                            staticStyle: {
                                              "max-width": "50px",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("div", {
                                            staticClass: "news-description",
                                            domProps: {
                                              innerHTML: _vm._s(item.resumo),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("div", [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "news-more",
                                            class: {
                                              "mt-4":
                                                _vm.$vuetify.breakpoint.width <=
                                                850,
                                            },
                                          },
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                attrs: {
                                                  to: {
                                                    name: _vm.$config
                                                      .navigation[_vm.sector]
                                                      .newsSingle.link,
                                                    params: {
                                                      title:
                                                        _vm.$routes.sanitize(
                                                          item.titulo
                                                        ),
                                                    },
                                                    query: { id: item.id },
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: "main-btn",
                                                    attrs: {
                                                      dark: "",
                                                      title:
                                                        _vm.$t(
                                                          "misc.more.read"
                                                        ),
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "misc.more.read"
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "text-center" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "main-btn load-more mt-6",
                    attrs: {
                      height: "48",
                      disabled: _vm.news.loading || _vm.news.last,
                    },
                    on: { click: _vm.loadMore },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.news.last
                            ? _vm.$t("misc.news.empty")
                            : _vm.$t("misc.more.load")
                        ) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }