<template>

    <div class="language-wrapper" :title="$t('misc.language.change')">
        <!--
        //! REMOVE LANGUAGE SELECTOR
        //? RICARDO MONTEIRO -->
        <v-select v-model="select" :items="items" item-text="language" item-value="language" return-object single-line
            append-icon="mdi-chevron-down" flat solo @change="changeLocale" label="language" aria-label="language">
            <template v-slot:selection="{ item }">
                <div class="curr-selection">
                    <v-img :src="item.flag" max-width="24"></v-img>
                    <span class="ml-1">{{ item.language }}</span>
                </div>
            </template>

            <template v-slot:item="{ item }">
                <div class="language-selection">
                    <v-img :src="item.flag" :eager="true" max-width="24"></v-img>
                    <span class="ml-1">{{ item.language }}</span>
                </div>
            </template>

        </v-select>

    </div>

</template>

<script>
import sector from '@mixins/sector/sector';

export default {
    data: () => ({
        select: { language: 'PT', flag: '' },
        items: [
            { language: 'PT', flag: '' },
            { language: 'EN', flag: '' },
            { language: 'ES', flag: '' },
        ],
    }),
    mixins: [sector],
    methods: {
        checkSavedLocale() {
            let savedLang = localStorage.getItem('lang');

            if (typeof savedLang === 'string') {
                let newSelection = this.items.find((el) => el.language.toLowerCase() === savedLang);

                if (newSelection !== undefined) {
                    this.select = newSelection;
                    this.$vuetify.lang.current = savedLang;
                    this.$root.$i18n.locale = savedLang;
                }
            }
        },
        changeLocale(item) {
            let lang = item.language.toLowerCase();

            localStorage.setItem('lang', lang);
            this.$vuetify.lang.current = lang;
            this.$root.$i18n.locale = lang;

            if (!this.isIntro) {
                this.$store.commit(`${this.sector}/news/reset`, { route: this.$route, sector: this.sector });
            }
        }
    },
    created() {
        this.items[0].flag = this.$routes.asset('/img/language/portugal.png');
        this.items[1].flag = this.$routes.asset('/img/language/united-kingdom.png');
        this.items[2].flag = this.$routes.asset('/img/language/spain.png');
        this.checkSavedLocale();
    },
}
</script>

<style lang="scss" scoped>
.language-wrapper {
    max-width: 92px;
    max-height: 48px;
    overflow: hidden;

    .curr-selection {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: 14px;
    }
}

::v-deep .language-selection {
    display: flex;
    justify-content: center;
    align-items: center;
}

::v-deep .v-select__slot {
    .v-icon {
        font-size: 18px;
    }

    .v-input__icon {
        width: 20px;
        min-width: 20px;
    }
}

::v-deep .theme--light.v-text-field--solo>.v-input__control>.v-input__slot {
    background: transparent;
}

.appbar-wrapper {
    &.auto {
        & .language-wrapper {
            .curr-selection span {
                color: #fff;
            }

            ::v-deep .v-select__slot {

                .v-icon.theme--light,
                .v-icon.theme--dark {
                    color: #fff !important;
                }
            }
        }
    }
}
</style>
