var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "error-wrapper px-4",
      class: {
        intro: _vm.isIntro,
        industria: _vm.isIndustria,
        auto: _vm.isAuto,
        agricultura: _vm.isAgricultura,
      },
    },
    [
      _c(
        "div",
        { staticClass: "plug-wrapper" },
        [
          _c(
            "v-icon",
            {
              staticClass: "plug",
              attrs: {
                size:
                  _vm.$vuetify.breakpoint.width < 400
                    ? 240
                    : _vm.$vuetify.breakpoint.width < 700
                    ? 300
                    : 500,
              },
            },
            [_vm._v("mdi-power-plug-off-outline")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "router-link",
        { staticClass: "router-link", attrs: { to: { name: _vm.homepage } } },
        [
          _c(
            "v-btn",
            { staticClass: "main-btn mt-6", attrs: { dark: "", height: "48" } },
            [_vm._v("\n            Voltar ao início\n        ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-wrapper text-center" }, [
      _c("h1", [_vm._v("ERRO 404")]),
      _vm._v(" "),
      _c("h2", { staticClass: "mt-6 h5" }, [
        _vm._v("Não conseguimos encontrar a página pretendida."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }