<template>
    <div class="contacts-page-wrapper" :class="sector">
        <hero-inner-page :src="$routes.asset('/img/components/hero/contacts/hero-inner-page.jpg')"
            :routes="isIndustria ? [$config.navigation.industria.contacts] : isAuto ? [$config.navigation.auto.contacts] : []"></hero-inner-page>

        <div class="py-12 px-4">
            <div class="contacts-wrapper limit-width py-12">

                <v-hover v-slot="{ hover }" v-for="(item, i) in $config.branches" :key="i">
                    <v-card :elevation="hover ? 4 : 2" outlined :class="{ 'even': i % 2 === 0, 'odd': i % 2 !== 0 }"
                        class="mb-6 contact-card">

                        <div class="img-wrapper" v-if="$vuetify.breakpoint.width > 850">
                            <a :href="item.link" target="_blank" rel="noopener noreferrer" class="open-map-btn"
                                :title="'Romafe - ' + item.local">
                                <v-hover v-slot="{ hover }">
                                    <v-img :alt="item.local + ' - ' + item.tipo" :src="item.img" :height="imgSize"
                                        :width="imgSize" :max-height="imgSize" :max-width="imgSize"
                                        :eager="$vuetify.breakpoint.mobile"
                                        gradient="90deg, rgba(33, 37, 41, 0.2), rgba(33, 37, 41, 0.1)">
                                        <v-scale-transition origin="center center">
                                            <div v-if="hover" class="v-card--reveal white--text hover-overlay"
                                                style="height: 100%;">
                                                <v-icon color="#f2f2f2" size="48">mdi-map-marker</v-icon>
                                            </div>
                                        </v-scale-transition>
                                    </v-img>
                                </v-hover>
                            </a>
                        </div>

                        <v-container fluid class="pa-4">
                            <h2>{{ item.local }}</h2>
                            <div class="divider my-2" style="max-width: 50px;"></div>
                            <h3 class="regular h6">{{ type(item.tipo) }}</h3>

                            <div class="my-2 contact-card-info">
                                <div>{{ item.morada }}</div>
                                <div>{{ item.morada2 }}</div>

                                <div>Tel: <a :href="'tel:' + item.telefone" class="quick-link blue--text">{{ item.telefone
                                }}</a> (Chamada para a rede fixa nacional)
                                </div>
                                <div>Fax: {{ item.fax }}</div>
                                <div style="font-style:italic"><a :href="'mailto:' + item.mail"
                                        class="quick-link blue--text">{{ item.mail }}</a>
                                </div>

                            </div>

                            <div>
                                <a :href="item.link" target="_blank" rel="noopener noreferrer" class="open-map-btn">
                                    <v-btn class="main-btn" dark :title="$t('contacts.map.open')">
                                        {{ $t('contacts.map.open') }}
                                    </v-btn>
                                </a>
                            </div>

                        </v-container>
                    </v-card>
                </v-hover>

            </div>
        </div>

    </div>
</template>

<script>
import sector from '@mixins/sector/sector';

export default {
    data: () => ({
        imgSize: 279,
    }),
    mixins: [sector],
    methods: {
        type(val) {
            let tipo;

            if (val === 'Sede / Centro Logístico') tipo = this.$t('contacts.headquarters');
            else if (val === 'Centro Logístico') tipo = this.$t('contacts.branch')
            else throw 'Tipo de local não definido: valores definidos: Sede e Ponto de Venda'

            return tipo;
        }
    }
}
</script>

<style lang="scss" scoped>
.contacts-page-wrapper {
    &.industria {
        .hover-overlay {
            background: rgba(236, 146, 29, 0.6);
        }
    }

    &.auto {
        .hover-overlay {
            background: rgba(207, 55, 45, 0.6);
        }
    }
}

.contact-card {
    display: flex;
    height: 280px;

    .container {
        height: 280px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .open-map-btn {
        align-self: flex-end;
    }

    .img-wrapper {
        cursor: pointer;
    }

    &.odd {
        .container {
            padding-right: calc(260px + 36px);
        }

        .img-wrapper {
            position: absolute;
            right: 0;
        }

        .open-map-btn {
            align-self: flex-start;
        }
    }

    &.even {
        .container {
            text-align: right;
        }

        .divider {
            align-self: flex-end;
        }
    }

    @media screen and (max-width: 850px) {
        height: 100%;

        .container {
            height: 100%;
        }

        &.odd {
            .container {
                padding-right: 16px;
            }
        }

        &.even {
            .container {
                text-align: left;
            }

            .divider {
                align-self: flex-start;
            }
        }
    }
}

.contact-card-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 14px;
}
</style>

