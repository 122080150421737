import routes from "../routes/routes";

const config = {
    //! Contactos
    contacts: {
        phone: "+351 226 158 300 (Chamada para a rede fixa nacional)",
        mail: "romafe@romafe.com",
    },
    branches: [
        {
            tipo: "Sede / Centro Logístico",
            local: "Porto",
            morada: "Rua Eng Ezequiel de Campos, 164-182",
            morada2: "4100-228 Porto, Portugal",
            telefone: "+351 226 158 300",
            fax: "+351 226 158 309 (Chamada para a rede fixa nacional)",
            mail: "romafe@romafe.com",
            link: "https://www.google.pt/maps/place/Sociedade+Importadora+Romafe,+S.A./@41.1712418,-8.652477,17z/data=!3m1!4b1!4m5!3m4!1s0xd24658de0278b79:0xe5b3112e87f2a066!8m2!3d41.1712418!4d-8.6502883",
            img: routes.asset("/img/branch/porto.jpg"),
        },
        {
            tipo: "Centro Logístico",
            local: "Lisboa",
            morada: "Rua Marechal Gomes da Costa, 97",
            morada2: "2685-871 Sacavém, Portugal",
            telefone: "+351 226 158 300",
            fax: "+351 226 158 309 (Chamada para a rede fixa nacional)",
            mail: "romafe@romafe.com",
            link: "https://www.google.pt/maps/place/Sociedade+Importadora+ROMAFE/@38.7968035,-9.123567,17z/data=!3m1!4b1!4m5!3m4!1s0xd19320a8df460b1:0xadd620c79e3f2e4d!8m2!3d38.796803!4d-9.1213491",
            img: routes.asset("/img/branch/lisboa.jpg"),
        },
        {
            tipo: "Centro Logístico",
            local: "Coimbra",
            morada: "Rua da Cerâmica Ceres 15, Armazém 7",
            morada2: "3020-927, Torre de Vilela, Portugal",
            telefone: "+351 226 158 300",
            fax: "+351 226 158 309 (Chamada para a rede fixa nacional)",
            mail: "romafe@romafe.com",
            link: "https://www.google.com/maps/place/Romafe+(Coimbra),+Rolamentos,+Lda/@40.2654014,-8.4409609,18z/data=!3m1!4b1!4m5!3m4!1s0xd22ff5a39579255:0xd24ad59f22b95d7e!8m2!3d40.2653994!4d-8.4398666",
            img: routes.asset("/img/branch/coimbra.jpg"),
        },
    ],
    branchesSL: [
        {
            tipo: "Sede / Centro Logístico",
            local: "Porto",
            morada: "Rua Eng Ezequiel de Campos, 164-182",
            morada2: "4100-228 Porto, Portugal",
            telefone: "+351 226 158 300",
            fax: "+351 226 158 309 (Chamada para a rede fixa nacional)",
            mail: "romafe@romafe.com",
            link: "https://www.google.pt/maps/place/Sociedade+Importadora+Romafe,+S.A./@41.1712418,-8.652477,17z/data=!3m1!4b1!4m5!3m4!1s0xd24658de0278b79:0xe5b3112e87f2a066!8m2!3d41.1712418!4d-8.6502883",
            img: routes.asset("/img/branch/porto.jpg"),
        },
        {
            tipo: "Centro Logístico",
            local: "Lisboa",
            morada: "Rua Marechal Gomes da Costa, 97",
            morada2: "2685-871 Sacavém, Portugal",
            telefone: "+351 226 158 300",
            fax: "+351 226 158 309 (Chamada para a rede fixa nacional)",
            mail: "romafe@romafe.com",
            link: "https://www.google.pt/maps/place/Sociedade+Importadora+ROMAFE/@38.7968035,-9.123567,17z/data=!3m1!4b1!4m5!3m4!1s0xd19320a8df460b1:0xadd620c79e3f2e4d!8m2!3d38.796803!4d-9.1213491",
            img: routes.asset("/img/branch/lisboa.jpg"),
        },
        {
            tipo: "Centro Logístico",
            local: "Coimbra",
            morada: "Rua da Cerâmica Ceres 15, Armazém 7",
            morada2: "3020-927, Torre de Vilela, Portugal",
            telefone: "+351 226 158 300",
            fax: "+351 226 158 309 (Chamada para a rede fixa nacional)",
            mail: "romafe@romafe.com",
            link: "https://www.google.com/maps/place/Romafe+(Coimbra),+Rolamentos,+Lda/@40.2654014,-8.4409609,18z/data=!3m1!4b1!4m5!3m4!1s0xd22ff5a39579255:0xd24ad59f22b95d7e!8m2!3d40.2653994!4d-8.4398666",
            img: routes.asset("/img/branch/coimbra.jpg"),
        },
    ],
    //! Socials
    socials: [
        [
            "Facebook",
            "mdi-facebook",
            "https://www.facebook.com/romafept",
            "#3B5998",
        ],
        [
            "Instagram",
            "mdi-instagram",
            "https://www.instagram.com/romafept",
            "#DD2A7B",
        ],
        [
            "LinkedIn",
            "mdi-linkedin",
            "https://www.linkedin.com/company/romafept",
            "#0E76A8",
        ],
        [
            "YouTube",
            "mdi-youtube",
            "https://www.youtube.com/channel/UCP1TWlHW3fRLewRpauLVo0w",
            "#e80000",
        ],
    ],
    //! Platforms
    platforms: {
        weberp: {
            title: "WebERP",
            link: "https://www.romafe.com/weberp",
            icon: "",
            showIntro: false,
        },
        goparts: {
            title: "GoParts",
            link: "https://goparts.romafe.com/",
            icon: "",
            showIntro: false,
        },
        webcrm: {
            title: "WebCRM",
            link: "https://www.romafe.com/webcrm",
            icon: "mdi-account-tie",
            showIntro: true,
        },
        webtec: {
            title: "WebTEC",
            link: "https://www.romafe.com/webtec",
            icon: "mdi-account-hard-hat",
            showIntro: true,
        },
        webprovider: {
            title: "WebProvider",
            link: "https://www.romafe.com/webprovider",
            icon: "mdi-truck-fast",
            showIntro: true,
        },
        webctb: {
            title: "WebCTB",
            link: "https://www.romafe.com/webctb",
            icon: "mdi-account-group",
            showIntro: true,
        },
        webbi: {
            title: "WebBI",
            link: "https://www.romafe.com/webbi",
            icon: "mdi-finance",
            showIntro: true,
        },
        webmail: {
            title: "WebMail",
            link: "https://www.romafe.com/webmail",
            icon: "mdi-email",
            showIntro: true,
        },
        intranet: {
            title: "Intranet",
            link: "https://www.romafe.com/intranet",
            icon: "mdi-apache-kafka",
            showIntro: true,
        },
        webcloud: {
            title: "Cloud",
            link: "https://www.romafe.com/webcloud",
            icon: "mdi-cloud",
            showIntro: true,
        },
        moodle: {
            title: "Moodle",
            link: "https://www.romafe.com/moodle",
            icon: "mdi-book-open-page-variant",
            showIntro: true,
        },
    },
    //! Navigation
    navigation: {
        intro: {
            home: { link: "intro-home" },
            about: { link: "intro-about" },
            contacts: { link: "intro-contacts" },
        },
        industria: {
            home: {
                localePath: "home",
                link: "industria",
                show: true,
                otherLinks: false,
            },
            about: {
                localePath: "about",
                link: "industria-about",
                show: true,
                otherLinks: false,
            },
            services: {
                localePath: "services",
                link: "industria-services",
                show: true,
                otherLinks: false,
            },
            /*       news: { localePath: 'news', link: 'industria-news', show: true, otherLinks: false, },
      newsSingle: { localePath: 'newsSingle', link: 'industria-news-single', show: false, otherLinks: false, }, */
            contacts: {
                localePath: "contacts",
                link: "industria-contacts",
                show: true,
                otherLinks: false,
            },
            saleConditions: {
                localePath: "saleConditions",
                show: false,
                otherLinks: true,
                file: routes.asset("pdf/condicoes_gerais_venda.pdf"),
            },
            privacyPolicy: {
                localePath: "privacyPolicy",
                link: "industria-privacy-policy",
                show: false,
                otherLinks: true,
            },
        },
        auto: {
            home: {
                localePath: "home",
                link: "auto-home",
                show: true,
                otherLinks: false,
            },
            about: {
                localePath: "about",
                link: "auto-about",
                show: true,
                otherLinks: false,
            },
            products: {
                localePath: "products",
                link: "auto-products",
                show: true,
                otherLinks: false,
            },
            /*       news: { localePath: 'news', link: 'auto-news', show: true, otherLinks: false, },
      newsSingle: { localePath: 'newsSingle', link: 'auto-news-single', show: false, otherLinks: false, }, */
            contacts: {
                localePath: "contacts",
                link: "auto-contacts",
                show: true,
                otherLinks: false,
            },
            saleConditions: {
                localePath: "saleConditions",
                show: false,
                otherLinks: true,
                file: routes.asset("pdf/condicoes_gerais_venda.pdf"),
            },
            privacyPolicy: {
                localePath: "privacyPolicy",
                link: "auto-privacy-policy",
                show: false,
                otherLinks: true,
            },
        },
        agricultura: {
            home: {
                localePath: "home",
                link: "agricultura-home",
                show: true,
                otherLinks: false,
            },
            about: {
                localePath: "about",
                link: "agricultura-about",
                show: false,
                otherLinks: false,
            },
            products: {
                localePath: "products",
                link: "agricultura-products",
                show: false,
                otherLinks: false,
            },
            contacts: {
                localePath: "contacts",
                link: "agricultura-contacts",
                show: false,
                otherLinks: false,
            },
            saleConditions: {
                localePath: "saleConditions",
                show: false,
                otherLinks: true,
                file: routes.asset("pdf/condicoes_gerais_venda.pdf"),
            },
            privacyPolicy: {
                localePath: "privacyPolicy",
                link: "auto-privacy-policy",
                show: false,
                otherLinks: true,
            },
        },
    },
};

export default config;
