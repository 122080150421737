var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "about-wrapper" },
    [
      _c("admin-quote", { attrs: { name: "mfranca" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "py-4 timeline-wrapper" },
        [
          _c(
            "v-slide-group",
            {
              attrs: { "center-active": "", "show-arrows": "" },
              model: {
                value: _vm.timeline,
                callback: function ($$v) {
                  _vm.timeline = $$v
                },
                expression: "timeline",
              },
            },
            [
              _c("div", { staticClass: "divider" }),
              _vm._v(" "),
              _vm._l(_vm.$t("intro.pages.about.items"), function (item, i) {
                return _c("v-slide-item", {
                  key: item.title + i,
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var active = ref.active
                          var toggle = ref.toggle
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "about-card-wrapper",
                                class: {
                                  active: active,
                                  even: i % 2 === 0,
                                  odd: i % 2 !== 0,
                                },
                              },
                              [
                                _c("div", {
                                  staticClass: "timeline-icon-wrapper",
                                }),
                                _vm._v(" "),
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "ma-4 pa-4 about-card",
                                    class: { "ml-4": i === 0 },
                                    attrs: {
                                      height: "140",
                                      width: _vm.cards.width,
                                    },
                                    on: { click: toggle },
                                  },
                                  [
                                    _c("div", { staticClass: "card-title" }, [
                                      _c("h2", [_vm._v(_vm._s(item.title))]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "about-date" }, [
                                        _vm._v(_vm._s(item.date)),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", {
                                      staticClass: "card-text mt-2",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          item.description + "."
                                        ),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "card-more-info" },
                                      [
                                        _c("v-icon", [
                                          _vm._v(
                                            _vm._s(
                                              active && i % 2 === 0
                                                ? "mdi-chevron-up"
                                                : !active && i % 2 !== 0
                                                ? "mdi-chevron-up"
                                                : "mdi-chevron-down"
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }